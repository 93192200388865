import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { getAllProjects } from '../api';
import Accordion from '../components/Accordion';
import CheckoutButton from '../components/CheckoutButton';
import BackHomeButton from '../components/BackHomeButton';
import {
  ShoppingCart,
  PackageCheck,
  Clock,
  Shield,
  Search,
  ArrowRight,
} from 'lucide-react';

const Shop = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accordionState, setAccordionState] = useState({
    howItWorks: false,
    faq: false,
  });

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: 'easeOut' },
    },
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const data = await getAllProjects();

        const formattedProjects = data.data
          .filter((project) => project.vendable)
          .map((project) => ({
            id: project.id,
            title: project.titre,
            image: project.image?.formats?.medium?.url
              ? `${project.image.formats.medium.url}`
              : project.image?.url || '/placeholder-image.jpg',
            price: project.price || 0,
            description: project.description || 'Description non disponible',
            priceId: project.priceId,
            category: project.categorie || 'Autre',
            technologies: project.technologies || [],
          }));

        setProjects(formattedProjects);
        setFilteredProjects(formattedProjects);
        setLoading(false);
      } catch (err) {
        setError('Erreur lors de la récupération des projets.');
        console.error('❌ Erreur fetch projets:', err);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = projects.filter(
        (project) =>
          project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          project.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          (project.category &&
            project.category.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredProjects(filtered);
    } else {
      setFilteredProjects(projects);
    }
  }, [searchTerm, projects]);

  const toggleAccordion = (key) => {
    setAccordionState((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-white to-gray-50 mt-0 md:mt-16 md:py-6">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="bg-white p-8 rounded-xl shadow-xl text-center max-w-md"
        >
          <div className="text-red-500 bg-red-50 p-6 rounded-lg flex flex-col items-center mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 mb-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <p className="text-lg font-semibold">{error}</p>
          </div>
          <p className="text-gray-600 mb-8">
            Nous sommes désolés pour ce désagrément. Veuillez réessayer plus
            tard ou contactez-nous si le problème persiste.
          </p>
          <BackHomeButton />
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 pt-20 pb-4 md:pb-24">
      {/* Hero Section */}
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          className="text-center pt-8 md:pt-16 mb-12"
        >
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-3 md:mb-6 text-gray-900">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-700">
              Notre Boutique
            </span>
          </h1>
          <p className="text-gray-600 max-w-xl mx-auto mb-2 md:mb-8 text-lg">
            Découvrez nos templates personnalisables et prenez rendez-vous pour
            adapter ces modèles à vos besoins spécifiques.
          </p>

          <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-2 md:mb-6">
            <BackHomeButton />

            <NavLink
              to="/contact"
              className="inline-flex items-center gap-2 px-5 py-2.5 bg-white text-blue-600 border border-blue-200 rounded-lg hover:bg-blue-50 transition-colors shadow-sm whitespace-nowrap"
            >
              <span>Prendre rendez-vous</span>
              <ArrowRight size={18} />
            </NavLink>
          </div>

          <div className="flex flex-col sm:flex-row sm:flex-wrap justify-center gap-4 sm:gap-6 mt-0 md:mt-8">
            <div className="flex flex-col sm:flex-row justify-center gap-6 mt-8">
              <div className="flex items-center text-gray-700 gap-2">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-100">
                  <Shield size={16} className="text-blue-600" />
                </div>
                <span className="text-sm font-medium">Paiement sécurisé</span>
              </div>

              <div className="flex items-center text-gray-700 gap-2">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-100">
                  <PackageCheck size={16} className="text-blue-600" />
                </div>
                <span className="text-sm font-medium">
                  Projets livrés clé en main
                </span>
              </div>

              <div className="flex items-center text-gray-700 gap-2">
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-100">
                  <Clock size={16} className="text-blue-600" />
                </div>
                <span className="text-sm font-medium">
                  Support technique inclus
                </span>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      {/* Barre de recherche */}
      <div className="container mx-auto px-4 mb-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-xl shadow-md p-4 flex items-center"
        >
          <Search size={18} className="text-gray-400 mr-2" />
          <input
            type="text"
            placeholder="Rechercher un template..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow outline-none text-gray-700"
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="text-gray-400 hover:text-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </motion.div>
      </div>
      {/* Grille de produits */}
      <div className="container mx-auto px-4">
        {loading ? (
          <div className="flex justify-center items-center py-24">
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mb-4"></div>
              <p className="text-gray-600">Chargement des templates...</p>
            </div>
          </div>
        ) : filteredProjects.length === 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-16 bg-white rounded-lg shadow-sm"
          >
            <div className="max-w-md mx-auto">
              <div className="bg-gray-100 p-6 rounded-full w-24 h-24 mx-auto mb-4 flex items-center justify-center">
                <Search size={32} className="text-gray-400" />
              </div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">
                Aucun template trouvé
              </h3>
              <p className="text-gray-600 mb-6">
                Aucun produit ne correspond à votre recherche. Essayez avec un
                autre terme ou explorez notre catalogue complet.
              </p>
              <button
                onClick={() => setSearchTerm('')}
                className="text-blue-600 font-medium hover:text-blue-800"
              >
                Voir tous les templates
              </button>
            </div>
          </motion.div>
        ) : (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3"
          >
            <AnimatePresence>
              {filteredProjects.map((project) => (
                <motion.div
                  key={project.id}
                  variants={itemVariants}
                  layout
                  className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 flex flex-col group"
                >
                  <div className="relative h-56 overflow-hidden">
                    {project.image ? (
                      <img
                        src={project.image}
                        alt={project.title}
                        className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-500">Aucune image</span>
                      </div>
                    )}

                    <div className="absolute top-4 left-4">
                      <span className="text-xs font-medium bg-blue-600/90 text-white px-2 py-1 rounded">
                        {project.category}
                      </span>
                    </div>

                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-end p-5">
                      <h2 className="text-white text-xl font-bold">
                        {project.title}
                      </h2>
                    </div>
                  </div>

                  <div className="p-5 flex-1 flex flex-col">
                    <div className="flex justify-between items-start mb-2">
                      <h3 className="text-lg font-bold text-gray-900 group-hover:text-blue-600 transition-colors">
                        {project.title}
                      </h3>
                      <div className="text-lg font-bold text-blue-600">
                        {project.price
                          ? `${project.price} €`
                          : 'Contactez-nous'}
                      </div>
                    </div>

                    <p className="text-gray-600 text-sm mb-5 flex-grow line-clamp-3">
                      {project.description}
                    </p>

                    {project.technologies &&
                      project.technologies.length > 0 && (
                        <div className="flex flex-wrap gap-2 mb-5">
                          {project.technologies
                            .slice(0, 3)
                            .map((tech, index) => (
                              <span
                                key={index}
                                className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded"
                              >
                                {tech}
                              </span>
                            ))}
                          {project.technologies.length > 3 && (
                            <span className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded">
                              +{project.technologies.length - 3}
                            </span>
                          )}
                        </div>
                      )}

                    <CheckoutButton
                      priceId={project.priceId}
                      projectId={project.id}
                      projectTitle={project.title}
                      className="mt-auto w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition-colors flex items-center justify-center gap-2 font-medium"
                      buttonIcon={<ShoppingCart size={18} />}
                    />
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        )}
      </div>
      {/* Sections Accordion */}
      <div className="container mx-auto px-4 mt-10 md:mt-20">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="bg-white rounded-xl shadow-lg p-8 max-w-4xl mx-auto"
        >
          <h2 className="text-2xl font-bold text-gray-900 mb-8 text-center">
            Comment fonctionne notre boutique ?
          </h2>

          <Accordion
            title="Comment ça fonctionne ?"
            isOpen={accordionState.howItWorks}
            onToggle={() => toggleAccordion('howItWorks')}
            className="border border-gray-200 rounded-lg mb-6 overflow-hidden"
          >
            <ol className="text-gray-600 list-decimal pl-5 space-y-4">
              <li>
                <strong className="text-gray-800">
                  Découvrez nos modèles :
                </strong>{' '}
                Parcourez notre galerie et notez le modèle qui vous inspire. Le
                prix affiché correspond à la version de base du template.
              </li>
              <li>
                <strong className="text-gray-800">Prenez rendez-vous :</strong>{' '}
                Réservez une consultation gratuite en visioconférence via la
                page{' '}
                <NavLink
                  to="/contact"
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  Contact
                </NavLink>{' '}
                pour discuter de vos besoins spécifiques.
              </li>
              <li>
                <strong className="text-gray-800">Personnalisation :</strong>{' '}
                Pendant notre échange, nous verrons ensemble les adaptations
                souhaitées (fonctionnalités supplémentaires, modifications de
                structure). Seuls les changements de couleurs, polices et images
                sont inclus dans le prix de base.
              </li>
              <li>
                <strong className="text-gray-800">
                  Validation du projet :
                </strong>{' '}
                Vous recevrez un devis détaillé incluant le prix du template et
                les éventuels ajouts de fonctionnalités demandées.
              </li>
              <li>
                <strong className="text-gray-800">Consultez nos CGV :</strong>{' '}
                Avant de démarrer, lisez nos{' '}
                <NavLink
                  to="/cgv"
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  CGV
                </NavLink>{' '}
                pour plus de transparence sur nos services.
              </li>
            </ol>
          </Accordion>

          <Accordion
            title="FAQ"
            isOpen={accordionState.faq}
            onToggle={() => toggleAccordion('faq')}
            className="border border-gray-200 rounded-lg overflow-hidden"
          >
            <div className="text-gray-600 space-y-6">
              <div>
                <h4 className="font-bold text-gray-800 mb-2">
                  Que comprend le prix affiché ?
                </h4>
                <p>
                  Le prix inclut le développement complet du site (front-end et
                  back-end), la mise en place de la base de données,
                  l'hébergement pour la première année, la configuration du nom
                  de domaine et la maintenance de base. Les changements
                  esthétiques (couleurs, images, textes) sont inclus dans le
                  prix de base.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-gray-800 mb-2">
                  Comment se déroule la consultation ?
                </h4>
                <p>
                  C'est un échange convivial de 30 minutes où nous discutons de
                  votre projet, de vos besoins techniques spécifiques et des
                  fonctionnalités souhaitées. Nous établirons ensemble un
                  planning détaillé des différentes étapes du projet.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-gray-800 mb-2">
                  Quels types de modifications sont payantes ?
                </h4>
                <p>
                  Les changements majeurs de structure, l'ajout de
                  fonctionnalités complexes ou de modules spécifiques feront
                  l'objet d'un devis complémentaire. La maintenance avancée et
                  les mises à jour majeures au-delà de la première année sont
                  également facturées séparément.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-gray-800 mb-2">
                  Quel est le délai de réalisation ?
                </h4>
                <p>
                  Le délai moyen est de 4 à 5 semaines, ce qui comprend : le
                  développement front-end et back-end (2-3 semaines), la mise en
                  place de la base de données (1 semaine), les tests et la mise
                  en production (1 semaine). Ce délai peut varier selon la
                  complexité du projet.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-gray-800 mb-2">
                  Quel accompagnement est fourni ?
                </h4>
                <p>
                  Vous recevrez un guide d'utilisation complet de votre site,
                  une formation personnalisée à son administration, et un
                  support technique pendant 3 mois après la mise en ligne. Nous
                  restons disponibles pour répondre à vos questions et assurer
                  la maintenance de base.
                </p>
              </div>
              <div>
                <h4 className="font-bold text-gray-800 mb-2">
                  Que comprend la maintenance de base ?
                </h4>
                <p>
                  Elle inclut les mises à jour de sécurité, la surveillance des
                  performances, les sauvegardes régulières et la résolution des
                  bugs mineurs pendant la première année. Les évolutions
                  fonctionnelles majeures feront l'objet d'un devis séparé.
                </p>
              </div>
            </div>
          </Accordion>
        </motion.div>
      </div>

      {/* Section paiement en trois fois */}
      <div className="container mx-auto px-4 mt-10 md:mt-20">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="bg-white rounded-xl shadow-lg p-8 max-w-4xl mx-auto border-t-4 border-blue-600"
        >
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/4 flex justify-center mb-6 md:mb-0">
              <div className="w-24 h-24 bg-blue-100 rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 text-blue-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>

            <div className="md:w-3/4 md:pl-8">
              <h2 className="text-xl font-bold text-gray-900 mb-3">
                Paiement flexible en trois étapes sans frais
              </h2>

              <p className="text-gray-600 mb-4">
                Pour faciliter votre investissement, nous proposons un paiement
                échelonné en trois versements :
              </p>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center font-bold mr-3">
                      1
                    </div>
                    <div>
                      <p className="font-medium">30% à la commande</p>
                      <p className="text-sm text-gray-500">
                        Pour démarrer le projet
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center font-bold mr-3">
                      2
                    </div>
                    <div>
                      <p className="font-medium">40% à mi-parcours</p>
                      <p className="text-sm text-gray-500">
                        Après validation du design
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center font-bold mr-3">
                      3
                    </div>
                    <div>
                      <p className="font-medium">30% à la livraison</p>
                      <p className="text-sm text-gray-500">
                        Après validation finale
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-sm text-gray-500 mt-4">
                Cette flexibilité de paiement vous permet d'étaler votre
                investissement tout en bénéficiant d'un accompagnement complet
                sans surcoût.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
      {/* Call-to-action */}
      <div className="container mx-auto px-4 mt-10 md:mt-24">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7 }}
          className="bg-gradient-to-r from-blue-600 to-indigo-700 rounded-2xl overflow-hidden shadow-xl"
        >
          <div className="md:flex items-center">
            <div className="p-8 md:p-12 md:w-2/3">
              <h2 className="text-2xl md:text-3xl font-bold text-white mb-4">
                Besoin d'un projet sur mesure ?
              </h2>
              <p className="text-blue-100 mb-6 md:pr-12">
                Nos templates ne correspondent pas exactement à vos besoins ?
                Nous pouvons créer une solution entièrement personnalisée pour
                votre entreprise. Discutons de votre vision lors d'une
                consultation gratuite.
              </p>
              <NavLink
                to="/contact"
                className="inline-flex items-center bg-white text-blue-700 px-6 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors group"
              >
                <span>Prendre contact</span>
                <ArrowRight
                  size={18}
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                />
              </NavLink>
            </div>
            <div className="hidden md:block md:w-1/3 h-full">
              <div className="h-full p-12 flex items-center justify-center">
                <div className="relative">
                  <div className="absolute -top-8 -left-8 w-32 h-32 bg-blue-500/20 rounded-full"></div>
                  <div className="absolute -bottom-8 -right-8 w-24 h-24 bg-indigo-500/20 rounded-full"></div>
                  <div className="relative z-10 bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/20 shadow-xl">
                    <div className="space-y-4">
                      <div className="flex items-center text-white gap-3">
                        <Shield className="w-5 h-5 opacity-70" />
                        <span>Projets sur mesure</span>
                      </div>
                      <div className="flex items-center text-white gap-3">
                        <PackageCheck className="w-5 h-5 opacity-70" />
                        <span>Solutions personnalisées</span>
                      </div>
                      <div className="flex items-center text-white gap-3">
                        <Clock className="w-5 h-5 opacity-70" />
                        <span>Accompagnement complet</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Shop;
