import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, ArrowLeft, RefreshCw, CheckCircle } from "lucide-react";
import { motion } from 'framer-motion';

function EmailVerificationPrompt() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 to-indigo-700 flex items-center justify-center p-4 mt-32">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative w-full max-w-md bg-white rounded-2xl shadow-xl overflow-hidden"
      >
        {/* Ligne de décoration en haut */}
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
        
        {/* Éléments décoratifs */}
        <div className="absolute top-0 right-0 w-48 h-48 rounded-full bg-gradient-to-r from-blue-100 to-indigo-100 opacity-10 -mr-16 -mt-16"></div>
        <div className="absolute -bottom-16 -left-16 w-48 h-48 rounded-full bg-gradient-to-r from-indigo-100 to-purple-100 opacity-10"></div>

        <div className="relative px-6 pt-10 pb-8 sm:px-10">
          {/* En-tête avec icône */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.2 }}
            className="flex flex-col items-center mb-8"
          >
            <div className="bg-blue-600 text-white p-3 rounded-full shadow-lg">
              <Mail size={28} />
            </div>
            <h2 className="mt-4 text-2xl sm:text-3xl font-bold text-gray-800 text-center">
              Vérifiez votre email
            </h2>
            <p className="mt-2 text-sm sm:text-base text-gray-500 text-center max-w-sm">
              Nous avons envoyé un email de confirmation à votre adresse. 
              Veuillez vérifier votre boîte de réception pour activer votre compte.
            </p>
          </motion.div>
          
          {/* Section informative */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded mb-6"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircle className="h-5 w-5 text-blue-600" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-blue-700">
                  L'activation de votre compte permet de sécuriser vos informations et d'accéder à toutes les fonctionnalités.
                </p>
              </div>
            </div>
          </motion.div>
          
          {/* Conseils */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="mb-6"
          >
            <h3 className="text-sm font-medium text-gray-700 mb-2">Si vous ne trouvez pas l'email :</h3>
            <ul className="text-sm text-gray-600 space-y-2 pl-5 list-disc">
              <li>Vérifiez votre dossier de courriers indésirables ou spam</li>
              <li>Assurez-vous d'avoir entré la bonne adresse email</li>
              <li>L'email peut prendre quelques minutes pour arriver</li>
            </ul>
          </motion.div>
          
          {/* Boutons d'action */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="flex flex-col sm:flex-row gap-4"
          >
            <motion.button
              whileHover={{ scale: 1.03 }}
              onClick={() => window.location.reload()}
              className="relative w-full py-3 px-4 flex items-center justify-center bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-white font-medium rounded-lg transition-all duration-300 overflow-hidden group"
            >
              <span className="inline-flex items-center relative z-10">
                <RefreshCw size={18} className="mr-2" />
                <span>Actualiser la page</span>
              </span>
              <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            </motion.button>
            
            <motion.button
              whileHover={{ scale: 1.03 }}
              onClick={() => navigate('/login')}
              className="w-full py-3 px-4 flex items-center justify-center bg-white text-blue-600 border border-blue-200 rounded-lg hover:bg-blue-50 transition-colors shadow-sm font-medium"
            >
              <ArrowLeft size={18} className="mr-2" />
              <span>Retour à la connexion</span>
            </motion.button>
          </motion.div>
          
          {/* Lien de support */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.7 }}
            className="mt-8 text-center"
          >
            <p className="text-sm text-gray-600">
              Besoin d'aide ? {' '}
              <button
                onClick={() => navigate('/nous-contacter')}
                className="inline-flex items-center font-medium text-blue-600 hover:text-blue-800 hover:underline transition-colors"
              >
                Contactez-nous
              </button>
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}

export default EmailVerificationPrompt;