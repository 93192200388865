import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import axios from 'axios';

export const UserContext = createContext();

axios.defaults.withCredentials = true;

// Durée d'inactivité avant déconnexion (en millisecondes)
const INACTIVITY_TIMEOUT = 2 * 60 * 1000; // 5 minutes

// Fonction utilitaire pour extraire les initiales
const getUserInitials = (userData) => {
  if (!userData) return 'U';

  // Ajout de log pour le débogage
  console.log('Génération des initiales pour:', userData);

  // Si on a firstname et lastname, utiliser leurs initiales
  if (userData.firstname && userData.lastname) {
    return (
      userData.firstname.charAt(0).toUpperCase() +
      userData.lastname.charAt(0).toUpperCase()
    );
  }

  // Données sociales (Facebook ou Google)
  // Utiliser name, given_name/family_name selon la disponibilité
  if (userData.name) {
    const nameParts = userData.name.split(' ');
    if (nameParts.length >= 2) {
      return (
        nameParts[0].charAt(0).toUpperCase() +
        nameParts[nameParts.length - 1].charAt(0).toUpperCase()
      );
    }
  }

  if (userData.given_name && userData.family_name) {
    return (
      userData.given_name.charAt(0).toUpperCase() +
      userData.family_name.charAt(0).toUpperCase()
    );
  }

  // Si on a juste un prénom
  if (userData.firstname) {
    return userData.firstname.substring(0, 2).toUpperCase();
  }

  // Si on a un email, extraire les initiales
  if (userData.email) {
    // Prendre la partie avant le @
    const emailName = userData.email.split('@')[0];

    // Extraire les initiales des parties de l'email
    if (emailName.includes('.')) {
      // S'il y a un point, utiliser les initiales des parties
      const parts = emailName.split('.');
      return (parts[0].charAt(0) + (parts[1]?.charAt(0) || '')).toUpperCase();
    } else if (emailName.includes('-')) {
      // S'il y a un tiret, utiliser les initiales des parties
      const parts = emailName.split('-');
      return (parts[0].charAt(0) + (parts[1]?.charAt(0) || '')).toUpperCase();
    } else if (emailName.includes('_')) {
      // S'il y a un underscore, utiliser les initiales des parties
      const parts = emailName.split('_');
      return (parts[0].charAt(0) + (parts[1]?.charAt(0) || '')).toUpperCase();
    } else if (emailName.length >= 2) {
      // S'il n'y a pas de séparateur, utiliser les 2 premières lettres
      return emailName.substring(0, 2).toUpperCase();
    }
  }

  // Fallback
  return 'U';
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecking, setIsAuthChecking] = useState(true);

  // Référence au timer d'inactivité
  const inactivityTimerRef = useRef(null);
  // Référence pour les fonctions afin d'éviter les dépendances circulaires
  const logoutUserRef = useRef();
  const resetInactivityTimerRef = useRef();

  // Fonction pour la déconnexion utilisateur
  const logoutUser = useCallback(() => {
    setUser(null);
    setJwt(null);
    setIsAuthenticated(false);

    localStorage.removeItem('jwt');
    localStorage.removeItem('user');

    // Nettoyer le timer d'inactivité
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
      inactivityTimerRef.current = null;
    }

    // Supprimer l'en-tête d'autorisation d'axios
    delete axios.defaults.headers.common['Authorization'];
  }, []);

  // Assigner la fonction à sa référence
  useEffect(() => {
    logoutUserRef.current = logoutUser;
  }, [logoutUser]);

  // Fonction pour réinitialiser le timer d'inactivité
  // Dans UserContext.js, version améliorée avec avertissement
  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }

    // Ne pas créer de timer si l'utilisateur n'est pas connecté
    if (!isAuthenticated) return;

    // Timer principal pour la déconnexion
    inactivityTimerRef.current = setTimeout(() => {
      console.log(
        'Déconnexion pour inactivité après',
        INACTIVITY_TIMEOUT / 60000,
        'minutes'
      );

      // Stocker la raison de la déconnexion
      sessionStorage.setItem('inactivityLogout', 'true');

      // Déconnecter l'utilisateur
      logoutUserRef.current();

      // Rediriger vers la page de connexion
      window.location.href = '/login';
    }, INACTIVITY_TIMEOUT);

    // Timer d'avertissement (2 minutes avant la déconnexion)
    const warningTime = INACTIVITY_TIMEOUT - 2 * 60 * 1000; // 2 minutes avant

    if (warningTime > 0) {
      setTimeout(() => {
        // Vérifier que l'utilisateur est toujours connecté
        if (isAuthenticated) {
          // Vous pouvez remplacer cet alert par une notification plus élégante
          const stayConnected = window.confirm(
            'Vous serez déconnecté dans 2 minutes pour inactivité. Cliquez sur OK pour rester connecté.'
          );

          if (stayConnected) {
            // L'utilisateur a cliqué sur OK, réinitialiser le timer
            resetInactivityTimerRef.current();
          }
        }
      }, warningTime);
    }
  }, [isAuthenticated]);

  // Assigner la fonction à sa référence
  useEffect(() => {
    resetInactivityTimerRef.current = resetInactivityTimer;
  }, [resetInactivityTimer]);

  // Configure axios avec le token JWT si disponible
  useEffect(() => {
    if (jwt) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [jwt]);

  // Fonction pour la connexion utilisateur - mise à jour
  const loginUser = useCallback((userData, token, rememberMe = false) => {
    // Vérifier le niveau de consentement aux cookies (code existant)
    const cookiesConsent = localStorage.getItem('cookiesAccepted') || 'none';

    // Gestion du rememberMe (code existant)
    if (cookiesConsent === 'essential' && rememberMe) {
      console.warn('Session limitée - consentement cookies restreint');
      rememberMe = false;
    }

    // Normaliser et enrichir les données utilisateur de manière plus robuste
    const enrichedUserData = {
      ...userData,

      // Normalisation des données de profil
      firstname:
        userData.firstname ||
        userData.given_name ||
        (userData.name ? userData.name.split(' ')[0] : '') ||
        '',

      lastname:
        userData.lastname ||
        userData.family_name ||
        (userData.name?.split(' ').length > 1
          ? userData.name.split(' ').slice(1).join(' ')
          : '') ||
        '',

      // Avatar - prendre en compte différentes sources possibles
      avatar:
        userData.avatar ||
        userData.picture ||
        userData.picture?.data?.url ||
        null,

      // Identifier clairement la source de l'authentification
      provider:
        userData.provider ||
        (userData.facebook_id
          ? 'facebook'
          : userData.google_id
            ? 'google'
            : 'local'),

      // Conserver les IDs spécifiques
      facebook_id:
        userData.facebook_id ||
        (userData.provider === 'facebook' ? userData.id : undefined),

      google_id:
        userData.google_id ||
        (userData.provider === 'google' ? userData.id : undefined),

      // Générer les initiales
      initials: userData.initials || getUserInitials(userData),
    };

    // Mettre à jour l'état
    setUser(enrichedUserData);
    setJwt(token);
    setIsAuthenticated(true);

    // Stocker dans localStorage
    localStorage.setItem('jwt', token);
    localStorage.setItem('user', JSON.stringify(enrichedUserData));
    localStorage.setItem('rememberMe', rememberMe.toString());

    // Démarrer le timer d'inactivité lors de la connexion
    resetInactivityTimerRef.current();

    // Vérifier s'il y a une redirection après connexion
    setTimeout(() => {
      const redirectPath = sessionStorage.getItem('redirectAfterLogin');
      if (redirectPath) {
        console.log('Redirection après connexion vers:', redirectPath);
        sessionStorage.removeItem('redirectAfterLogin'); // Nettoyer après utilisation
        window.location.href = redirectPath; // Utiliser window.location.href pour une redirection complète
      }
    }, 100); // Petit délai pour s'assurer que l'état d'authentification est bien mis à jour
  }, []);
  
  // Vérification d'authentification au chargement
  useEffect(() => {
    const checkAuth = async () => {
      setIsAuthChecking(true);
      try {
        const storedJwt = localStorage.getItem('jwt');
        const storedUserStr = localStorage.getItem('user');
        const rememberMe = localStorage.getItem('rememberMe') === 'true';

        if (storedJwt && storedUserStr) {
          let storedUser = JSON.parse(storedUserStr);

          // Vérifier et ajouter les initiales si elles manquent
          if (!storedUser.initials) {
            storedUser = {
              ...storedUser,
              initials: getUserInitials(storedUser),
            };
            // Mettre à jour le localStorage avec les initiales
            localStorage.setItem('user', JSON.stringify(storedUser));
          }

          try {
            axios.defaults.headers.common['Authorization'] =
              `Bearer ${storedJwt}`;

            try {
              await axios.get(
                'https://vgomcreation-api.onrender.com/api/users/me',
                {
                  withCredentials: true,
                }
              );

              setJwt(storedJwt);
              setUser(storedUser);
              setIsAuthenticated(true);
              // Démarrer le timer d'inactivité
              resetInactivityTimerRef.current();
            } catch (apiError) {
              console.warn('Erreur de vérification API:', apiError);
              if (!rememberMe) {
                logoutUserRef.current();
              }
            }
          } catch (verifyError) {
            console.error('Erreur de vérification du token:', verifyError);
            if (!rememberMe) {
              logoutUserRef.current();
            }
          }
        }
      } catch (error) {
        console.error(
          "Erreur lors de la vérification d'authentification:",
          error
        );
        logoutUserRef.current();
      } finally {
        setIsAuthChecking(false);
      }
    };

    checkAuth();
  }, []);

  // Intercepteur de réponse pour gérer les 401
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          logoutUserRef.current(); // Utiliser la référence
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []); // Plus besoin de dépendances

  // Gestionnaires d'événements pour détecter l'activité de l'utilisateur
  useEffect(() => {
    // Liste des événements à surveiller pour l'activité utilisateur
    const activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
      'click',
      'keypress',
    ];

    // Fonction qui sera appelée à chaque activité
    const handleUserActivity = () => {
      resetInactivityTimerRef.current(); // Utiliser la référence
    };

    // Ajout des écouteurs d'événements
    if (isAuthenticated) {
      activityEvents.forEach((event) => {
        window.addEventListener(event, handleUserActivity);
      });
    }

    // Nettoyage à la destruction du composant
    return () => {
      activityEvents.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });

      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [isAuthenticated]); // Plus besoin de resetInactivityTimer comme dépendance

  return (
    <UserContext.Provider
      value={{
        user,
        jwt,
        isAuthenticated,
        isAuthChecking,
        loginUser,
        logoutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
