import React from 'react';
import { motion } from 'framer-motion';
import { RefreshCw } from "lucide-react";

function LoadingScreen({ message = "Chargement..." }) {
  return (
    <motion.div 
      className="fixed inset-0 bg-gradient-to-br from-blue-600 to-indigo-700 flex items-center justify-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="relative">
        {/* Effet de lueur en arrière-plan */}
        <motion.div 
          className="absolute -inset-10 bg-blue-500 rounded-full opacity-20 blur-3xl"
          animate={{ 
            scale: [1, 1.2, 1],
            opacity: [0.1, 0.3, 0.1] 
          }}
          transition={{ 
            duration: 3,
            repeat: Infinity,
            repeatType: "reverse"
          }}
        />
        
        {/* Cercles concentriques animés */}
        <div className="relative">
          <motion.div 
            className="absolute inset-0 rounded-full border-4 border-blue-300 border-opacity-20"
            style={{ width: 120, height: 120, left: -30, top: -30 }}
            animate={{ rotate: 360 }}
            transition={{ 
              duration: 8, 
              repeat: Infinity, 
              ease: "linear" 
            }}
          />
          <motion.div 
            className="absolute inset-0 rounded-full border-4 border-indigo-400 border-opacity-20"
            style={{ width: 140, height: 140, left: -40, top: -40 }}
            animate={{ rotate: -360 }}
            transition={{ 
              duration: 12, 
              repeat: Infinity, 
              ease: "linear" 
            }}
          />
        </div>
        
        {/* Contenu central */}
        <motion.div 
          className="flex flex-col items-center gap-6 bg-white bg-opacity-10 backdrop-blur-lg rounded-2xl p-8 shadow-2xl border border-white border-opacity-20"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.1, duration: 0.5 }}
        >
          <div className="relative">
            {/* Cercle principal */}
            <motion.div 
              className="w-20 h-20 rounded-full bg-gradient-to-tr from-blue-500 to-indigo-600 flex items-center justify-center shadow-lg"
              animate={{ 
                boxShadow: [
                  "0 0 0 rgba(79, 70, 229, 0.4)", 
                  "0 0 20px rgba(79, 70, 229, 0.6)", 
                  "0 0 0 rgba(79, 70, 229, 0.4)"
                ]
              }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ 
                  duration: 2, 
                  repeat: Infinity, 
                  ease: "linear" 
                }}
              >
                <RefreshCw className="w-8 h-8 text-white" />
              </motion.div>
            </motion.div>
            
            {/* Petites particules animées */}
            {[...Array(6)].map((_, i) => (
              <motion.div
                key={i}
                className="absolute w-2 h-2 bg-blue-400 rounded-full"
                style={{ 
                  top: '50%', 
                  left: '50%',
                  margin: '-1px 0 0 -1px' 
                }}
                animate={{
                  x: [0, (50 + i * 10) * Math.cos(i * 60 * Math.PI/180)],
                  y: [0, (50 + i * 10) * Math.sin(i * 60 * Math.PI/180)],
                  opacity: [0, 1, 0],
                  scale: [0.8, 1.2, 0.8]
                }}
                transition={{
                  duration: 2 + i * 0.2,
                  repeat: Infinity,
                  delay: i * 0.1
                }}
              />
            ))}
          </div>
          
          <div className="text-center space-y-2">
            <motion.p 
              className="text-white text-xl font-medium"
              animate={{ 
                opacity: [0.7, 1, 0.7]
              }}
              transition={{ 
                duration: 1.5, 
                repeat: Infinity 
              }}
            >
              {message}
            </motion.p>
            <motion.div 
              className="w-36 h-1 bg-gradient-to-r from-blue-400 to-indigo-500 rounded-full mx-auto"
              initial={{ width: 0 }}
              animate={{ width: 144 }}
              transition={{ 
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse"
              }}
            />
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default LoadingScreen;