import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { servicesData } from '../data/servicesData';
import { Link } from 'react-router-dom';
import BackHomeButton from '../components/BackHomeButton';
import {
  ChevronRight,
  Star,
  Clock,
  CheckCircle,
  ArrowRight,
} from 'lucide-react';

const NosOffres = () => {
  // Filtrer uniquement le service spécial
  const filteredServices = servicesData.filter(
    (service) => service.id !== 'special'
  );

  // Animation variants pour Framer Motion
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const serviceItemVariant = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 mt-28 md:mt-36">
      {/* Hero Section */}
      <div className="container mx-auto px-4">
        <motion.div
          className="text-center mb-0 md:mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-extrabold text-gray-900 mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-700">
              Nos Services
            </span>
          </h1>
          <p className="text-gray-600 text-lg max-w-2xl mx-auto">
            Des solutions digitales sur mesure pour répondre à tous vos besoins,
            qu'il s'agisse de sites vitrines, d'e-commerce ou d'applications
            web.
          </p>
        </motion.div>
      </div>

      {/* Conteneur principal pour le contenu */}
      <div className="container mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-2">
          <div>
            <BackHomeButton />
          </div>
        </div>
        {/* Grille des services avec animations */}
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-10"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <AnimatePresence>
            {filteredServices.map((service) => (
              <motion.div
                key={service.id}
                className={`bg-white rounded-2xl overflow-hidden transition-all shadow-lg hover:shadow-2xl group ${
                  service.customDesign ? 'relative' : ''
                }`}
                variants={serviceItemVariant}
                whileHover={{ y: -5 }}
              >
                {/* Badge "À venir" */}
                {service.comingSoon && (
                  <div className="absolute top-4 right-4 z-10">
                    <div className="flex items-center gap-1 bg-gradient-to-r from-yellow-400 to-yellow-600 text-white text-xs font-bold px-3 py-1.5 rounded-full shadow-md">
                      <Clock size={14} />
                      <span>À venir</span>
                    </div>
                  </div>
                )}

                {/* Badge "Populaire" si le service est marqué comme populaire */}
                {service.popular && (
                  <div className="absolute top-4 left-4 z-10">
                    <div className="flex items-center gap-1 bg-gradient-to-r from-green-400 to-green-600 text-white text-xs font-bold px-3 py-1.5 rounded-full shadow-md">
                      <Star size={14} />
                      <span>Populaire</span>
                    </div>
                  </div>
                )}

                {/* Service standard */}
                {!service.customDesign ? (
                  <>
                    <div className="overflow-hidden h-52 sm:h-56">
                      <img
                        src={service.image}
                        alt={service.title}
                        className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-700"
                      />
                    </div>
                    <div className="p-6">
                      <h3 className="text-xl font-bold text-gray-800 mb-3 group-hover:text-google transition-colors">
                        {service.title}
                      </h3>
                      <ul className="space-y-3 text-gray-600 mb-6">
                        {service.items.slice(0, 4).map((item, index) => (
                          <li key={index} className="flex items-start">
                            <CheckCircle
                              size={18}
                              className="text-google mr-2 flex-shrink-0 mt-1"
                            />
                            <span className="text-sm">{item}</span>
                          </li>
                        ))}
                        {service.items.length > 4 && (
                          <li className="text-google font-medium text-sm pt-1">
                            + {service.items.length - 4} autres avantages
                          </li>
                        )}
                      </ul>
                      {!service.comingSoon ? (
                        <Link
                          to={service.link || '/tarifs'}
                          className="inline-flex items-center bg-gradient-to-r from-google to-blue-700 text-white px-5 py-2.5 rounded-lg font-medium transition-all hover:from-blue-700 hover:to-google hover:shadow-md"
                        >
                          <span>En savoir plus</span>
                          <ChevronRight
                            size={18}
                            className="ml-1 transition-transform group-hover:translate-x-1"
                          />
                        </Link>
                      ) : (
                        <p className="flex items-center text-yellow-600 font-medium">
                          <Clock size={18} className="mr-2" />
                          Disponible bientôt
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  // Service avec design personnalisé
                  <div className="p-6 bg-gradient-to-br from-blue-50 to-indigo-100 h-full flex flex-col relative overflow-hidden">
                    <div className="absolute top-0 right-0 w-32 h-32 bg-blue-200 opacity-30 rounded-full -mr-16 -mt-16 z-0"></div>
                    <div className="absolute bottom-0 left-0 w-24 h-24 bg-indigo-200 opacity-30 rounded-full -ml-12 -mb-12 z-0"></div>

                    <div className="relative z-10">
                      <h3 className="text-2xl font-bold text-gray-900 mb-3">
                        {service.title}
                      </h3>
                      <p className="text-gray-600 mb-4">
                        {service.description}
                      </p>
                      <div className="mb-6 border-t border-b border-gray-200 py-4 my-4">
                        <h4 className="font-semibold text-gray-900 mb-3">
                          Inclus :
                        </h4>
                        <ul className="space-y-3">
                          {service.details.map((detail, index) => (
                            <li key={index} className="flex items-start">
                              <CheckCircle
                                size={18}
                                className="text-google mr-2 flex-shrink-0 mt-1"
                              />
                              <span className="text-sm text-gray-700">
                                {detail}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {!service.comingSoon ? (
                        <div className="mt-auto">
                          <Link
                            to={service.link || '/tarifs'}
                            className="inline-flex items-center bg-gradient-to-r from-google to-blue-700 text-white px-5 py-2.5 rounded-lg font-medium transition-all hover:from-blue-700 hover:to-google hover:shadow-md"
                          >
                            <span>Découvrir l'offre</span>
                            <ChevronRight
                              size={18}
                              className="ml-1 transition-transform group-hover:translate-x-1"
                            />
                          </Link>
                        </div>
                      ) : (
                        <p className="flex items-center text-yellow-600 font-medium mt-auto">
                          <Clock size={18} className="mr-2" />
                          Disponible bientôt
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>

      {/* Section projets clé en main */}
      <motion.div
        className="bg-gradient-to-r from-gray-900 to-black py-8 md:py-24 mt-8 md:mt-24 relative overflow-hidden"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        {/* Background dots pattern */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-gray-400 to-transparent"></div>
          <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-gray-400 to-transparent"></div>
          <div className="absolute inset-0 grid grid-cols-10 gap-6">
            {[...Array(100)].map((_, i) => (
              <div
                key={i}
                className="w-1 h-1 bg-white rounded-full opacity-50"
              ></div>
            ))}
          </div>
        </div>

        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2, duration: 0.7 }}
              className="mb-10"
            >
              <span className="inline-block bg-gradient-to-r from-blue-400 to-indigo-500 text-white text-xs font-bold px-3 py-1 rounded-full mb-4">
                Nouveauté
              </span>
              <h2 className="text-3xl sm:text-4xl font-bold text-white mb-6">
                Des projets clé en main, prêts à l'achat
              </h2>
            </motion.div>
            <motion.p
              className="text-base sm:text-lg text-gray-300 mb-8 leading-relaxed"
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4, duration: 0.7 }}
            >
              En plus de nos services sur mesure, nous proposons des projets web
              clé en main, disponibles dans notre boutique. Ces projets incluent
              la personnalisation des textes et des images, qui doivent être
              fournis par le client.
            </motion.p>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.6, duration: 0.7 }}
            >
              <Link
                to="/shop"
                className="inline-flex items-center bg-white hover:bg-gray-100 text-black px-8 py-3.5 rounded-lg text-lg font-medium transition-all hover:shadow-xl group cursor-pointer"
              >
                <span>Découvrir nos projets</span>
                <ArrowRight
                  size={20}
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                />
              </Link>
            </motion.div>
          </div>
        </div>
      </motion.div>

      {/* Appel à l'action */}
      <div className="bg-white py-6 md:py-20">
        <div className="container mx-auto px-4">
          <motion.div
            className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-2xl p-10 sm:p-16 text-center relative overflow-hidden shadow-xl border border-blue-100"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            {/* Background decorative elements */}
            <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-blue-200 to-blue-300 opacity-20 rounded-full -mr-32 -mt-32"></div>
            <div className="absolute bottom-0 left-0 w-64 h-64 bg-gradient-to-tr from-indigo-200 to-indigo-300 opacity-20 rounded-full -ml-32 -mb-32"></div>

            <div className="relative z-10">
              <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-6">
                Prêt à démarrer votre projet digital ?
              </h2>
              <p className="text-gray-600 text-lg mb-10 max-w-2xl mx-auto">
                Contactez-nous pour discuter de vos besoins et obtenir un devis
                personnalisé. Notre équipe est à votre disposition pour
                transformer votre vision en réalité.
              </p>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <Link
                  to="/contact"
                  className="inline-flex items-center bg-gradient-to-r from-blue-600 to-indigo-700 text-white px-10 py-4 rounded-lg text-xl font-medium hover:shadow-lg hover:from-blue-700 hover:to-indigo-800 transition-all group"
                >
                  <span>Nous contacter</span>
                  <ArrowRight
                    size={20}
                    className="ml-2 group-hover:translate-x-1 transition-transform"
                  />
                </Link>

                <a
                  href="https://forms.gle/Wqq9dcyKvvYXVtKM6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center bg-white border-2 border-indigo-600 text-indigo-700 px-8 py-3.5 rounded-lg text-lg font-medium hover:bg-indigo-50 transition-all group"
                >
                  <span>Formulaire de devis</span>
                  <ChevronRight
                    size={18}
                    className="ml-1 group-hover:translate-x-1 transition-transform"
                  />
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default NosOffres;
