import React, { useState, useEffect } from "react";
import { ArrowUp } from "lucide-react";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const location = useLocation(); // Permet de détecter les changements de route

  // Fonction pour gérer le défilement vers le haut
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Animation fluide
    });
  };

  // Fonction pour vérifier la position de défilement
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll automatique lors d'un changement de page
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant", // Défilement immédiat au chargement de la page
    });
  }, [location]); // Déclenchement à chaque changement de route

  // Ajouter un écouteur d'événements pour le défilement
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility); // Nettoyage
    };
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          onClick={scrollToTop}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          initial={{ opacity: 0, scale: 0.5, y: 20 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.5, y: 20 }}
          transition={{ duration: 0.3, type: "spring", stiffness: 300 }}
          className="fixed bottom-6 z-50 right-6 p-4 rounded-full shadow-lg focus:outline-none group"
          aria-label="Retour en haut"
          style={{
            background: "linear-gradient(135deg, #4338CA, #6366F1)", // Plus indigo
            boxShadow: isHovering 
              ? "0 8px 20px -5px rgba(67, 56, 202, 0.6)"
              : "0 4px 10px -3px rgba(67, 56, 202, 0.4)"
          }}
        >
          <div className="relative">
            {/* Anneau extérieur animé */}
            <motion.div
              className="absolute -inset-1 rounded-full opacity-0 group-hover:opacity-100"
              animate={{ 
                scale: isHovering ? [1, 1.1, 1] : 1,
                opacity: isHovering ? [0, 0.4, 0] : 0 
              }}
              transition={{ 
                duration: 1.5, 
                repeat: Infinity,
                ease: "easeInOut"
              }}
              style={{
                background: "linear-gradient(135deg, rgba(67, 56, 202, 0.7), rgba(99, 102, 241, 0.7))",
              }}
            />
            
            {/* Icône de flèche avec animation au survol */}
            <motion.div
              animate={{ y: isHovering ? -3 : 0 }}
              transition={{ type: "spring", stiffness: 500 }}
            >
              <ArrowUp size={20} className="text-white" />
            </motion.div>
          </div>

          {/* Label qui apparaît au survol */}
          <div className="absolute right-full mr-2 top-1/2 transform -translate-y-1/2 pointer-events-none">
            <AnimatePresence>
              {isHovering && (
                <motion.div 
                  initial={{ opacity: 0, x: 10 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 10 }}
                  className="whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium text-white shadow-sm"
                  style={{
                    background: "linear-gradient(135deg, #4338CA, #6366F1)", // Plus indigo
                  }}
                >
                  Retour en haut
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.button>
      )}
    </AnimatePresence>
  );
};

export default ScrollToTop;