// src/components/CookieConsent.js
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Info } from 'lucide-react';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  // Dans votre composant CookieConsent.js, ajoutez dans le useEffect :

useEffect(() => {
    // Vérifier si l'utilisateur a déjà accepté les cookies
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      // Afficher la bannière après un court délai
      const timer = setTimeout(() => {
        setShowBanner(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
    
    // Écouter l'événement pour réafficher la bannière
    const handleShowConsent = () => setShowBanner(true);
    window.addEventListener('showCookieConsent', handleShowConsent);
    
    return () => window.removeEventListener('showCookieConsent', handleShowConsent);
  }, []);
  const acceptAllCookies = () => {
    localStorage.setItem('cookiesAccepted', 'all');
    localStorage.setItem('cookiesAcceptedAt', new Date().toString());
    setShowBanner(false);
  };

  const acceptEssentialCookies = () => {
    localStorage.setItem('cookiesAccepted', 'essential');
    localStorage.setItem('cookiesAcceptedAt', new Date().toString());
    setShowBanner(false);
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <AnimatePresence>
      {showBanner && (
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed bottom-0 left-0 right-0 z-50 p-4 bg-white shadow-lg border-t border-gray-200"
        >
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-gray-800">Nous utilisons des cookies</h3>
                <p className="mt-1 text-sm text-gray-600">
                  Ce site utilise des cookies pour améliorer votre expérience, notamment pour l'authentification 
                  et les connexions via les réseaux sociaux. 
                  <button 
                    onClick={toggleDetails} 
                    className="ml-1 text-blue-600 hover:text-blue-800 hover:underline inline-flex items-center"
                  >
                    En savoir plus
                    <Info size={14} className="ml-1" />
                  </button>
                </p>
                
                <AnimatePresence>
                  {showDetails && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      className="overflow-hidden"
                    >
                      <div className="mt-3 p-3 bg-gray-50 rounded-md text-sm">
                        <h4 className="font-medium text-gray-800">Types de cookies utilisés :</h4>
                        <ul className="mt-2 space-y-1 list-disc pl-5">
                          <li><span className="font-medium">Cookies essentiels :</span> Nécessaires au fonctionnement du site (session, sécurité)</li>
                          <li><span className="font-medium">Cookies d'authentification :</span> Permettent de vous connecter et de rester connecté</li>
                          <li><span className="font-medium">Cookies tiers :</span> Utilisés lors de l'authentification via Google ou Facebook</li>
                        </ul>
                        <p className="mt-2">
                          Pour plus d'informations, consultez notre {" "}
                          <a href="/politique-confidentialite" className="text-blue-600 hover:underline">politique de confidentialité</a>.
                        </p>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              
              <div className="flex flex-col sm:flex-row gap-2">
                <button
                  onClick={acceptEssentialCookies}
                  className="py-2 px-4 text-sm bg-gray-200 hover:bg-gray-300 rounded-md transition-colors duration-200"
                >
                  Cookies essentiels uniquement
                </button>
                <button
                  onClick={acceptAllCookies}
                  className="py-2 px-4 text-sm bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors duration-200"
                >
                  Accepter tous les cookies
                </button>
              </div>
              
              <button 
                onClick={() => setShowBanner(false)} 
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                aria-label="Fermer"
              >
                <X size={20} />
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookieConsent;