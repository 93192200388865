import React, { useRef, useEffect, useState } from "react";
import { ChevronDown } from "lucide-react";

const Accordion = ({ title, children, isOpen, onToggle, className = "" }) => {
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");

  useEffect(() => {
    // Mettre à jour la hauteur maximale quand l'accordéon s'ouvre ou se ferme
    if (isOpen) {
      // Limiter la hauteur maximale et permettre le défilement
      setMaxHeight("500px"); // Hauteur maximale fixe
    } else {
      setMaxHeight("0px");
    }
  }, [isOpen]);

  return (
    <div className={`mb-6 ${className}`}>
      <button
        onClick={onToggle}
        aria-expanded={isOpen}
        aria-controls={`accordion-content-${title.replace(/\s+/g, '-').toLowerCase()}`}
        className={`w-full text-left p-4 md:p-5 font-semibold text-gray-800 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex justify-between items-center rounded-t-lg ${
          isOpen
            ? "bg-blue-50 hover:bg-blue-100 text-blue-700"
            : "bg-white hover:bg-gray-50"
        }`}
      >
        <span className="text-base md:text-lg">{title}</span>
        <ChevronDown 
          size={20} 
          className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'} text-gray-500 flex-shrink-0 ml-2`} 
        />
      </button>

      <div
        ref={contentRef}
        id={`accordion-content-${title.replace(/\s+/g, '-').toLowerCase()}`}
        className={`transition-all duration-300 ease-in-out bg-white ${isOpen ? 'overflow-y-auto' : 'overflow-hidden'}`}
        style={{
          maxHeight,
          opacity: isOpen ? 1 : 0,
          visibility: isOpen ? "visible" : "hidden",
        }}
      >
        <div className="p-4 md:p-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;