import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  CreditCard,
  AlertCircle,
  Loader2,
  Lock,
  Check,
  LogIn,
} from 'lucide-react';
import { UserContext } from '../context/UserContext'; // Importez le contexte utilisateur
import { useNavigate } from 'react-router-dom'; // Pour rediriger vers la page de connexion

const CheckoutButton = ({
  priceId,
  projectId,
  projectTitle,
  customClass = '',
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { isAuthenticated } = useContext(UserContext); // Récupération de l'état d'authentification
  const navigate = useNavigate();

  const handleCreateCheckoutSession = async () => {
    // Si l'utilisateur n'est pas connecté, rediriger vers la page de connexion
    if (!isAuthenticated) {
      // Sauvegarder l'URL actuelle pour rediriger l'utilisateur après connexion
      sessionStorage.setItem('redirectAfterLogin', window.location.pathname);
      navigate('/connexion');
      return;
    }
    try {
      setLoading(true);
      setErrorMessage(null);
      console.log('🔍 Données envoyées:', { priceId, projectId });

      // Vérification des données
      if (!priceId) {
        console.error('❌ PriceId manquant');
        setErrorMessage(
          'PriceId manquant. Impossible de procéder au paiement.'
        );
        setLoading(false);
        return;
      }

      // Collecter les informations client depuis localStorage si disponibles
      const savedCustomerInfo = localStorage.getItem('customerInfo');
      let customerInfo = savedCustomerInfo ? JSON.parse(savedCustomerInfo) : {};

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/projets/create-checkout-session`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            priceId,
            projetId: projectId,
            customerEmail: customerInfo.email,
            customerName: customerInfo.name,
            projectTitle,
          }),
        }
      );

      console.log('📡 Statut de la réponse:', response.status);

      if (!response.ok) {
        const errorData = await response.json();
        console.error('❌ Erreur détaillée:', errorData);
        throw new Error(
          errorData.error || 'Impossible de créer une session de paiement.'
        );
      }

      const data = await response.json();
      console.log('✅ Réponse reçue:', data);

      if (data.url) {
        // Animation de succès avant redirection
        setSuccess(true);

        // Sauvegarder l'ID de session pour la page de succès
        sessionStorage.setItem('checkoutSessionId', data.sessionId);

        // Redirection avec délai pour permettre l'animation
        setTimeout(() => {
          window.location.href = data.url;
        }, 1000);
      } else {
        throw new Error('Lien de paiement manquant dans la réponse.');
      }
    } catch (error) {
      setErrorMessage(
        'Une erreur est survenue lors de la création de la session de paiement.'
      );
      console.error('Erreur détaillée:', error);
      setLoading(false);
    }
  };

  // Bouton spécifique pour les utilisateurs non connectés
  const renderLoginButton = () => (
    <motion.button
      onClick={() => {
        sessionStorage.setItem('redirectAfterLogin', window.location.pathname);
        navigate('/login');
      }}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className={`
        py-3 px-6 rounded-lg font-medium shadow-md
        flex items-center justify-center gap-3 transition-all duration-300
        bg-gradient-to-r from-gray-500 to-gray-700 text-white hover:from-gray-600 hover:to-gray-800
      `}
    >
      <div className="flex items-center gap-2">
        <LogIn size={18} />
        <span>Connectez-vous pour acheter</span>
      </div>
    </motion.button>
  );

  // Afficher un message informatif pour les non-connectés
  const renderAuthInfo = () => {
    if (!isAuthenticated) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded-md flex items-start mb-4"
        >
          <Lock className="text-blue-500 mr-3 flex-shrink-0" size={20} />
          <p className="text-blue-700 text-sm">
            Vous devez être connecté pour effectuer un achat. Connectez-vous ou
            créez un compte.
          </p>
        </motion.div>
      );
    }
    return null;
  };

  return (
    <div className={`flex flex-col gap-4 ${customClass}`}>
      {renderAuthInfo()}

      <AnimatePresence>
        {errorMessage && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, height: 0, marginBottom: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-red-50 border-l-4 border-red-500 p-4 rounded-md flex items-start"
          >
            <AlertCircle
              className="text-red-500 mr-3 flex-shrink-0"
              size={20}
            />
            <p className="text-red-700 text-sm">{errorMessage}</p>
          </motion.div>
        )}
      </AnimatePresence>

      {isAuthenticated ? (
        // Bouton normal pour les utilisateurs connectés
        <motion.button
          onClick={handleCreateCheckoutSession}
          disabled={loading || success}
          whileHover={!loading && !success ? { scale: 1.02 } : {}}
          whileTap={!loading && !success ? { scale: 0.98 } : {}}
          className={`
            relative overflow-hidden py-3 px-6 rounded-lg font-medium shadow-md
            flex items-center justify-center gap-3 transition-all duration-300
            ${
              success
                ? 'bg-green-600 text-white'
                : 'bg-gradient-to-r from-blue-600 to-indigo-700 text-white hover:from-blue-700 hover:to-indigo-800'
            }
            ${loading || success ? 'cursor-not-allowed' : 'cursor-pointer'}
          `}
        >
          <motion.div
            className="absolute inset-0 bg-white opacity-0"
            animate={{
              opacity: loading ? [0, 0.1, 0] : 0,
            }}
            transition={{
              duration: 1,
              repeat: loading ? Infinity : 0,
            }}
          />

          {success ? (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="flex items-center gap-2"
            >
              <Check size={20} className="text-white" />
              <span>Redirection vers le paiement...</span>
            </motion.div>
          ) : loading ? (
            <div className="flex items-center gap-2">
              <Loader2 className="animate-spin" size={20} />
              <span>Préparation du paiement...</span>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <CreditCard size={18} />
              <span>Acheter maintenant</span>
              <Lock size={14} className="ml-1 opacity-70" />
            </div>
          )}
        </motion.button>
      ) : (
        // Bouton alternatif pour les utilisateurs non connectés
        renderLoginButton()
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="flex items-center justify-center text-xs text-gray-500 mt-1"
      >
        <Lock size={12} className="mr-1" />
        Paiement sécurisé via Stripe
      </motion.div>
    </div>
  );
};

export default CheckoutButton;
