import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const BackHomeButton = () => {
  const navigate = useNavigate();
  
  return (
    <div className="container mx-auto my-4 flex justify-start">
      <button
        onClick={() => navigate('/')}
        className="group flex items-center gap-2 max-w-fit px-3 py-1 sm:px-4 sm:py-2 rounded-lg
                  text-white text-sm font-medium
                  bg-gradient-to-r from-bootstrap to-violetta
                  hover:bg-gradient-to-r hover:from-blue-700 hover:to-indigo-700
                  shadow-md hover:shadow-lg
                  border border-opacity-20 border-white"
      >
        <ArrowLeft 
          className="w-4 h-4 sm:w-5 sm:h-5 group-hover:-translate-x-1 transition-transform duration-300 ease-in-out"
        />
        <span>Accueil</span>
      </button>
    </div>
  );
};

export default BackHomeButton;
