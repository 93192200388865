import React from 'react';
import { motion } from 'framer-motion';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { Mail, MapPin, PhoneCall, Cookie } from 'lucide-react';
import {
  FaLinkedin,
  FaGithub,
  FaSlack,
  FaDiscord,
  FaBehance,
  FaPinterest,
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaYoutube,
} from 'react-icons/fa';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const handleShowCookieSettings = () => {
    localStorage.removeItem('cookiesAccepted');
    // Déclencher l'événement pour afficher à nouveau la bannière
    window.dispatchEvent(new Event('showCookieConsent'));
  };

  // Social media links
  const socialLinks = [
    {
      href: 'https://www.linkedin.com/in/vincent-vgom',
      icon: FaLinkedin,
      label: 'LinkedIn',
      color: 'hover:text-linkedin',
      bgColor: 'hover:bg-linkedin/10',
    },
    {
      href: 'https://github.com/der411',
      icon: FaGithub,
      label: 'GitHub',
      color: 'hover:text-github',
      bgColor: 'hover:bg-github/10',
    },
    {
      href: 'https://webdev-hyw5242.slack.com/team/U06LYPWHUUB',
      icon: FaSlack,
      label: 'Slack',
      color: 'hover:text-slack',
      bgColor: 'hover:bg-slack/10',
    },
    {
      href: 'https://discord.gg/QqKW5Xskpp',
      icon: FaDiscord,
      label: 'Discord',
      color: 'hover:text-discord',
      bgColor: 'hover:bg-discord/10',
    },
    {
      href: 'https://www.behance.net/vintedvv',
      icon: FaBehance,
      label: 'Behance',
      color: 'hover:text-behance',
      bgColor: 'hover:bg-behance/10',
    },
    {
      href: 'https://www.pinterest.com/vaitilingomvincent',
      icon: FaPinterest,
      label: 'Pinterest',
      color: 'hover:text-pinterest',
      bgColor: 'hover:bg-pinterest/10',
    },
    {
      href: 'https://x.com/VaitilingomV',
      icon: FaTwitter,
      label: 'Twitter',
      color: 'hover:text-twitter',
      bgColor: 'hover:bg-twitter/10',
    },
    {
      href: 'https://instagram.com/vgom_creation',
      icon: FaInstagram,
      label: 'Instagram',
      color: 'hover:text-instagram',
      bgColor: 'hover:bg-instagram/10',
    },
    {
      href: 'https://www.facebook.com/profile.php?id=100084983017139',
      icon: FaFacebook,
      label: 'Facebook',
      color: 'hover:text-facebook',
      bgColor: 'hover:bg-facebook/10',
    },
    {
      href: 'https://www.youtube.com/@vincentvgom',
      icon: FaYoutube,
      label: 'YouTube',
      color: 'hover:text-youtube',
      bgColor: 'hover:bg-youtube/10',
    },
  ];

  return (
    <>
      <footer className="bg-black text-white">
        <div className="container mx-auto px-4 py-6 md:py-12 lg:py-16">
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-12"
          >
            {/* Logo et présentation */}
            <motion.div variants={itemVariants} className="flex flex-col items-center md:items-start">
              <div className="w-full flex justify-center md:justify-start">
                <Link to="/" className="mb-4 inline-block">
                  <img src={logo} alt="VGom Creation" className="h-28 md:h-32 w-auto" />
                </Link>
              </div>
              <p className="text-gray-400 text-sm md:text-base max-w-xs text-left">
                Solutions web professionnelles pour votre entreprise. Création de sites internet et stratégie digitale sur mesure.
              </p>
            </motion.div>

            {/* Services */}
            <motion.div variants={itemVariants} className="flex flex-col items-start">
              <div className="w-full flex justify-center md:justify-start">
                <h3 className="text-lg md:text-xl font-bold mb-4 text-white">Nos services</h3>
              </div>
              <ul className="space-y-2 text-left w-full px-4 md:px-0">
                <li>
                  <Link to="/nos-offres" className="text-gray-400 hover:text-white transition-colors">Création de site web</Link>
                </li>
                <li>
                  <Link to="/nos-offres" className="text-gray-400 hover:text-white transition-colors">E-commerce</Link>
                </li>
                <li>
                  <Link to="/nos-offres" className="text-gray-400 hover:text-white transition-colors">Refonte de site</Link>
                </li>
                <li>
                  <Link to="/nos-offres" className="text-gray-400 hover:text-white transition-colors">Site sur mesure</Link>
                </li>
                <li>
                  <Link to="/tarifs" className="text-gray-400 hover:text-white transition-colors">Nos tarifs</Link>
                </li>
              </ul>
            </motion.div>

            {/* Informations de contact */}
            <motion.div variants={itemVariants} className="flex flex-col items-start">
              <div className="w-full flex justify-center md:justify-start">
                <h3 className="text-lg md:text-xl font-bold mb-4 text-white">Contact</h3>
              </div>
              <ul className="space-y-3 text-left w-full px-4 md:px-0">
                <li>
                  <a href="tel:0767603449" className="flex items-center gap-3 text-gray-400 hover:text-white transition-colors">
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-600/20">
                      <PhoneCall size={16} className="text-blue-500" />
                    </div>
                    <span>07 67 60 34 49</span>
                  </a>
                </li>
                <li>
                  <a href="mailto:contact@vgomdigital.fr" className="flex items-center gap-3 text-gray-400 hover:text-white transition-colors">
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-600/20">
                      <Mail size={16} className="text-blue-500" />
                    </div>
                    <span>contact@vgmdigital.fr</span>
                  </a>
                </li>
                <li className="flex items-center gap-3 text-gray-400">
                  <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-600/20">
                    <MapPin size={16} className="text-blue-500" />
                  </div>
                  <span>Paris & Périphérie, France</span>
                </li>
              </ul>
            </motion.div>

            {/* Liens légaux */}
            <motion.div variants={itemVariants} className="flex flex-col items-start">
              <div className="w-full flex justify-center md:justify-start">
                <h3 className="text-lg md:text-xl font-bold mb-4 text-white">Informations</h3>
              </div>
              <ul className="space-y-2 text-left w-full px-4 md:px-0">
                <li>
                  <Link to="/nous-contacter" className="text-gray-400 hover:text-white transition-colors">
                    Nous contacter
                  </Link>
                </li>
                <li>
                  <Link to="/cgu" className="text-gray-400 hover:text-white transition-colors">
                    CGU
                  </Link>
                </li>
                <li>
                  <Link to="/cgv" className="text-gray-400 hover:text-white transition-colors">
                    CGV
                  </Link>
                </li>
                <li>
                  <Link to="/mentions-legales" className="text-gray-400 hover:text-white transition-colors">
                    Mentions légales
                  </Link>
                </li>
                <li>
                  <Link to="/politique-confidentialite" className="text-gray-400 hover:text-white transition-colors">
                    Politique de confidentialité
                  </Link>
                </li>
                <li>
                  <Link to="/paiement-securise" className="text-gray-400 hover:text-white transition-colors">
                    Paiement sécurisé
                  </Link>
                </li>
              </ul>
            </motion.div>
          </motion.div>
        </div>

        {/* Réseaux sociaux et copyright */}
        <div className="border-t border-gray-800 mt-6 md:mt-12">
          <div className="container mx-auto px-4 py-6">
            <div className="text-center mb-8">
              <h4 className="text-gray-400 text-sm uppercase tracking-wide mb-4">Suivez-nous</h4>
              <div className="flex flex-wrap justify-center gap-3 md:gap-4">
                {socialLinks.map(({ href, icon: Icon, label, color, bgColor }) => (
                  <a
                    key={label}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={label}
                    className={`flex items-center justify-center w-10 h-10 rounded-full bg-gray-800/50 transition-all duration-300 
                    hover:scale-110 ${color} ${bgColor}`}
                  >
                    <Icon size={18} />
                  </a>
                ))}
              </div>
            </div>
            
            <div className="text-center">
              <p className="text-xs md:text-sm text-gray-500">
                © {2023} - {currentYear} VGM Digital. Tous droits réservés.
              </p>
              {/* Nouveau bouton pour paramètres des cookies */}
              <button 
                onClick={handleShowCookieSettings}
                className="flex items-center justify-center gap-2 mx-auto mt-4 px-4 py-2 text-xs text-gray-400 hover:text-white border border-gray-800 hover:border-gray-700 rounded-md transition-colors"
              >
                <Cookie size={14} />
                <span>Paramètres des cookies</span>
              </button>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;