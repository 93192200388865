import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getAllProjects } from '../api';
import { ArrowRight, Code, Layout } from 'lucide-react';
import { Link } from 'react-router-dom';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const data = await getAllProjects();
        
        // Formatage des données pour garantir que l'image est bien récupérée
        const formattedProjects = data.data.map((project) => ({
          id: project.id,
          title: project.titre,
          image: project.image?.formats?.medium?.url
            ? `${project.image.formats.medium.url}`
            : `${project.image?.url}`,
          description: project.description,
          category: project.categorie || 'Web',
          technologies: project.technologies || [],
          vendable: project.vendable || false,
        }));

        setProjects(formattedProjects);
        setLoading(false);
      } catch (err) {
        setError('Erreur lors de la récupération des projets.');
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  if (error) {
    return (
      <div className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 text-center">
          <div className="bg-red-50 border border-red-200 rounded-lg p-6 max-w-lg mx-auto">
            <p className="text-red-600 font-medium">{error}</p>
            <p className="mt-2 text-gray-600">Veuillez réessayer ultérieurement ou contactez l'administrateur.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="bg-gradient-to-b from-white to-gray-100 py-8 md:py-16">
      <div className="container mx-auto px-4">
        {/* En-tête de section avec animation */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7 }}
          className="text-center mb-12 md:mb-16"
        >
          <div className="inline-block bg-blue-50 text-blue-600 px-4 py-1.5 rounded-full text-xs font-medium tracking-wide mb-3">
            PORTFOLIO
          </div>
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-gray-900">
            Nos réalisations <span className="text-blue-600">récentes</span>
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-base md:text-lg">
            Découvrez les projets web que nous avons développés. Chaque projet reflète notre engagement envers l'excellence technique et le design centré sur l'utilisateur.
          </p>
        </motion.div>

        {/* Loader */}
        {loading ? (
          <div className="flex justify-center py-20">
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-600 mb-3"></div>
              <p className="text-gray-600">Chargement des projets...</p>
            </div>
          </div>
        ) : (
          <>
            {/* Grille des projets avec animation */}
            <motion.div 
              variants={containerVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
            >
              {projects.slice(0, 3).map((project, index) => (
                <motion.div
                  key={project.id}
                  variants={itemVariants}
                  className="group relative"
                >
                  <Link
                    to={`/portfolio`}
                    className="block h-full"
                  >
                    <div className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 h-full flex flex-col">
                      {/* Bande colorée en haut */}
                      <div className={`h-2
                        ${index % 3 === 0 ? 'bg-blue-500' : ''}
                        ${index % 3 === 1 ? 'bg-purple-500' : ''}
                        ${index % 3 === 2 ? 'bg-indigo-500' : ''}
                      `}></div>
                      
                      {/* Image avec overlay au survol */}
                      <div className="relative overflow-hidden">
                        {project.image ? (
                          <>
                            <img
                              src={project.image}
                              alt={project.title}
                              className="w-full h-64 object-cover transform group-hover:scale-105 transition-transform duration-500" 
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end">
                              <div className="p-6">
                                <span className="text-white/90 text-sm bg-white/20 px-3 py-1 rounded-full backdrop-blur-sm">
                                  Voir le projet
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="w-full h-64 bg-gray-100 flex items-center justify-center">
                            <Layout size={32} className="text-gray-300" />
                          </div>
                        )}
                        
                        {/* Badge catégorie */}
                        <div className="absolute top-4 right-4">
                          <span className={`text-xs font-medium px-2 py-1 rounded-full ${
                            index % 3 === 0 ? 'bg-blue-100 text-blue-700' : 
                            index % 3 === 1 ? 'bg-purple-100 text-purple-700' : 
                            'bg-indigo-100 text-indigo-700'
                          }`}>
                            {project.category}
                          </span>
                        </div>
                      </div>
                      
                      {/* Contenu texte */}
                      <div className="p-6 flex-grow flex flex-col">
                        <h3 className="text-xl font-bold text-gray-900 mb-2 group-hover:text-blue-600 transition-colors">
                          {project.title || 'Projet sans titre'}
                        </h3>
                        
                        <p className="text-gray-600 text-sm mb-4 line-clamp-3 flex-grow">
                          {project.description || 'Aucune description disponible pour ce projet.'}
                        </p>
                        
                        {/* Technologies utilisées */}
                        {project.technologies && project.technologies.length > 0 && (
                          <div className="flex flex-wrap gap-2 mt-auto">
                            {project.technologies.slice(0, 3).map((tech, idx) => (
                              <span 
                                key={idx} 
                                className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded-md flex items-center"
                              >
                                <Code size={12} className="mr-1" />
                                {tech}
                              </span>
                            ))}
                            {project.technologies.length > 3 && (
                              <span className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded-md">
                                +{project.technologies.length - 3}
                              </span>
                            )}
                          </div>
                        )}
                      </div>

                      {/* Call to action */}
                      <div className="px-6 pb-6">
                        <div className={`flex items-center text-sm font-medium ${
                          index % 3 === 0 ? 'text-blue-600' : 
                          index % 3 === 1 ? 'text-purple-600' : 
                          'text-indigo-600'
                        }`}>
                          <span>Voir les détails</span>
                          <ArrowRight size={16} className="ml-1 group-hover:translate-x-1 transition-transform" />
                        </div>
                      </div>

                      {/* Badge vendable si applicable */}
                      {project.vendable && (
                        <div className="absolute top-4 left-4">
                          <span className="bg-green-500 text-white text-xs font-bold px-2.5 py-1 rounded">
                            À vendre
                          </span>
                        </div>
                      )}
                    </div>
                  </Link>
                </motion.div>
              ))}
            </motion.div>

            {/* Bouton "Voir tous les projets" avec animation */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.5, duration: 0.7 }}
              className="text-center"
            >
              <Link
                to="/portfolio"
                className="inline-flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-8 py-3.5 rounded-lg transition-colors font-medium group"
              >
                <span>Explorer tout notre portfolio</span>
                <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" size={18} />
              </Link>
              
              {/* Statistiques */}
              <div className="mt-16 grid grid-cols-2 md:grid-cols-4 gap-6">
                <div className="bg-white rounded-lg shadow-sm p-6 text-center">
                  <div className="text-3xl font-bold text-blue-600 mb-1">{projects.length}+</div>
                  <div className="text-gray-600 text-sm">Projets réalisés</div>
                </div>
                
                <div className="bg-white rounded-lg shadow-sm p-6 text-center">
                  <div className="text-3xl font-bold text-purple-600 mb-1">100%</div>
                  <div className="text-gray-600 text-sm">Satisfaction client</div>
                </div>
                
                <div className="bg-white rounded-lg shadow-sm p-6 text-center">
                  <div className="text-3xl font-bold text-indigo-600 mb-1">12+</div>
                  <div className="text-gray-600 text-sm">Technologies maîtrisées</div>
                </div>
                
                <div className="bg-white rounded-lg shadow-sm p-6 text-center">
                  <div className="text-3xl font-bold text-blue-600 mb-1">5+</div>
                  <div className="text-gray-600 text-sm">Années d'expérience</div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </div>
    </section>
  );
};

export default Projects;