import React from 'react';
import { motion } from 'framer-motion';
import {
  ShieldCheck,
  CreditCard,
  Lock,
  HelpCircle,
  Bell,
  CheckCircle,
} from 'lucide-react';

const PaiementSecurise = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="bg-gradient-to-br from-blue-600 to-indigo-700 py-24 md:py-32 pb-10">
      <div className="container mx-auto px-4 py-6 sm:py-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-2xl shadow-xl p-6 sm:p-8 md:p-10"
        >
          {/* En-tête avec titre et description */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center mb-8"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="inline-block bg-blue-100 p-3 rounded-full mb-4"
            >
              <ShieldCheck size={32} className="text-blue-600" />
            </motion.div>

            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 text-gray-800">
              Paiement Sécurisé
            </h1>

            <p className="text-sm sm:text-base text-gray-600 max-w-lg sm:max-w-3xl mx-auto">
              Toutes les transactions effectuées sur notre site sont protégées
              grâce à <strong>Stripe</strong>, une plateforme de paiement
              reconnue pour sa sécurité et sa fiabilité. Vos informations
              bancaires sont encryptées et ne transitent jamais par nos
              serveurs.
            </p>

            <div className="w-24 h-1 mx-auto mt-6 rounded-full bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
          </motion.div>

          {/* Sections principales avec animations */}
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="space-y-6 sm:space-y-8"
          >
            {/* Processus de Paiement */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <CheckCircle size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  Processus d'Achat Simple
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  Notre processus de paiement est conçu pour être simple, rapide
                  et sécurisé.
                </p>
                <ul className="list-disc pl-5 mt-4 space-y-2 text-sm sm:text-base text-gray-700">
                  <li>Sélectionnez le projet que vous souhaitez acquérir</li>
                  <li>Cliquez sur le bouton "Acheter maintenant"</li>
                  <li>
                    Vous êtes redirigé vers la plateforme sécurisée Stripe
                  </li>
                  <li>
                    Une fois le paiement effectué, vous recevez une confirmation
                    par email
                  </li>
                </ul>
              </div>
            </motion.section>

            {/* Gestion des Achats */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <CreditCard size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  Suivi de vos Achats
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  Après votre achat, vous recevrez :
                </p>
                <ul className="list-disc pl-5 mt-4 space-y-2 text-sm sm:text-base text-gray-700">
                  <li>
                    Un email de confirmation avec les détails de votre achat
                  </li>
                  <li>Le reçu de votre transaction</li>
                  <li>Les instructions pour accéder à votre achat</li>
                  <li>Un support client dédié en cas de question</li>
                </ul>
              </div>
            </motion.section>

            {/* Sécurité des Transactions */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Lock size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  Sécurité des Transactions
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  Stripe est certifié PCI-DSS (Payment Card Industry Data
                  Security Standard), garantissant une protection optimale de
                  vos données personnelles et financières.
                </p>
                <ul className="list-disc pl-5 mt-4 space-y-2 text-sm sm:text-base text-gray-700">
                  <li>
                    Protection des données grâce à une technologie de
                    chiffrement avancée
                  </li>
                  <li>
                    Conformité aux normes de sécurité les plus strictes
                    (PCI-DSS)
                  </li>
                  <li>Paiement sécurisé par carte bancaire via Stripe</li>
                  <li>
                    Authentification à deux facteurs pour les paiements
                    sensibles
                  </li>
                </ul>
              </div>
            </motion.section>

            {/* Logo Stripe */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Bell size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  Notre Partenaire de Paiement
                </h2>
              </div>

              <div className="ml-12 flex flex-col items-center">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed mb-4 w-full">
                  Nous avons sélectionné Stripe pour la sécurité et la
                  simplicité de son interface de paiement.
                </p>
                <motion.img
                  initial={{ scale: 0.95, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  src="https://res.cloudinary.com/dwcdxkpfk/image/upload/v1736382739/stripe_twabz5.webp"
                  alt="Logo Stripe"
                  className="w-36 md:w-40"
                />
              </div>
            </motion.section>

            {/* Besoin d'aide */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <HelpCircle size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  Besoin d'aide ?
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  En cas de problème ou de question concernant votre paiement,
                  n'hésitez pas à nous contacter :
                </p>
                <div className="mt-4 flex flex-col sm:flex-row gap-2 items-start sm:items-center">
                  <a
                    href="mailto:contact@vgomcreation.fr"
                    className="inline-flex items-center px-3 py-1.5 sm:px-4 sm:py-2 bg-blue-100 text-blue-700 rounded-full hover:bg-blue-200 transition-colors text-xs sm:text-sm"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="truncate">contact@vgmdigital.fr</span>
                  </a>
                  <a
                    href="tel:0767603449"
                    className="inline-flex items-center px-3 py-1.5 sm:px-4 sm:py-2 bg-blue-100 text-blue-700 rounded-full hover:bg-blue-200 transition-colors text-xs sm:text-sm"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    07 67 60 34 49
                  </a>
                </div>
              </div>
            </motion.section>
          </motion.div>

          {/* Élément de décoration en bas de page */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="w-full flex justify-center mt-10"
          >
            <div className="w-24 h-1 rounded-full bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default PaiementSecurise;
