import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { getAllProjects, incrementLikeGalerie, decrementLikeGalerie } from '../api';

// Création du contexte
const GalerieContext = createContext();

// Hook personnalisé pour utiliser le contexte
export const useGalerie = () => useContext(GalerieContext);

// Composant Provider
export const GalerieProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // État pour les projets likés - utiliser localStorage pour persistance
  const [likedProjects, setLikedProjects] = useState(() => {
    // Récupérer les projets likés depuis le localStorage
    const saved = localStorage.getItem('likedProjects');
    return saved ? JSON.parse(saved) : [];
  });

  // Fonction pour réinitialiser les filtres
  const resetFilters = useCallback(() => {
    setSelectedCategory('all');
    setSearchTerm('');
  }, []);

  // Charger les projets depuis l'API
  const fetchProjects = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getAllProjects();

      const formattedProjects = data.data.map((project) => ({
        id: project.id,
        title: project.titre,
        description: project.description || 'Description non disponible',
        image: project.image?.formats?.medium?.url
          ? `${project.image.formats.medium.url}`
          : `${project.image?.url}`,
        url: project.url_projet,
        category: project.categorie,
        date: project.date_realisation || new Date().toISOString(),
        technologies: project.technologies || [],
        likes: project.likes || 0,
        isLiked: likedProjects.includes(project.id),
        isAnimatingLike: false,
      }));

      // Extraire les catégories uniques
      const uniqueCategories = [
        ...new Set(formattedProjects.map((project) => project.category)),
      ]
        .filter(Boolean)
        .sort();

      setProjects(formattedProjects);
      setFilteredProjects(formattedProjects);
      setCategories(uniqueCategories);
      setLoading(false);
    } catch (err) {
      console.error('Erreur lors du chargement des projets:', err);
      setError('Erreur lors de la récupération des projets.');
      setLoading(false);
    }
  }, [likedProjects]);

  // Charger les projets au montage
  useEffect(() => {
    if (!projects.length) {
      fetchProjects();
    }
  }, [projects.length, fetchProjects]);

  // Filtre combiné (catégorie + recherche)
  useEffect(() => {
    if (!projects.length) return;

    let result = [...projects];

    // Filtre par catégorie
    if (selectedCategory !== 'all') {
      result = result.filter(
        (project) => project.category === selectedCategory
      );
    }

    // Filtre par recherche
    if (searchTerm) {
      const term = searchTerm.toLowerCase().trim();
      result = result.filter(
        (project) =>
          project.title.toLowerCase().includes(term) ||
          project.description.toLowerCase().includes(term) ||
          (project.technologies &&
            project.technologies.some((tech) =>
              tech.toLowerCase().includes(term)
            ))
      );
    }

    setFilteredProjects(result);
  }, [selectedCategory, searchTerm, projects]);

  // Fonction pour gérer le like d'un projet
  const handleLike = useCallback(
    async (projectId) => {
      try {
        // Vérifier si le projet est déjà liké
        const isAlreadyLiked = likedProjects.includes(projectId);

        // Mettre à jour l'UI immédiatement pour une expérience réactive
        setProjects((prevProjects) =>
          prevProjects.map((project) => {
            if (project.id === projectId) {
              return {
                ...project,
                isLiked: !isAlreadyLiked,
                isAnimatingLike: !isAlreadyLiked,
                likes: isAlreadyLiked 
                  ? Math.max(0, project.likes - 1) 
                  : project.likes + 1
              };
            }
            return project;
          })
        );

        // Mettre à jour les projets filtrés également
        setFilteredProjects((prevFiltered) =>
          prevFiltered.map((project) => {
            if (project.id === projectId) {
              return {
                ...project,
                isLiked: !isAlreadyLiked,
                isAnimatingLike: !isAlreadyLiked,
                likes: isAlreadyLiked 
                  ? Math.max(0, project.likes - 1) 
                  : project.likes + 1
              };
            }
            return project;
          })
        );

        // Mettre à jour la liste des projets likés dans localStorage
        let newLikedProjects;
        if (isAlreadyLiked) {
          newLikedProjects = likedProjects.filter((id) => id !== projectId);
        } else {
          newLikedProjects = [...likedProjects, projectId];
        }
        setLikedProjects(newLikedProjects);
        localStorage.setItem('likedProjects', JSON.stringify(newLikedProjects));

        // Appel à l'API - Similaire à l'approche du blog
        let newLikeCount;
        if (isAlreadyLiked) {
          // Si déjà liké, on décrémente
          newLikeCount = await decrementLikeGalerie(projectId);
        } else {
          // Sinon on incrémente
          newLikeCount = await incrementLikeGalerie(projectId);
        }

        // Si l'API retourne un compte différent, on met à jour
        if (newLikeCount !== null) {
          setProjects((prevProjects) =>
            prevProjects.map((project) => {
              if (project.id === projectId) {
                return {
                  ...project,
                  likes: newLikeCount
                };
              }
              return project;
            })
          );

          setFilteredProjects((prevFiltered) =>
            prevFiltered.map((project) => {
              if (project.id === projectId) {
                return {
                  ...project,
                  likes: newLikeCount
                };
              }
              return project;
            })
          );
        }

        // Rétablir la taille du cœur après l'animation
        setTimeout(() => {
          setProjects((prevProjects) =>
            prevProjects.map((project) => {
              if (project.id === projectId) {
                return {
                  ...project,
                  isAnimatingLike: false,
                };
              }
              return project;
            })
          );

          setFilteredProjects((prevFiltered) =>
            prevFiltered.map((project) => {
              if (project.id === projectId) {
                return {
                  ...project,
                  isAnimatingLike: false,
                };
              }
              return project;
            })
          );
        }, 700);
      } catch (error) {
        console.error('Erreur lors du like:', error);
        // Même en cas d'erreur, on garde l'état local pour une meilleure UX
      }
    },
    [likedProjects]
  );

  // Fonction vide pour remplacer incrementViewCount (pour compatibilité)
  const incrementViewCount = useCallback(() => {
    // Ne fait rien
  }, []);

  // Fonction pour trier les projets
  const sortProjects = useCallback(
    (criteria) => {
      let sortedProjects = [...filteredProjects];

      switch (criteria) {
        case 'recent':
          sortedProjects.sort((a, b) => new Date(b.date) - new Date(a.date));
          break;
        default:
          // Par défaut, tri par date décroissante
          sortedProjects.sort((a, b) => new Date(b.date) - new Date(a.date));
      }

      setFilteredProjects(sortedProjects);
    },
    [filteredProjects]
  );

  // Valeur du contexte
  const value = {
    projects,
    filteredProjects,
    categories,
    selectedCategory,
    setSelectedCategory,
    searchTerm,
    setSearchTerm,
    loading,
    error,
    likedProjects,
    handleLike,
    incrementViewCount, // Fonction vide pour compatibilité
    resetFilters,
    sortProjects,
    refreshProjects: fetchProjects,
  };

  return (
    <GalerieContext.Provider value={value}>
      {children}
    </GalerieContext.Provider>
  );
};