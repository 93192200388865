import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import BackHomeButton from '../components/BackHomeButton';
import { ExternalLink, Loader, Search, Filter, ThumbsUp } from 'lucide-react';
import { useGalerie } from '../context/GalerieContext';

const Galerie = () => {
  const {
    filteredProjects,
    categories,
    selectedCategory,
    setSelectedCategory,
    searchTerm,
    setSearchTerm,
    loading,
    error,
    handleLike,
    incrementViewCount,
  } = useGalerie();

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const projectVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-white to-gray-50 mt-0 md:mt-16 md:py-6">
        <div className="bg-white p-8 rounded-xl shadow-xl text-center max-w-md">
          <div className="text-red-500 bg-red-50 p-4 rounded-lg flex flex-col items-center mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 mb-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <p className="text-lg font-semibold">{error}</p>
          </div>
          <p className="text-gray-600 mb-6">
            Nous sommes désolés pour ce désagrément. Veuillez réessayer plus
            tard ou contactez-nous si le problème persiste.
          </p>
          <BackHomeButton />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 pt-20 pb-8 md:pb-20">
      {/* Hero Section */}
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          className="text-center pt-8 md:pt-16"
        >
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 text-gray-900">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-700">
              Notre Galerie
            </span>
          </h1>
          <p className="text-gray-600 max-w-xl mx-auto mb-2 md:mb-8 text-lg">
            Découvrez nos réalisations en développement web et design. Chaque
            projet reflète notre engagement pour l'excellence et l'innovation,
            tout en vous offrant des idées inspirantes pour vos projets.
          </p>
        </motion.div>
      </div>

      {/* Filtres et recherche */}
      <div className="container mx-auto px-4 mb-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-xl shadow-md p-6 mb-8 flex flex-col md:flex-row justify-between items-start md:items-center gap-4"
        >
          <div className="w-full md:w-auto">
            <BackHomeButton />
          </div>

          <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full md:w-auto">
            {/* Filtre par catégorie */}
            <div className="relative flex-grow">
              <div className="flex items-center absolute left-3 top-1/2 transform -translate-y-1/2">
                <Filter size={16} className="text-gray-400 mr-1" />
              </div>
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none bg-white"
              >
                <option value="all">Toutes les catégories</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            {/* Recherche */}
            <div className="relative flex-grow">
              <Search
                size={16}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              />
              <input
                type="text"
                placeholder="Rechercher un projet..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </motion.div>
      </div>

      {/* Grille de Projets */}
      <div className="container mx-auto px-4">
        {loading ? (
          <div className="flex justify-center items-center py-24">
            <div className="flex flex-col items-center">
              <Loader size={40} className="animate-spin text-blue-600 mb-4" />
              <p className="text-gray-600">Chargement des projets...</p>
            </div>
          </div>
        ) : filteredProjects.length === 0 ? (
          <div className="text-center py-16 bg-white rounded-lg shadow-sm">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="max-w-md mx-auto"
            >
              <div className="bg-gray-100 p-6 rounded-full w-24 h-24 mx-auto mb-4 flex items-center justify-center">
                <Search size={32} className="text-gray-400" />
              </div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">
                Aucun projet trouvé
              </h3>
              <p className="text-gray-600 mb-6">
                Aucun projet ne correspond à vos critères de recherche. Essayez
                de modifier vos filtres.
              </p>
              <button
                onClick={() => {
                  setSelectedCategory('all');
                  setSearchTerm('');
                }}
                className="text-blue-600 font-medium hover:text-blue-800"
              >
                Réinitialiser les filtres
              </button>
            </motion.div>
          </div>
        ) : (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="grid gap-8 md:grid-cols-2 lg:grid-cols-3"
          >
            <AnimatePresence>
              {filteredProjects.map((project) => (
                <motion.div
                  key={project.id}
                  variants={projectVariants}
                  layout
                  className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 flex flex-col group relative"
                  onAnimationComplete={() => incrementViewCount(project.id)}
                >
                  {/* Uniquement le bouton Like en position absolue */}
                  <div className="absolute top-3 right-3 z-20">
                    <motion.button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleLike(project.id);
                      }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      className={`flex items-center justify-center w-9 h-9 rounded-full ${
                        project.isLiked
                          ? 'bg-blue-600 text-white shadow-lg'
                          : 'bg-white/80 text-gray-500 backdrop-blur-sm hover:bg-white'
                      } transition-all duration-300 shadow`}
                      aria-label={
                        project.isLiked
                          ? 'Ne plus approuver'
                          : 'Approuver ce projet'
                      }
                    >
                      <motion.div
                        initial={{ scale: 1 }}
                        animate={{ scale: project.isAnimatingLike ? 1.3 : 1 }}
                        transition={{
                          type: 'spring',
                          stiffness: 400,
                          damping: 10,
                        }}
                      >
                        <ThumbsUp
                          size={16}
                          fill={project.isLiked ? 'currentColor' : 'none'}
                          stroke="currentColor"
                          strokeWidth={project.isLiked ? 0 : 2}
                        />
                        {project.isLiked && project.isAnimatingLike && (
                          <motion.span
                            key={`${project.id}-like-animation`}
                            initial={{ opacity: 1, scale: 0, y: 0 }}
                            animate={{ opacity: 0, scale: 1.5, y: -10 }}
                            transition={{ duration: 0.6 }}
                            className="absolute -top-1 -right-1 text-blue-400"
                          >
                            <ThumbsUp size={8} fill="currentColor" />
                          </motion.span>
                        )}
                      </motion.div>
                    </motion.button>
                  </div>

                  <div className="relative h-56 overflow-hidden">
                    {project.image ? (
                      <img
                        src={project.image}
                        alt={project.title}
                        className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-500">Aucune image</span>
                      </div>
                    )}

                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-end p-5">
                      <span className="text-white text-sm font-medium bg-blue-600/90 px-2 py-1 rounded self-start mb-2">
                        {project.category}
                      </span>
                      <h2 className="text-white text-xl font-bold">
                        {project.title}
                      </h2>
                    </div>
                  </div>

                  <div className="p-5 flex-1 flex flex-col">
                    <h3 className="text-lg font-bold text-gray-900 mb-2 group-hover:text-blue-600 transition-colors">
                      {project.title}
                    </h3>

                    <p className="text-gray-600 text-sm mb-4 line-clamp-2 flex-grow">
                      {project.description}
                    </p>

                    {project.technologies &&
                      project.technologies.length > 0 && (
                        <div className="flex flex-wrap gap-2 mb-4">
                          {project.technologies
                            .slice(0, 3)
                            .map((tech, index) => (
                              <span
                                key={index}
                                className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded"
                              >
                                {tech}
                              </span>
                            ))}
                          {project.technologies.length > 3 && (
                            <span className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded">
                              +{project.technologies.length - 3}
                            </span>
                          )}
                        </div>
                      )}

                    {/* Footer avec le lien seulement */}
                    <div className="flex justify-end items-center mt-auto pt-4 border-t border-gray-100">
                      <div className="z-10">
                        <a
                          href={project.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className="flex items-center text-sm font-medium text-blue-600 hover:text-blue-800 transition-colors px-2 py-1.5 rounded-full hover:bg-blue-50"
                        >
                          <span>Voir le projet</span>
                          <ExternalLink size={16} className="ml-1" />
                        </a>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        )}
      </div>

      {/* Appel à l'action */}
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.7 }}
        className="container mx-auto px-4 mt-14 md:mt-20"
      >
        {/* Votre contenu CTA existant */}
      </motion.div>
    </div>
  );
};

export default Galerie;
