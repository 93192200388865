import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { login } from '../api';
import { UserContext } from '../context/UserContext';
import GoogleLogin from '../components/GoogleLogin';
import FacebookLogin from '../components/FacebookLogin';
import {
  Eye,
  EyeOff,
  RefreshCw,
  CheckCircle,
  LogIn,
  Lock,
  AlertCircle,
  ArrowRight,
  Clock,
} from 'lucide-react';

function Connexion() {
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { loginUser, user, isAuthChecking } = useContext(UserContext);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [logoutMessage, setLogoutMessage] = useState('');

  // Vérifier si l'utilisateur a été déconnecté pour inactivité
  useEffect(() => {
    const inactivityLogout = sessionStorage.getItem('inactivityLogout');
    if (inactivityLogout === 'true') {
      setLogoutMessage(
        "Vous avez été déconnecté en raison d'une période d'inactivité."
      );
      sessionStorage.removeItem('inactivityLogout');
    }

    // Vérifier également les autres raisons de déconnexion
    const logoutReason = sessionStorage.getItem('logoutReason');
    if (logoutReason) {
      setLogoutMessage(logoutReason);
      sessionStorage.removeItem('logoutReason');
    }
  }, []);

  useEffect(() => {
    if (!isAuthChecking && user) {
      setIsRedirecting(true);
      navigate('/', { replace: true });
    }
  }, [user, isAuthChecking, navigate]);

  if (isAuthChecking || isRedirecting) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-gradient-to-br from-blue-600 to-indigo-700 flex items-center justify-center"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center gap-4"
        >
          <RefreshCw className="w-12 h-12 text-white animate-spin" />
          <p className="text-white text-lg font-medium">Chargement...</p>
        </motion.div>
      </motion.div>
    );
  }

  if (user) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
  
    try {
      const data = await login(formData);
      setSuccess(true);
      setIsLoading(false);
  
      setTimeout(() => {
        loginUser(data.user, data.jwt, rememberMe);
        setIsRedirecting(true);
        
        // Vérifier s'il y a une redirection après connexion
        const redirectPath = sessionStorage.getItem('redirectAfterLogin');
        if (redirectPath) {
          sessionStorage.removeItem('redirectAfterLogin'); // Nettoyer après utilisation
          navigate(redirectPath); // Rediriger vers la page précédente
        } else {
          navigate('/'); // Redirection par défaut
        }
      }, 1000);
    } catch (err) {
      setError(err.response?.data?.error?.message || 'Identifiants incorrects');
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 to-indigo-700 flex items-center justify-center p-4 py-16 mt-12 md:py-20 md:mt-20">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative w-full max-w-md bg-white rounded-2xl shadow-xl overflow-hidden"
      >
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
        <div className="absolute top-0 right-0 w-48 h-48 rounded-full bg-gradient-to-r from-blue-100 to-indigo-100 opacity-10 -mr-16 -mt-16"></div>

        <div className="relative px-6 pt-10 pb-8 sm:px-10">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.2 }}
            className="flex flex-col items-center mb-8"
          >
            <div className="bg-blue-600 text-white p-3 rounded-full shadow-lg">
              {success ? <CheckCircle size={28} /> : <LogIn size={28} />}
            </div>
            <h2 className="mt-4 text-2xl sm:text-3xl font-bold text-gray-800 text-center">
              {success ? 'Connexion réussie' : 'Bienvenue'}
            </h2>
            <p className="mt-2 text-sm sm:text-base text-gray-500 text-center max-w-sm">
              {success
                ? "Vous allez être redirigé vers la page d'accueil..."
                : 'Connectez-vous à votre compte pour accéder à votre espace personnel'}
            </p>
          </motion.div>

          {/* Message de déconnexion pour inactivité */}
          {logoutMessage && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-center p-4 mb-6 bg-amber-50 border-l-4 border-amber-500 rounded"
            >
              <Clock size={20} className="text-amber-500 mr-2 flex-shrink-0" />
              <p className="text-amber-700 text-sm">{logoutMessage}</p>
            </motion.div>
          )}

          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-center justify-center p-4 mb-6 bg-red-50 border-l-4 border-red-500 rounded"
            >
              <AlertCircle size={20} className="text-red-500 mr-2" />
              <p className="text-red-600 text-sm">{error}</p>
            </motion.div>
          )}

          {success && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-center justify-center p-4 mb-6 bg-green-50 border-l-4 border-green-500 rounded"
            >
              <CheckCircle size={20} className="text-green-500 mr-2" />
              <span className="text-green-600 text-sm">
                Authentification réussie!
              </span>
            </motion.div>
          )}

          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            onSubmit={handleLogin}
            className="space-y-5"
          >
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="space-y-2"
            >
              <label
                htmlFor="identifier"
                className="block text-sm font-medium text-gray-700"
              >
                Identifiant
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-400">
                    <Lock size={16} />
                  </span>
                </div>
                <input
                  type="text"
                  id="identifier"
                  name="identifier"
                  value={formData.identifier}
                  onChange={handleChange}
                  required
                  className="pl-10 block w-full border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 py-3 text-gray-900 placeholder-gray-400 bg-gray-50"
                  placeholder="Email ou nom d'utilisateur"
                />
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="space-y-2"
            >
              <div className="flex justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mot de passe
                </label>
              </div>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-400">
                    <Lock size={16} />
                  </span>
                </div>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  className="pl-10 block w-full border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 py-3 text-gray-900 placeholder-gray-400 bg-gray-50"
                  placeholder="Votre mot de passe"
                />
                <button
                  type="button"
                  onClick={toggleShowPassword}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
                >
                  {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                </button>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.55 }}
              className="flex items-center justify-between pt-2"
            >
              <div className="flex items-center">
                <div className="relative flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                    className="focus:ring-2 focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded transition-colors"
                  />
                  <motion.div
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{
                      scale: rememberMe ? 1 : 0.9,
                      opacity: rememberMe ? 1 : 0,
                    }}
                    className="absolute -right-0.5 -top-0.5 bg-blue-100 rounded-full p-0.5"
                  >
                    <CheckCircle size={8} className="text-blue-600" />
                  </motion.div>
                </div>
                <label
                  htmlFor="remember-me"
                  className="ml-2 text-sm text-gray-600 cursor-pointer select-none"
                >
                  Se souvenir de moi
                </label>
              </div>

              <button
                type="button"
                onClick={() => navigate('/forgot-password')}
                className="text-sm text-blue-600 hover:text-blue-800 hover:underline"
              >
                Mot de passe oublié?
              </button>
            </motion.div>

            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              type="submit"
              disabled={isLoading || success}
              className="relative w-full py-3 px-4 flex items-center justify-center bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-white font-medium rounded-lg transition-all duration-300 overflow-hidden group"
            >
              <span className="inline-flex items-center relative z-10">
                {isLoading ? (
                  <>
                    <RefreshCw size={18} className="animate-spin mr-2" />
                    <span>Connexion en cours...</span>
                  </>
                ) : (
                  <>
                    <LogIn size={18} className="mr-2" />
                    <span>Se connecter</span>
                  </>
                )}
              </span>
              <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            </motion.button>
          </motion.form>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.65 }}
            className="relative flex py-5 items-center my-4"
          >
            <div className="flex-grow border-t border-gray-200"></div>
            <span className="flex-shrink mx-4 text-gray-400 text-sm font-medium">
              Ou continuer avec
            </span>
            <div className="flex-grow border-t border-gray-200"></div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.7 }}
            className="flex gap-3 justify-center flex-wrap md:flex-nowrap"
          >
            {/* Bouton Google simplifié */}
            <div className="w-full md:w-1/2">
              <GoogleLogin isRegistration={false} />
            </div>

            {/* Bouton Facebook simplifié */}
            <div className="w-full md:w-1/2">
              <FacebookLogin isRegistration={false} />
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.7 }}
            className="mt-8 text-center"
          >
            <p className="text-sm text-gray-600">
              Pas encore de compte?{' '}
              <button
                onClick={() => navigate('/inscription')}
                className="inline-flex items-center font-medium text-blue-600 hover:text-blue-800 hover:underline transition-colors"
              >
                S'inscrire
                <ArrowRight
                  size={14}
                  className="ml-1 group-hover:translate-x-1 transition-transform"
                />
              </button>
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}

export default Connexion;
