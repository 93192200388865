import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { sendForgotPasswordEmail } from "../api";
import { Mail, CheckCircle, AlertCircle, ArrowRight, RefreshCw } from "lucide-react";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      await sendForgotPasswordEmail(email);
      setSuccess(true);
      setIsLoading(false);
    } catch (err) {
      setError(
        err.response?.data?.error?.message || "Une erreur s'est produite."
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 to-indigo-700 flex items-center justify-center p-4 py-32 md:py-40">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative w-full max-w-md bg-white rounded-2xl shadow-xl overflow-hidden"
      >
        {/* Effet de design en arrière-plan */}
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
        <div className="absolute top-0 right-0 w-48 h-48 rounded-full bg-gradient-to-r from-blue-100 to-indigo-100 opacity-10 -mr-16 -mt-16"></div>
        
        <div className="relative px-6 pt-10 pb-8 sm:px-10">
          {/* En-tête avec animation */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.2 }}
            className="flex flex-col items-center mb-8"
          >
            <div className="bg-blue-600 text-white p-3 rounded-full shadow-lg">
              {success ? <CheckCircle size={28} /> : <Mail size={28} />}
            </div>
            <h2 className="mt-4 text-2xl sm:text-3xl font-bold text-gray-800 text-center">
              {success ? 'Email envoyé' : 'Mot de passe oublié ?'}
            </h2>
            <p className="mt-2 text-sm sm:text-base text-gray-500 text-center max-w-sm">
              {success 
                ? "Consultez votre boîte de réception pour réinitialiser votre mot de passe"
                : 'Saisissez votre adresse email pour recevoir un lien de réinitialisation'}
            </p>
          </motion.div>

          {/* Message d'erreur avec animation */}
          {error && (
            <motion.div 
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-center p-4 mb-6 bg-red-50 border-l-4 border-red-500 rounded"
            >
              <AlertCircle size={20} className="text-red-500 mr-2 flex-shrink-0" />
              <p className="text-red-600 text-sm">{error}</p>
            </motion.div>
          )}

          {/* Contenu principal */}
          {success ? (
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="bg-green-50 rounded-xl p-6 flex flex-col items-center"
            >
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
                <CheckCircle className="text-green-500 w-8 h-8" />
              </div>
              
              <h3 className="text-lg font-semibold text-green-800 mb-2">
                Email envoyé avec succès !
              </h3>
              
              <p className="text-green-700 text-center mb-6">
                Un email de réinitialisation a été envoyé à :<br/>
                <span className="font-medium">{email}</span>
              </p>
              
              <p className="text-sm text-gray-600 mb-6 text-center">
                Si vous ne recevez pas l'email dans les prochaines minutes, vérifiez votre dossier spam ou essayez à nouveau avec une adresse différente.
              </p>
              
              <motion.button
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => navigate("/login")}
                className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <ArrowRight size={18} className="mr-2" />
                Retour à la connexion
              </motion.button>
            </motion.div>
          ) : (
            <motion.form 
              onSubmit={handleSubmit} 
              className="space-y-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="space-y-2"
              >
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adresse email
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail size={16} className="text-gray-400" />
                  </div>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="pl-10 block w-full border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 py-2.5 text-gray-900 placeholder-gray-400 bg-gray-50"
                    placeholder="votre@email.com"
                  />
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  Nous enverrons un lien sécurisé à cette adresse pour réinitialiser votre mot de passe
                </p>
              </motion.div>

              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                type="submit"
                disabled={isLoading || !email.trim()}
                className={`relative w-full py-3 px-4 flex items-center justify-center ${
                  !email.trim() ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'
                } focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-white font-medium rounded-lg transition-all duration-300 overflow-hidden group`}
              >
                <span className="inline-flex items-center relative z-10">
                  {isLoading ? (
                    <>
                      <RefreshCw size={18} className="animate-spin mr-2" />
                      <span>Envoi en cours...</span>
                    </>
                  ) : (
                    <>
                      <Mail size={18} className="mr-2" />
                      <span>Envoyer le lien</span>
                    </>
                  )}
                </span>
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </motion.button>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.6 }}
                className="text-center mt-6"
              >
                <button
                  type="button"
                  onClick={() => navigate("/login")}
                  className="inline-flex items-center font-medium text-blue-600 hover:text-blue-800 hover:underline transition-colors"
                >
                  Retour à la connexion
                  <ArrowRight size={14} className="ml-1 group-hover:translate-x-1 transition-transform" />
                </button>
              </motion.div>
            </motion.form>
          )}
        </div>
      </motion.div>
    </div>
  );
}

export default ForgotPassword;