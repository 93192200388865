import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { getAllTestimonials, submitTestimonial } from '../api';
import { Star, MessageSquare, Quote, ChevronRight, ChevronLeft, CheckCircle, User } from 'lucide-react';

const Temoignage = () => {
  const [temoignages, setTemoignages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [averageRating, setAverageRating] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [formData, setFormData] = useState({
    nom_du_client: '',
    contenu_du_temoignage: '',
  });

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.3 }
    }
  };

  // Récupération des témoignages
  useEffect(() => {
    const fetchTemoignages = async () => {
      try {
        const data = await getAllTestimonials();
        setTemoignages(data.data);

        // Calcul de la note moyenne
        const totalRatings = data.data.reduce(
          (sum, temoignage) => sum + temoignage.note,
          0
        );
        const average =
          data.data.length > 0 ? totalRatings / data.data.length : 0;
        setAverageRating(average.toFixed(1));
      } catch (error) {
        setError('Erreur lors de la récupération des témoignages.');
      }
    };

    fetchTemoignages();
  }, []);

  // Gestion du changement de slide
  const handleSlideChange = useCallback(
    (direction) => {
      if (isTransitioning || !temoignages.length) return;
      setIsTransitioning(true);

      setCurrentSlide((prev) => {
        const nextSlide =
          direction === 'next'
            ? (prev + 1) % temoignages.length
            : (prev - 1 + temoignages.length) % temoignages.length;
        return nextSlide;
      });

      setTimeout(() => setIsTransitioning(false), 300);
    },
    [temoignages.length, isTransitioning]
  );

  // Défilement automatique
  useEffect(() => {
    if (!temoignages.length) return;
    const timer = setInterval(() => {
      handleSlideChange('next');
    }, 6000);
    return () => clearInterval(timer);
  }, [handleSlideChange, temoignages.length]);

  // Gestion des changements du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Soumission d'un nouveau témoignage
  const handleSubmitTemoignage = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    if (rating === 0) {
      setError("Veuillez sélectionner une note avant de soumettre votre témoignage.");
      setIsSubmitting(false);
      return;
    }

    try {
      const payload = {
        nom_du_client: formData.nom_du_client,
        contenu_du_temoignage: formData.contenu_du_temoignage,
        note: rating,
      };

      await submitTestimonial(payload);
      
      // Mise à jour des témoignages après soumission
      const updatedTestimonials = await getAllTestimonials();
      setTemoignages(updatedTestimonials.data);

      // Recalculer la note moyenne
      const totalRatings = updatedTestimonials.data.reduce(
        (sum, temoignage) => sum + temoignage.note,
        0
      );
      const average =
        updatedTestimonials.data.length > 0
          ? totalRatings / updatedTestimonials.data.length
          : 0;
      setAverageRating(average.toFixed(1));
      
      // Réinitialisation du formulaire
      setFormData({
        nom_du_client: '',
        contenu_du_temoignage: '',
      });
      setRating(0);
      setSuccess("Merci pour votre témoignage ! Il a été ajouté avec succès.");
      
      // Masquer le message de succès après quelques secondes
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      setError(error.message || 'Une erreur est survenue lors de la soumission du témoignage.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Affichage des étoiles
  const renderStars = (note, isInteractive = false) => {
    return (
      <div className={`inline-flex items-center ${isInteractive ? 'bg-gray-50 hover:bg-gray-100' : 'bg-blue-600/10'} rounded-full py-1.5 px-2 transition-colors`}>
        {Array(5)
          .fill(0)
          .map((_, index) => {
            const starIndex = index + 1;
            return (
              <button
                key={index}
                type={isInteractive ? "button" : "div"}
                onMouseEnter={
                  isInteractive ? () => setHoverRating(starIndex) : undefined
                }
                onMouseLeave={
                  isInteractive ? () => setHoverRating(0) : undefined
                }
                onClick={isInteractive ? () => setRating(starIndex) : undefined}
                className={`transition-colors ${isInteractive ? 'cursor-pointer' : ''} px-0.5`}
                disabled={!isInteractive}
              >
                <Star
                  fill={starIndex <= (hoverRating || note) ? 'currentColor' : 'none'}
                  className={`w-5 h-5 ${
                    starIndex <= (hoverRating || note)
                      ? 'text-yellow-400'
                      : 'text-gray-300'
                  }`}
                />
              </button>
            );
          })}
      </div>
    );
  };

  // Rendu du carousel
  const renderCarousel = () => {
    if (!temoignages.length) {
      return (
        <div className="bg-white p-8 rounded-lg shadow-md text-center">
          <MessageSquare size={40} className="mx-auto mb-4 text-gray-400" />
          <p className="text-gray-600">Aucun témoignage disponible pour le moment.</p>
          <p className="text-gray-500 text-sm mt-2">Soyez le premier à partager votre expérience !</p>
        </div>
      );
    }

    return (
      <div className="relative overflow-hidden mx-auto max-w-4xl">
        {/* Boutons de navigation */}
        {temoignages.length > 1 && (
          <>
            <button
              onClick={() => handleSlideChange('prev')}
              className="absolute left-0 top-1/2 -translate-y-1/2 z-20 bg-white/80 hover:bg-white text-gray-800 rounded-full p-2 shadow-md transition-all hover:scale-110 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Précédent"
            >
              <ChevronLeft size={24} />
            </button>
            
            <button
              onClick={() => handleSlideChange('next')}
              className="absolute right-0 top-1/2 -translate-y-1/2 z-20 bg-white/80 hover:bg-white text-gray-800 rounded-full p-2 shadow-md transition-all hover:scale-110 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Suivant"
            >
              <ChevronRight size={24} />
            </button>
          </>
        )}

        {/* Les témoignages */}
        <div className="overflow-hidden mx-auto">
          <AnimatePresence initial={false} mode="wait">
            <motion.div
              key={currentSlide}
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="w-full px-4"
            >
              <div className="bg-white p-8 rounded-xl shadow-lg mx-auto relative">
                <div className="absolute -top-0 left-8 bg-blue-600 text-white p-2 rounded-full">
                  <Quote size={24} />
                </div>
                
                <div className="mb-6 flex justify-center pt-4">
                  {renderStars(temoignages[currentSlide].note)}
                </div>
                
                <p className="text-gray-700 text-lg leading-relaxed mb-8 text-center italic">
                  "{temoignages[currentSlide].contenu_du_temoignage}"
                </p>
                
                <div className="flex items-center justify-center">
                  <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mr-4">
                    <User size={20} className="text-blue-600" />
                  </div>
                  <div>
                    <p className="font-bold text-gray-900">
                      {temoignages[currentSlide].nom_du_client}
                    </p>
                    <p className="text-sm text-gray-500">
                      Client
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        {/* Indicateurs de pagination */}
        {temoignages.length > 1 && (
          <div className="flex justify-center gap-2 mt-6">
            {temoignages.map((_, idx) => (
              <button
                key={idx}
                onClick={() => setCurrentSlide(idx)}
                className={`w-2.5 h-2.5 rounded-full transition-all focus:outline-none ${
                  idx === currentSlide
                    ? 'bg-blue-600 w-8'
                    : 'bg-gray-300 hover:bg-gray-400'
                }`}
                aria-label={`Aller au témoignage ${idx + 1}`}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <section className="bg-gradient-to-b from-white to-gray-50 py-4 md:py-10">
      <div className="container mx-auto px-4">
        {/* En-tête de section */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7 }}
          className="text-center mb-12 md:mb-16"
        >
          <div className="inline-block bg-blue-50 text-blue-600 px-4 py-1.5 rounded-full text-xs font-medium tracking-wide mb-3">
            AVIS CLIENTS
          </div>
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-gray-900">
            Ce que disent nos <span className="text-blue-600">clients</span>
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-base md:text-lg">
            Découvrez les expériences vécues par nos clients et pourquoi ils nous font confiance pour leurs projets digitaux.
          </p>
        </motion.div>

        {/* Note moyenne */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-center mb-10"
        >
          <div className="inline-block bg-white rounded-lg shadow-md px-6 py-4">
            <h3 className="text-xl font-semibold mb-2 text-gray-800">
              Note moyenne
            </h3>
            <div className="flex items-center justify-center gap-3">
              <span className="text-3xl font-bold text-blue-600">{averageRating}</span>
              <div className="h-8 w-px bg-gray-200"></div>
              <div>{renderStars(Math.round(averageRating))}</div>
              <div className="h-8 w-px bg-gray-200"></div>
              <span className="text-sm text-gray-600">
                {temoignages.length} {temoignages.length > 1 ? 'avis' : 'avis'}
              </span>
            </div>
          </div>
        </motion.div>

        {/* Affichage des erreurs */}
        {error && (
          <div className="max-w-2xl mx-auto mb-8">
            <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
              <p className="flex items-center">
                <span className="mr-2">⚠️</span>
                {error}
              </p>
            </div>
          </div>
        )}

        {/* Carrousel des témoignages */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className="mb-16"
        >
          {renderCarousel()}
        </motion.div>

        {/* Formulaire d'envoi de témoignage */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-8"
        >
          <div className="flex items-center mb-6">
            <div className="bg-blue-100 p-2 rounded-full mr-4">
              <MessageSquare size={24} className="text-blue-600" />
            </div>
            <h3 className="text-2xl font-bold text-gray-900">
              Partagez votre expérience
            </h3>
          </div>

          {success && (
            <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg mb-6 flex items-center">
              <CheckCircle size={20} className="mr-2" />
              <p>{success}</p>
            </div>
          )}

          <form onSubmit={handleSubmitTemoignage}>
            <div className="mb-4">
              <label
                htmlFor="nom_du_client"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Votre nom
              </label>
              <input
                type="text"
                id="nom_du_client"
                name="nom_du_client"
                value={formData.nom_du_client}
                onChange={handleInputChange}
                required
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                placeholder="Entrez votre nom"
              />
            </div>
            
            <div className="mb-4">
              <label
                htmlFor="contenu_du_temoignage"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Votre témoignage
              </label>
              <textarea
                id="contenu_du_temoignage"
                name="contenu_du_temoignage"
                value={formData.contenu_du_temoignage}
                onChange={handleInputChange}
                required
                rows="4"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                placeholder="Partagez votre expérience avec nos services..."
              ></textarea>
            </div>
            
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Votre note
              </label>
              <div className="flex items-center">
                {renderStars(rating, true)}
                <span className="ml-2 text-sm text-gray-600">
                  {rating > 0 ? `${rating}/5` : "Sélectionnez votre note"}
                </span>
              </div>
            </div>
            
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg font-medium hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            >
              {isSubmitting ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Envoi en cours...
                </>
              ) : (
                'Envoyer mon témoignage'
              )}
            </button>
          </form>
        </motion.div>
      </div>
    </section>
  );
};

export default Temoignage;