import React from 'react';
import { motion } from 'framer-motion';
import {
  Shield,
  FileText,
  Check,
  CreditCard,
  Clock,
  Settings,
  HelpCircle,
} from 'lucide-react';

const CGV = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="bg-gradient-to-br from-blue-600 to-indigo-700 py-24 md:py-32 pb-5 md:pb-10">
      <div className="container mx-auto px-4 py-6 sm:py-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-2xl shadow-xl p-6 sm:p-8 md:p-10"
        >
          {/* En-tête avec titre et description */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center mb-8"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="inline-block bg-blue-100 p-3 rounded-full mb-4"
            >
              <FileText size={32} className="text-blue-600" />
            </motion.div>

            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 text-gray-800">
              Conditions Générales de Vente (CGV)
            </h1>

            <p className="text-sm sm:text-base text-gray-600 max-w-lg sm:max-w-3xl mx-auto">
              Les présentes Conditions Générales de Vente régissent les
              transactions réalisées sur le site VGM Digital. En effectuant
              une commande, vous acceptez ces conditions dans leur intégralité.
            </p>

            <div className="w-24 h-1 mx-auto mt-6 rounded-full bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
          </motion.div>

          {/* Sections principales avec animations */}
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="space-y-6 sm:space-y-8"
          >
            {/* Section 1 */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Shield size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  1. Identification du Prestataire
                </h2>
              </div>

              <div className="ml-12 space-y-2">
                <p className="text-sm sm:text-base">
                  <strong>Nom de l'entreprise :</strong> VGM Digital
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Adresse :</strong> 7 Rue de la Louette, 91150 Étampes,
                  France
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Email :</strong>{' '}
                  <a
                    href="mailto:contact@vgomcreation.fr"
                    className="text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    contact@vgomcreation.fr
                  </a>
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Téléphone :</strong> 07 67 60 34 49
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Numéro SIRET :</strong> 93380665500017
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Hébergeur du site :</strong> Vercel
                </p>
              </div>
            </motion.section>

            {/* Section 2 */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <FileText size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  2. Objet des CGV
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base">
                  Les présentes Conditions Générales de Vente (CGV) s'appliquent
                  à la vente des produits et services proposés par VGom
                  Creation, notamment :
                </p>
                <ul className="list-disc ml-5 mt-3 text-sm sm:text-base space-y-1">
                  <li>
                    Des sites web prêts à l'emploi et leurs personnalisations.
                  </li>
                  <li>
                    Des prestations de création de sites web sur mesure,
                    d'hébergement et de maintenance.
                  </li>
                </ul>
              </div>
            </motion.section>

            {/* Section 3 */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Settings size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  3. Produits et Services
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base mb-3">
                  VGM Digital propose :
                </p>
                <ul className="space-y-3">
                  <li className="flex">
                    <div className="mr-2 mt-1">
                      <Check size={16} className="text-green-500" />
                    </div>
                    <div>
                      <strong className="text-sm sm:text-base">
                        Sites web prêts à l'emploi :
                      </strong>
                      <p className="text-sm sm:text-base text-gray-600">
                        Modèles préconçus de sites internet adaptés à
                        différentes activités (e-commerce, blogs, etc.).
                      </p>
                    </div>
                  </li>

                  <li className="flex">
                    <div className="mr-2 mt-1">
                      <Check size={16} className="text-green-500" />
                    </div>
                    <div>
                      <strong className="text-sm sm:text-base">
                        Personnalisation :
                      </strong>
                      <p className="text-sm sm:text-base text-gray-600">
                        Adaptation des couleurs, logos, et contenus selon les
                        besoins du client.
                      </p>
                    </div>
                  </li>

                  <li className="flex">
                    <div className="mr-2 mt-1">
                      <Check size={16} className="text-green-500" />
                    </div>
                    <div>
                      <strong className="text-sm sm:text-base">
                        Services sur mesure :
                      </strong>
                      <p className="text-sm sm:text-base text-gray-600">
                        Création de sites internet spécifiques, hébergement
                        sécurisé et optimisation SEO.
                      </p>
                    </div>
                  </li>

                  <li className="flex">
                    <div className="mr-2 mt-1">
                      <Check size={16} className="text-green-500" />
                    </div>
                    <div>
                      <strong className="text-sm sm:text-base">
                        Intégration IA et technologies 3D :
                      </strong>
                      <ul className="list-disc ml-5 mt-2 text-sm sm:text-base space-y-1 text-gray-600">
                        <li>Chatbots intelligents avec IA conversationnelle</li>
                        <li>
                          Assistants virtuels personnalisés pour votre site
                        </li>
                        <li>Visualisation 3D de produits et services</li>
                        <li>Expériences immersives avec réalité augmentée</li>
                        <li>Intégration de modèles 3D interactifs</li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </motion.section>

            {/* Section 4 à 14 - Suivre le même modèle */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Clock size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  4. Modalités de commande et livraison
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base">
                  Les fichiers digitaux sont livrés sous 48 heures après
                  validation de la commande. En cas de personnalisation, le
                  délai est spécifié dans le devis.
                </p>
              </div>
            </motion.section>

            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <CreditCard size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">5. Prix</h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base">
                  Les prix sont affichés en euros, toutes taxes comprises (TTC).
                  Ils incluent les frais de personnalisation, le cas échéant, et
                  sont définis clairement avant la validation de la commande.
                </p>
              </div>
            </motion.section>

            {/* Continuez avec les sections 6 à 14 selon le même schéma... */}

            {/* Section 14 */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <HelpCircle size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  14. Date de mise en vigueur
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base">
                  Les présentes CGV sont entrées en vigueur le 1 janvier 2025.
                </p>
              </div>
            </motion.section>
          </motion.div>

          {/* Élément de décoration en bas de page */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="w-full flex justify-center mt-10"
          >
            <div className="w-24 h-1 rounded-full bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default CGV;
