import { useContext } from 'react';
import { Eye, Heart, MessageSquare, ArrowRight, Calendar, Clock, Tag } from 'lucide-react';
import { Link } from 'react-router-dom';
import { BlogContext } from '../context/BlogContext';
import { motion } from 'framer-motion';

const BlogSection = () => {
  const { articles, loading, error } = useContext(BlogContext);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.15, delayChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 } 
    }
  };

  // Fonction pour extraire un extrait du contenu
  const getExcerpt = (content, maxLength = 100) => {
    if (!content) return '';
    
    // Suppression des balises HTML potentielles
    const stripHtml = content.replace(/<[^>]*>?/gm, '');
    
    if (stripHtml.length <= maxLength) return stripHtml;
    return `${stripHtml.substring(0, maxLength)}...`;
  };
  
  // Formater la date en format français
  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };
  
  // Estimer le temps de lecture (1 mot = 0.2 seconde en moyenne)
  const getReadingTime = (content) => {
    if (!content) return '1 min';
    const words = content.replace(/<[^>]*>?/gm, '').split(/\s+/).length;
    const minutes = Math.max(1, Math.round(words / 200));
    return `${minutes} min`;
  };

  if (loading) {
    return (
      <div className="bg-gradient-to-b from-gray-50 to-white py-16">
        <div className="container mx-auto px-4 text-center">
          <div className="flex justify-center items-center py-20">
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-600 mb-3"></div>
              <p className="text-gray-600">Chargement des articles...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-gradient-to-b from-gray-50 to-white py-16">
        <div className="container mx-auto px-4 text-center">
          <div className="bg-red-50 border border-red-200 rounded-lg p-6 max-w-lg mx-auto">
            <p className="text-red-600 font-medium">{error}</p>
            <p className="mt-2 text-gray-600">Impossible de charger les articles. Veuillez réessayer ultérieurement.</p>
          </div>
        </div>
      </div>
    );
  }

  if (!articles.length) {
    return (
      <div className="bg-gradient-to-b from-gray-50 to-white py-16">
        <div className="container mx-auto px-4 text-center">
          <div className="bg-blue-50 border border-blue-200 rounded-lg p-6 max-w-lg mx-auto">
            <p className="text-blue-600 font-medium">Aucun article disponible pour le moment.</p>
            <p className="mt-2 text-gray-600">Revenez bientôt pour découvrir nos prochaines publications.</p>
          </div>
        </div>
      </div>
    );
  }

  // Limiter les articles aux trois plus récents
  const recentArticles = articles.slice(0, 3);

  return (
    <section className="bg-gradient-to-b from-gray-50 to-white py-10 md:py-20">
      <div className="container mx-auto px-4">
        {/* En-tête de section avec animation */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7 }}
          className="text-center mb-12 md:mb-16"
        >
          <div className="inline-block bg-blue-50 text-blue-600 px-4 py-1.5 rounded-full text-xs font-medium tracking-wide mb-3">
            ACTUALITÉS
          </div>
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-gray-900">
            Notre <span className="text-blue-600">Blog</span>
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-base md:text-lg">
            Découvrez nos derniers articles, conseils et actualités sur le développement web, le design et les tendances numériques.
          </p>
        </motion.div>

        {/* Grille des articles avec animation */}
        <motion.div 
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12"
        >
          {recentArticles.map((article, index) => {
            const imageUrl = article.image?.url ? `${article.image.url}` : null;
            const excerpt = getExcerpt(article.contenu);
            const readingTime = getReadingTime(article.contenu);

            return (
              <motion.div 
                key={article.id} 
                variants={itemVariants}
                className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 flex flex-col group"
              >
                {/* Image de l'article */}
                <div className="relative h-56 overflow-hidden">
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt={article.titre || 'Image de l\'article'}
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                      <span className="text-gray-500">Image non disponible</span>
                    </div>
                  )}
                  
                  {/* Overlay au survol */}
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end">
                    <div className="p-6">
                      <Link 
                        to={`/blog`} 
                        className="text-white/90 text-sm bg-white/20 px-3 py-1 rounded-full backdrop-blur-sm inline-flex items-center"
                      >
                        Voir le blog
                        <ArrowRight size={14} className="ml-1" />
                      </Link>
                    </div>
                  </div>
                </div>

                {/* Contenu de l'article */}
                <div className="p-6 flex-1 flex flex-col">
                  {/* Métadonnées */}
                  <div className="flex items-center gap-3 mb-3 text-xs text-gray-500">
                    <div className="flex items-center">
                      <Calendar size={14} className="mr-1" />
                      <span>{formatDate(article.createdAt)}</span>
                    </div>
                    <div className="flex items-center">
                      <Clock size={14} className="mr-1" />
                      <span>{readingTime}</span>
                    </div>
                    {article.categories && article.categories.length > 0 && (
                      <div className="flex items-center">
                        <Tag size={14} className="mr-1" />
                        <span>{article.categories[0]}</span>
                      </div>
                    )}
                  </div>

                  {/* Titre */}
                  <Link to={`/article/${article.id}`}>
                    <h3 className="text-xl font-bold text-gray-900 mb-3 group-hover:text-blue-600 transition-colors line-clamp-2">
                      {article.titre}
                    </h3>
                  </Link>

                  {/* Extrait */}
                  <p className="text-gray-600 text-sm mb-6 flex-grow line-clamp-3">
                    {excerpt}
                  </p>

                  {/* Statistiques */}
                  <div className="flex items-center justify-between mt-auto pt-4 border-t border-gray-100">
                    <div className="flex items-center space-x-4 text-xs text-gray-500">
                      <div className="flex items-center">
                        <Eye size={14} className="mr-1" />
                        <span>{article.vue_count || 0}</span>
                      </div>
                      <div className="flex items-center">
                        <Heart
                          size={14}
                          className={`mr-1 ${
                            article.hasLiked
                              ? 'text-red-500 fill-current'
                              : 'text-gray-400'
                          }`}
                        />
                        <span>{article.likes_count || 0}</span>
                      </div>
                      <div className="flex items-center">
                        <MessageSquare size={14} className="mr-1" />
                        <span>{article.commentaire_count || 0}</span>
                      </div>
                    </div>
                    
                    {/* Lien vers l'article */}
                    <Link 
                      to={`/article/${article.id}`}
                      className="text-blue-600 text-sm font-medium inline-flex items-center group-hover:text-blue-700"
                    >
                      <span>Lire la suite</span>
                      <ArrowRight size={14} className="ml-1 group-hover:translate-x-1 transition-transform" />
                    </Link>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </motion.div>

        {/* Bouton "Voir tous les articles" avec animation */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.3, duration: 0.6 }}
          className="text-center"
        >
          <Link
            to="/blog"
            className="inline-flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-8 py-3.5 rounded-lg transition-colors font-medium group"
          >
            <span>Explorer tous nos articles</span>
            <ArrowRight size={18} className="ml-2 group-hover:translate-x-1 transition-transform" />
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

export default BlogSection;