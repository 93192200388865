import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';
import { 
  FacebookIcon, 
  LinkedinIcon, 
  WhatsappIcon,
} from 'react-share';
import { 
  X, 
  Share2
} from 'lucide-react';

// Icône personnalisée pour Twitter/X
const XIcon = () => (
  <motion.div 
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
    className="w-12 h-12 bg-black rounded-full flex items-center justify-center overflow-hidden shadow-md"
  >
    <img
      src="https://img.icons8.com/forma-regular/72/twitterx.png"
      alt="Twitter X"
      className="w-[70%] h-[70%] object-cover filter invert"
    />
  </motion.div>
);

const BoutonsPartage = ({ 
  url = window.location.href,  // URL actuelle par défaut
  title = 'Découvrez VGom Creation - Création de sites web sur mesure !',
  description = 'Votre partenaire pour la création de sites web et d\'applications sur mesure.',
  hashtags = ['webdesign', 'VGomCreation', 'sitesweb'],
  position = 'right', // 'right', 'left', 'bottom'
  customClass = ''
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  // Contrôle de visibilité lors du défilement
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      // Cacher uniquement si on défile vers le bas et qu'on est au-delà de 200px
      if (currentScrollY > lastScrollY && currentScrollY > 200) {
        setIsVisible(false);
        if (isOpen) setIsOpen(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, isOpen]);

  // Variantes d'animation pour les boutons de partage
  const containerVariants = {
    hidden: { 
      opacity: 0,
      y: position === 'bottom' ? 20 : 0,
      x: position !== 'bottom' ? (position === 'left' ? -20 : 20) : 0
    },
    visible: { 
      opacity: 1, 
      y: 0,
      x: 0,
      transition: {
        staggerChildren: 0.1,
        when: 'beforeChildren'
      }
    },
    exit: { 
      opacity: 0,
      y: position === 'bottom' ? 20 : 0,
      x: position !== 'bottom' ? (position === 'left' ? -20 : 20) : 0,
      transition: { 
        duration: 0.2,
        staggerChildren: 0.05,
        staggerDirection: -1,
        when: 'afterChildren'
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8 }
  };

  // Détermination des classes CSS selon la position
  const positionClasses = {
    right: 'fixed right-4 top-1/2 -translate-y-1/2 flex flex-col items-center gap-2',
    left: 'fixed left-4 top-1/2 -translate-y-1/2 flex flex-col items-center gap-2',
    bottom: 'fixed bottom-4 right-4 flex flex-col-reverse items-end gap-2'
  };

  const shareIconsContainerClass = {
    right: 'absolute flex flex-col items-center gap-3 bottom-full mb-2',
    left: 'absolute flex flex-col items-center gap-3 bottom-full mb-2',
    bottom: 'absolute flex flex-row-reverse items-center gap-3 right-full mr-2'
  };

  const tooltipClass = {
    right: 'absolute right-full mr-2 top-1/2 -translate-y-1/2',
    left: 'absolute left-full ml-2 top-1/2 -translate-y-1/2',
    bottom: 'absolute bottom-full mb-2 right-0'
  };

  return (
    <motion.div 
      className={`${positionClasses[position]} z-50 ${customClass}`}
      animate={{ 
        opacity: isVisible ? 1 : 0,
        scale: isVisible ? 1 : 0.8,
        y: isVisible ? 0 : 20
      }}
      transition={{ duration: 0.3 }}
    >
      {/* Icônes de réseaux sociaux */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={shareIconsContainerClass[position]}
          >

            {/* Facebook */}
            <motion.div variants={itemVariants}>
              <FacebookShareButton url={url} quote={title} hashtag={hashtags.map(tag => `#${tag}`).join(' ')}>
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                  <FacebookIcon size={48} round className="shadow-md" />
                </motion.div>
              </FacebookShareButton>
            </motion.div>

            {/* Twitter/X */}
            <motion.div variants={itemVariants}>
              <TwitterShareButton url={url} title={title} hashtags={hashtags}>
                <XIcon />
              </TwitterShareButton>
            </motion.div>

            {/* LinkedIn */}
            <motion.div variants={itemVariants}>
              <LinkedinShareButton url={url} title={title} summary={description} source="VGom Creation">
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                  <LinkedinIcon size={48} round className="shadow-md" />
                </motion.div>
              </LinkedinShareButton>
            </motion.div>

            {/* WhatsApp */}
            <motion.div variants={itemVariants}>
              <WhatsappShareButton url={url} title={title}>
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                  <WhatsappIcon size={48} round className="shadow-md" />
                </motion.div>
              </WhatsappShareButton>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Bouton principal */}
      <div className="relative group">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setIsOpen(!isOpen)}
          className={`bg-gradient-to-r from-blue-600 to-indigo-700 hover:from-blue-700 hover:to-indigo-800 text-white rounded-full p-3.5 shadow-lg flex items-center justify-center`}
          aria-label={isOpen ? 'Fermer le menu de partage' : 'Ouvrir le menu de partage'}
        >
          {isOpen ? (
            <X size={24} />
          ) : (
            <Share2 size={22} className={position === 'bottom' ? 'mr-0' : ''} />
          )}
          {position === 'bottom' && (
            <span className={`ml-2 font-medium ${isOpen ? 'hidden' : 'block'} text-sm`}>
              Partager
            </span>
          )}
        </motion.button>

        {/* Tooltip */}
        {position !== 'bottom' && (
          <motion.div
            initial={{ opacity: 0, x: position === 'right' ? -10 : 10 }}
            whileHover={{ opacity: 1, x: 0 }}
            className={`${tooltipClass[position]} bg-gray-800 text-white text-xs rounded px-2 py-1 shadow-lg whitespace-nowrap`}
          >
            {isOpen ? 'Fermer' : 'Partager cette page'}
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default BoutonsPartage;