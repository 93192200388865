import React, { useState, useEffect, useRef, useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  UserPlus2,
  LogIn,
  LogOut,
  User,
  Menu,
  X,
  LayoutDashboard,
} from 'lucide-react';
import { UserContext } from '../context/UserContext';
import logo from '../assets/logo.png';
import { logout } from '../api';
import Tooltip from '@mui/material/Tooltip';
import { motion, AnimatePresence } from 'framer-motion';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(UserContext);
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    logout();
    localStorage.removeItem('jwt');
    navigate('/');
  };

  // Détection du scroll pour changer le style du header
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Ferme le menu lorsque l'utilisateur clique à l'extérieur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setAnimate(false), 1600);
    return () => clearTimeout(timeout);
  }, []);

  // Composant pour afficher l'icône utilisateur avec ses initiales
  // Correction dans le composant UserIcon

  const UserIcon = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const user = JSON.parse(localStorage.getItem('user'));

    // CORRECTION: Fix de la logique pour éviter "undefined undefined"
    const initials = (() => {
      // Si les initiales sont déjà définies, les utiliser
      if (user?.initials) {
        return user.initials;
      }

      // Si on a à la fois prénom et nom
      if (
        user?.firstname &&
        user?.lastname &&
        user.firstname.length > 0 &&
        user.lastname.length > 0
      ) {
        return `${user.firstname[0]}${user.lastname[0]}`.toUpperCase();
      }

      // Si on a juste un prénom
      if (user?.firstname && user.firstname.length > 0) {
        return user.firstname.substring(0, 2).toUpperCase();
      }

      // Si on a juste un nom
      if (user?.lastname && user.lastname.length > 0) {
        return user.lastname.substring(0, 2).toUpperCase();
      }

      // Si on a un email
      if (user?.email) {
        // Extraire uniquement la partie avant l'arobase
        const emailName = user.email.split('@')[0];

        // Si cette partie contient un point, utiliser les initiales des parties
        if (emailName.includes('.')) {
          const nameParts = emailName.split('.');
          return (
            nameParts[0].charAt(0) + nameParts[1].charAt(0)
          ).toUpperCase();
        }

        // Si l'email a un format "prénom-nom" avant l'arobase
        if (emailName.includes('-')) {
          const nameParts = emailName.split('-');
          return (
            nameParts[0].charAt(0) + nameParts[1].charAt(0)
          ).toUpperCase();
        }

        // Si l'email a un format "prénom_nom" avant l'arobase
        if (emailName.includes('_')) {
          const nameParts = emailName.split('_');
          return (
            nameParts[0].charAt(0) + nameParts[1].charAt(0)
          ).toUpperCase();
        }

        // Sinon utiliser les 2 premières lettres de la partie avant l'arobase
        return emailName.substring(0, 2).toUpperCase();
      }

      // Fallback ultime
      return 'U';
    })();
    // Vérifier si l'email a été confirmé
    const isEmailConfirmed = user?.confirmed || false;

    // Fermer le menu quand on clique ailleurs
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setMenuOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <Tooltip
        title={`${user?.firstname || ''} ${user?.lastname || ''} (${user?.email || ''})${!isEmailConfirmed ? ' - Email non vérifié' : ''}`}
      >
        <div className="relative" ref={menuRef}>
          <div
            className="relative cursor-pointer"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <div className="relative flex items-center justify-center">
              {isAuthenticated ? (
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gradient-to-r from-blue-600 to-indigo-700 text-white text-sm font-semibold shadow-md">
                  {initials}
                  {/* Afficher la bille verte uniquement si l'email est confirmé */}
                  {isEmailConfirmed && (
                    <div className="absolute -top-0.5 -right-0.5">
                      <div className="relative inline-flex rounded-full h-2.5 w-2.5 bg-green-500">
                        <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></div>
                      </div>
                    </div>
                  )}

                  {/* Indicateur de non-vérification pour les emails non confirmés */}
                  {!isEmailConfirmed && (
                    <div className="absolute -top-0.5 -right-0.5">
                      <div className="relative inline-flex rounded-full h-2.5 w-2.5 bg-amber-500">
                        <div className="animate-pulse absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"></div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <User className="w-6 h-6 text-white hover:text-gray-300 transition-transform transform hover:scale-110" />
              )}
            </div>
          </div>
          {/* Menu déroulant */}
          <AnimatePresence>
            {menuOpen && isAuthenticated && (
              <motion.div
                initial={{ opacity: 0, y: -5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -5 }}
                transition={{ duration: 0.2 }}
                className="absolute right-0 mt-2 w-48 bg-gray-800 shadow-lg rounded-lg overflow-hidden z-50 border border-gray-700"
              >
                <div className="p-3 border-b border-gray-700">
                  <p className="text-white font-medium">
                    {user?.firstname || ''} {user?.lastname || ''}
                  </p>
                  <p className="text-gray-400 text-xs truncate">
                    {user?.email || ''}
                  </p>
                </div>
                <div className="py-1">
                  <button
                    onClick={() => {
                      navigate('/dashboard');
                      setMenuOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-200 hover:bg-gray-700"
                  >
                    <div className="flex items-center">
                      <LayoutDashboard className="w-4 h-4 mr-2" />
                      Tableau de bord
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      navigate('/profile');
                      setMenuOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-200 hover:bg-gray-700"
                  >
                    <div className="flex items-center">
                      <User className="w-4 h-4 mr-2" />
                      Mon profil
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      handleLogout();
                      setMenuOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-red-400 hover:bg-gray-700"
                  >
                    <div className="flex items-center">
                      <LogOut className="w-4 h-4 mr-2" />
                      Déconnexion
                    </div>
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Tooltip>
    );
  };

  // Animation variants pour les éléments du menu
  const menuItemVariants = {
    closed: { opacity: 0, x: -10 },
    open: { opacity: 1, x: 0 },
  };

  // Animation du conteneur du menu mobile
  const mobileMenuVariants = {
    closed: {
      x: '100%',
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    open: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
        staggerChildren: 0.07,
        delayChildren: 0.1,
      },
    },
  };

  // Navigation links
  const navLinks = [
    { to: '/', text: 'Accueil' },
    { to: '/nos-offres', text: 'Nos offres' },
    { to: '/tarifs', text: 'Tarifs' },
    { to: '/galerie', text: 'Galerie' },
    { to: '/shop', text: 'Shop' },
    { to: '/contact', text: 'Contact' },
    { to: '/blog', text: 'Blog' },
  ];

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        scrolled
          ? 'bg-black bg-opacity-95 shadow-md py-2'
          : 'bg-gradient-to-r from-black to-gray-900 py-4'
      }`}
    >
      <div className="container mx-auto flex justify-between items-center px-4">
        {/* Logo */}
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <motion.img
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              src={logo}
              alt="VGM Digital"
              className={`h-16 md:h-20 ${animate ? 'start-animation' : ''}`}
            />
          </Link>
        </div>

        {/* Navigation Desktop */}
        <nav className="hidden lg:flex items-center">
          <ul className="flex space-x-1">
            {navLinks.map((item, index) => (
              <motion.li
                key={item.to}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.05 }}
              >
                <NavLink
                  to={item.to}
                  className={({ isActive }) =>
                    `px-3 py-2 mx-1 rounded-lg transition-all duration-200 text-sm font-medium ${
                      isActive
                        ? 'bg-gradient-to-r from-blue-600 to-indigo-700 text-white shadow-md'
                        : 'text-gray-200 hover:bg-white/10 hover:text-white'
                    }`
                  }
                >
                  {item.text}
                </NavLink>
              </motion.li>
            ))}
          </ul>
        </nav>

        {/* Icônes d'authentification pour le desktop */}
        <div className="hidden lg:flex items-center gap-3 ml-4">
          {!isAuthenticated ? (
            <>
              <Tooltip title="Inscription" arrow>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="cursor-pointer bg-transparent hover:bg-white/10 p-2 rounded-full transition-colors"
                >
                  <UserPlus2
                    className="w-5 h-5 text-gray-200"
                    onClick={() => navigate('/inscription')}
                  />
                </motion.div>
              </Tooltip>

              <Tooltip title="Connexion" arrow>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="cursor-pointer bg-gradient-to-r from-blue-600 to-indigo-700 p-2 rounded-full shadow-md hover:shadow-lg transition-all"
                  onClick={handleLogin}
                >
                  <LogIn className="w-5 h-5 text-white" />
                </motion.div>
              </Tooltip>
            </>
          ) : (
            <>
              <UserIcon />
            </>
          )}
        </div>

        {/* Contrôles mobile */}
        <div className="flex lg:hidden items-center gap-4">
          {/* Icônes d'authentification pour mobile */}
          {!isAuthenticated ? (
            <div className="flex items-center gap-3">
              <Tooltip title="Inscription">
                <span className="cursor-pointer">
                  <UserPlus2
                    className="w-5 h-5 text-gray-300"
                    onClick={() => navigate('/inscription')}
                  />
                </span>
              </Tooltip>
              <Tooltip title="Connexion">
                <span className="cursor-pointer bg-gradient-to-r from-blue-600 to-indigo-700 p-1.5 rounded-full">
                  <LogIn className="w-4 h-4 text-white" onClick={handleLogin} />
                </span>
              </Tooltip>
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <UserIcon />
            </div>
          )}

          {/* Bouton menu hamburger */}
          <button
            className="p-2 rounded-full hover:bg-white/10 transition-colors"
            onClick={toggleMenu}
            aria-label="Menu principal"
          >
            <Menu className="w-6 h-6 text-white" />
          </button>
        </div>

        {/* Menu mobile avec animation */}
        <AnimatePresence>
          {menuOpen && (
            <>
              {/* Overlay pour fermer le menu */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="fixed inset-0 bg-black bg-opacity-60 lg:hidden z-40"
                onClick={() => setMenuOpen(false)}
              />

              {/* Menu latéral */}
              <motion.div
                ref={menuRef}
                variants={mobileMenuVariants}
                initial="closed"
                animate="open"
                exit="closed"
                className="fixed top-0 right-0 bottom-0 w-3/4 max-w-sm bg-gradient-to-br from-black to-gray-900 z-50 p-6 flex flex-col shadow-2xl"
              >
                {/* En-tête du menu */}
                <div className="flex items-center justify-between mb-8">
                  <img src={logo} alt="VGom Creation" className="h-14" />
                  <button
                    className="p-2 rounded-full hover:bg-white/10 transition-colors"
                    onClick={() => setMenuOpen(false)}
                    aria-label="Fermer le menu"
                  >
                    <X className="w-6 h-6 text-white" />
                  </button>
                </div>

                {/* Liens de navigation */}
                <ul className="space-y-1">
                  {navLinks.map((item) => (
                    <motion.li key={item.to} variants={menuItemVariants}>
                      <NavLink
                        to={item.to}
                        className={({ isActive }) =>
                          `flex items-center px-4 py-3 rounded-lg transition-all duration-200 ${
                            isActive
                              ? 'bg-gradient-to-r from-blue-600 to-indigo-700 text-white font-medium shadow-sm'
                              : 'text-gray-300 hover:bg-white/5 hover:text-white'
                          }`
                        }
                        onClick={() => setMenuOpen(false)}
                      >
                        {item.text}
                      </NavLink>
                    </motion.li>
                  ))}
                </ul>

                {/* Section du bas du menu mobile */}
                <div className="mt-auto pt-6 border-t border-gray-800">
                  <p className="text-gray-400 text-sm mb-4">
                    VGM Digital - Solutions web professionnelles
                  </p>
                  <Link
                    to="/nous-contacter"
                    className="inline-flex items-center px-4 py-2 bg-white/10 hover:bg-white/15 text-white rounded-lg text-sm font-medium transition-colors"
                    onClick={() => setMenuOpen(false)}
                  >
                    Contactez-nous
                  </Link>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
}

export default Header;
