import React, { useEffect, useState, useCallback, useRef } from 'react';

const Particles = ({ className = "", quantity = 15, staticity = 30, ease = 30 }) => {
  const [particles, setParticles] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  // Calcul de la force d'attraction/répulsion optimisé
  const calculateForce = useCallback((distance) => {
    const normalizedStaticity = Math.max(5, Math.min(95, staticity)) / 100;
    return (1 - normalizedStaticity) * (1 / Math.max(1, distance)) * 2;
  }, [staticity]);

  // Gestion du mouvement de la souris avec useRef
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setMousePosition({ x, y });
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('mousemove', handleMouseMove);
      return () => container.removeEventListener('mousemove', handleMouseMove);
    }
  }, []);

  // Initialisation et animation des particules
  useEffect(() => {
    const initialParticles = Array.from({ length: quantity }, () => ({
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 4.5 + 0.5, // Particules plus petites
      velocityX: (Math.random() - 0.5) * 0.3,
      velocityY: (Math.random() - 0.5) * 0.3,
      opacity: Math.random() * 1 + 0.7, // Opacité ajustée
      originalX: Math.random() * 100,
      originalY: Math.random() * 100
    }));

    setParticles(initialParticles);

    const animateParticles = () => {
      setParticles(prevParticles =>
        prevParticles.map(particle => {
          const dx = mousePosition.x - particle.x;
          const dy = mousePosition.y - particle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          const force = calculateForce(distance);
          const normalizedEase = Math.max(5, Math.min(95, ease)) / 100;
          
          // Force d'élasticité plus douce
          const elasticityX = (particle.originalX - particle.x) * normalizedEase * 0.05;
          const elasticityY = (particle.originalY - particle.y) * normalizedEase * 0.05;

          // Vélocités plus douces
          const newVelocityX = particle.velocityX + (dx * force * 0.005) + elasticityX;
          const newVelocityY = particle.velocityY + (dy * force * 0.005) + elasticityY;

          // Amortissement plus fort
          const dampening = 0.92;
          let newX = particle.x + newVelocityX * dampening;
          let newY = particle.y + newVelocityY * dampening;

          // Limites de l'écran avec rebond
          if (newX < 0 || newX > 100) newX = particle.originalX;
          if (newY < 0 || newY > 100) newY = particle.originalY;

          return {
            ...particle,
            x: newX,
            y: newY,
            velocityX: newVelocityX * dampening,
            velocityY: newVelocityY * dampening
          };
        })
      );
    };

    const animationFrame = setInterval(animateParticles, 1000 / 30);
    return () => clearInterval(animationFrame);
  }, [quantity, mousePosition, calculateForce, ease]);

  return (
    <div ref={containerRef} className={`absolute inset-0 overflow-hidden pointer-events-none ${className}`}>
      {particles.map((particle, index) => (
        <div
          key={index}
          className="absolute rounded-full bg-blue-700"
          style={{
            left: `${particle.x}%`,
            top: `${particle.y}%`,
            width: `${particle.size * 4}px`,
            height: `${particle.size * 4}px`,
            opacity: particle.opacity,
            transform: 'translate(-50%, -50%)',
            boxShadow: '0 0 6px rgba(147, 51, 234, 0.3)',
            willChange: 'transform, opacity'
          }}
        />
      ))}
    </div>
  );
};

export default Particles;