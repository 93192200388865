import React from 'react';
import { motion } from 'framer-motion';
import {
  Shield,
  FileText,
  Clock,
  UserCheck,
  RefreshCw,
  Mail,
  Cookie,
} from 'lucide-react';

const PolitiqueConfidentialite = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="bg-gradient-to-br from-blue-600 to-indigo-700 py-24 md:py-32 pb-5 md:pb-10">
      <div className="container mx-auto px-4 py-6 sm:py-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-2xl shadow-xl p-6 sm:p-8 md:p-10"
        >
          {/* En-tête avec titre et description */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center mb-8"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="inline-block bg-blue-100 p-3 rounded-full mb-4"
            >
              <Shield size={32} className="text-blue-600" />
            </motion.div>

            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 text-gray-800">
              Politique de Confidentialité
            </h1>

            <p className="text-sm sm:text-base text-gray-600 max-w-lg sm:max-w-3xl mx-auto">
              Cette politique de confidentialité explique comment VGM Digital
              collecte, utilise, partage et protège vos informations
              personnelles lorsque vous visitez notre site web ou utilisez nos
              services.
            </p>

            <div className="w-24 h-1 mx-auto mt-6 rounded-full bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
          </motion.div>

          {/* Sections principales avec animations */}
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="space-y-6 sm:space-y-8"
          >
            {/* Section 1 - Utilisation des informations */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <FileText size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  1. Utilisation des informations
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed mb-3">
                  Vos informations personnelles sont utilisées pour :
                </p>
                <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700 space-y-1.5">
                  <li>
                    Traiter vos commandes et paiements via des prestataires
                    comme Stripe ;
                  </li>
                  <li>
                    Répondre à vos demandes et assurer le support client ;
                  </li>
                  <li>
                    Améliorer nos services et personnaliser votre expérience
                    utilisateur ;
                  </li>
                  <li>
                    Vous envoyer des communications marketing si vous avez donné
                    votre consentement explicite.
                  </li>
                </ul>
              </div>
            </motion.section>

            {/* Nouvelle section sur les cookies - à insérer après la section 1 */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Cookie size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  2. Utilisation des cookies
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed mb-3">
                  Notre site utilise différents types de cookies pour améliorer
                  votre expérience et permettre certaines fonctionnalités
                  essentielles :
                </p>

                <h3 className="font-semibold text-gray-800 mt-4 mb-2">
                  Types de cookies utilisés :
                </h3>

                <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700 space-y-2">
                  <li className="mb-1">
                    <span className="font-medium">Cookies essentiels</span> :
                    Nécessaires au fonctionnement du site
                    <ul className="list-circle ml-4 mt-1 text-sm text-gray-600">
                      <li>
                        <code>strapi_session</code> : Gère votre session sur
                        notre plateforme
                      </li>
                      <li>
                        <code>XSRF-TOKEN</code> : Assure la protection contre
                        les attaques CSRF
                      </li>
                      <li>
                        <code>jwt</code> : Stocke votre jeton d'authentification
                      </li>
                    </ul>
                  </li>

                  <li className="mb-1">
                    <span className="font-medium">
                      Cookies d'authentification
                    </span>{' '}
                    : Permettent de rester connecté à votre compte
                    <ul className="list-circle ml-4 mt-1 text-sm text-gray-600">
                      <li>
                        <code>rememberMe</code> : Garde votre session active
                        lorsque vous cochez "Se souvenir de moi"
                      </li>
                      <li>
                        <code>userInit</code> : Stocke vos initiales pour
                        personnaliser l'interface
                      </li>
                    </ul>
                  </li>
                  <li className="mb-1">
                    <span className="font-medium">Cookies tiers</span> :
                    Utilisés lors de l'authentification via des services
                    externes
                    <ul className="list-circle ml-4 mt-1 text-sm text-gray-600">
                      <li>
                        Cookies Google : Lors de la connexion avec un compte
                        Google
                      </li>
                      <li>
                        Cookies Facebook : Lors de la connexion avec un compte
                        Facebook
                      </li>
                    </ul>
                  </li>
                </ul>

                <h3 className="font-semibold text-gray-800 mt-4 mb-2">
                  Gestion de vos préférences
                </h3>
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  Lors de votre première visite sur notre site, une bannière
                  vous permet de choisir les cookies que vous souhaitez
                  accepter. Vous pouvez modifier vos préférences à tout moment
                  en cliquant sur le bouton "Paramètres des cookies" présent en
                  bas de chaque page.
                </p>

                <h3 className="font-semibold text-gray-800 mt-4 mb-2">
                  Durée de conservation
                </h3>
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  La durée de conservation des cookies varie selon leur type :
                </p>
                <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700 space-y-1">
                  <li>
                    Cookies de session : Supprimés à la fermeture du navigateur
                  </li>
                  <li>
                    Cookies d'authentification : 30 jours maximum avec l'option
                    "Se souvenir de moi", sinon 2 heures
                  </li>
                  <li>Cookies de préférences : 6 mois</li>
                </ul>
              </div>
            </motion.section>
            {/* Section 3 - Partage des informations */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <UserCheck size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  3. Partage des informations
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed mb-3">
                  Nous partageons vos informations personnelles uniquement dans
                  les cas suivants :
                </p>
                <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700 space-y-1.5">
                  <li>
                    Avec nos partenaires de paiement (par ex., Stripe) pour
                    sécuriser vos transactions ;
                  </li>
                  <li>
                    Avec des prestataires tiers de confiance pour l'hébergement
                    et l'analyse de données ;
                  </li>
                  <li>
                    Pour se conformer à des obligations légales ou des demandes
                    des autorités compétentes.
                  </li>
                </ul>
              </div>
            </motion.section>

            {/* Section 4 - Durée de conservation des données */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Clock size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  4. Durée de conservation des données
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed mb-3">
                  Vos données personnelles sont conservées aussi longtemps que
                  nécessaire pour les finalités décrites dans cette politique :
                </p>
                <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700 space-y-1.5">
                  <li>
                    Les données liées aux commandes sont conservées conformément
                    aux obligations légales (par ex., 10 ans pour des documents
                    comptables).
                  </li>
                  <li>
                    Les données marketing sont conservées jusqu'à retrait de
                    consentement ou après une période d'inactivité de 3 ans.
                  </li>
                </ul>
              </div>
            </motion.section>

            {/* Section 5 - Vos droits */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Shield size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  5. Vos droits
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed mb-3">
                  Conformément au RGPD, vous disposez des droits suivants :
                </p>
                <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700 space-y-1.5">
                  <li>Accéder à vos données personnelles ;</li>
                  <li>
                    Demander la rectification ou la suppression de vos données ;
                  </li>
                  <li>
                    Limiter ou vous opposer au traitement de vos données ;
                  </li>
                  <li>
                    Retirer votre consentement à tout moment pour les
                    communications marketing ;
                  </li>
                  <li>
                    Déposer une plainte auprès de la CNIL (Commission Nationale
                    de l'Informatique et des Libertés) si nécessaire.
                  </li>
                </ul>
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed mt-3">
                  Pour exercer vos droits, contactez-nous à{' '}
                  <a
                    href="mailto:contact@vgomcreation.fr"
                    className="text-blue-600 hover:underline"
                  >
                    contact@vgomcreation.fr
                  </a>
                  .
                </p>
              </div>
            </motion.section>

            {/* Section 6 - Modifications de la politique */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <RefreshCw size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">
                  6. Modifications de la politique
                </h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  Nous nous réservons le droit de modifier cette politique de
                  confidentialité à tout moment. Les modifications seront
                  publiées sur cette page. Nous vous encourageons à consulter
                  cette politique régulièrement pour rester informé.
                </p>
              </div>
            </motion.section>

            {/* Section 7 - Contact */}
            <motion.section
              variants={itemVariants}
              className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
            >
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Mail size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">7. Contact</h2>
              </div>

              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  Pour toute question ou demande relative à cette politique,
                  vous pouvez nous contacter par e-mail à{' '}
                  <a
                    href="mailto:contact@vgomcreation.fr"
                    className="text-blue-600 hover:underline"
                  >
                    contact@vgomcreation.fr
                  </a>
                  .
                </p>
              </div>
            </motion.section>
          </motion.div>

          <motion.section
            variants={itemVariants}
            className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm"
          >
            <div className="flex items-center mb-3">
              <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                <Cookie size={20} />
              </div>
              <h2 className="text-lg sm:text-xl font-semibold">
                7. Vos droits concernant les cookies
              </h2>
            </div>

            <div className="ml-12">
              <p className="text-sm sm:text-base text-gray-700 leading-relaxed mb-3">
                Conformément à la directive ePrivacy et au RGPD, vous disposez
                des droits suivants concernant les cookies :
              </p>
              <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700 space-y-1.5">
                <li>Droit de refuser les cookies non-essentiels</li>
                <li>Droit de retirer votre consentement à tout moment</li>
                <li>
                  Droit d'effacer les cookies stockés dans votre navigateur
                </li>
              </ul>

              <p className="text-sm sm:text-base text-gray-700 mt-3 leading-relaxed">
                <span className="font-medium">Important</span> : Certaines
                fonctionnalités du site, comme l'authentification et le panier
                d'achat, peuvent ne pas fonctionner correctement si vous refusez
                les cookies essentiels.
              </p>

              <p className="text-sm sm:text-base text-gray-700 mt-3 leading-relaxed">
                Pour gérer vos préférences de cookies, vous pouvez :
              </p>
              <ul className="list-disc ml-4 sm:ml-6 text-sm sm:text-base text-gray-700 space-y-1.5">
                <li>
                  Utiliser notre panneau de gestion des cookies disponible via
                  le lien "Paramètres des cookies" en bas de page
                </li>
                <li>
                  Configurer directement votre navigateur pour bloquer ou
                  supprimer les cookies
                </li>
              </ul>
            </div>
          </motion.section>

          {/* Élément de décoration en bas de page */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="w-full flex justify-center mt-10"
          >
            <div className="w-24 h-1 rounded-full bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default PolitiqueConfidentialite;
