import React from 'react';
import { motion } from 'framer-motion';

const CGU = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <>
      {/* Fond dégradé et contenu principal */}
      <div className="bg-gradient-to-br from-blue-600 to-indigo-700 py-24 md:py-32 pb-5 md:pb-10">
        <div className="container mx-auto px-4 py-6 sm:py-8">
          <motion.div 
            initial={{ opacity: 0, y: -20 }} 
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-2xl shadow-xl p-6 sm:p-8 md:p-10"
          >
            {/* En-tête avec titre et description */}
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-center mb-8"
            >
              <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 text-gray-800">
                Conditions Générales d'Utilisation (CGU)
              </h1>
              <p className="text-sm sm:text-base text-gray-600 max-w-lg sm:max-w-3xl mx-auto">
                Les présentes Conditions Générales d'Utilisation (CGU) définissent les règles d'utilisation du site internet VGM Digital. En accédant au site, vous acceptez ces conditions dans leur intégralité.
              </p>
            </motion.div>

            {/* Sections principales avec animations */}
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="space-y-6 sm:space-y-8"
            >
              <motion.section variants={itemVariants}>
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center text-white mr-3">1</div>
                  <h2 className="text-lg sm:text-xl font-semibold">Objet des CGU</h2>
                </div>
                <div className="ml-11">
                  <p className="text-sm sm:text-base text-gray-700">
                    Les présentes CGU définissent les règles d'utilisation des services proposés par VGM Digital, ainsi que les droits et obligations des utilisateurs et de VGM Digital.
                  </p>
                </div>
              </motion.section>

              <motion.section variants={itemVariants}>
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center text-white mr-3">2</div>
                  <h2 className="text-lg sm:text-xl font-semibold">L'espace membre</h2>
                </div>
                <div className="ml-11">
                  <p className="text-sm sm:text-base text-gray-700">
                    Le site internet ne comprend pas d'espace membre. Aucune création de compte ou gestion personnelle n'est requise pour accéder aux services du site.
                  </p>
                </div>
              </motion.section>

              <motion.section variants={itemVariants}>
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center text-white mr-3">3</div>
                  <h2 className="text-lg sm:text-xl font-semibold">L'éditeur du site</h2>
                </div>
                <div className="ml-11 space-y-2">
                  <p className="text-sm sm:text-base text-gray-700">
                    <strong>Nom et prénom de l'éditeur :</strong> Vaitilingom Vincent
                  </p>
                  <p className="text-sm sm:text-base text-gray-700">
                    <strong>Adresse de domiciliation :</strong> 7 Rue de la Louette, 91150 Étampes, France
                  </p>
                  <p className="text-sm sm:text-base text-gray-700">
                    <strong>Numéro de téléphone :</strong> 07 67 60 34 49
                  </p>
                  <p className="text-sm sm:text-base text-gray-700">
                    <strong>Adresse e-mail :</strong>{' '}
                    <a href="mailto:vaitilingomvincent@gmail.com" className="text-blue-600 hover:text-blue-800 hover:underline">
                      vaitilingomvincent@gmail.com
                    </a>
                  </p>
                  <p className="text-sm sm:text-base text-gray-700">
                    <strong>Régime juridique :</strong> Non assujetti au RCS ou au Répertoire des métiers.
                  </p>
                </div>
              </motion.section>

              {/* Continuez avec les autres sections de la même façon */}
              
              {/* Exemple pour une autre section */}
              <motion.section variants={itemVariants}>
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center text-white mr-3">4</div>
                  <h2 className="text-lg sm:text-xl font-semibold">L'hébergeur du site</h2>
                </div>
                <div className="ml-11 space-y-2">
                  <p className="text-sm sm:text-base text-gray-700">
                    <strong>Nom du site de l'hébergeur :</strong> Vercel
                  </p>
                  {/* Autres informations d'hébergement */}
                </div>
              </motion.section>
              
              {/* Continuez avec les sections 5 à 11... */}
            </motion.div>
            
            {/* Élément de décoration en bas de page */}
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="w-full flex justify-center mt-10"
            >
              <div className="w-24 h-1 rounded-full bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default CGU;