import React, { useState, useEffect } from 'react';
import { Mail, MapPin, Clock, Smartphone, Calendar, Send, CheckCircle, AlertCircle, ArrowLeft } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Particles from '../animations/Particles';
import BackHomeButton from '../components/BackHomeButton';

const Contact = () => {
  const [formData, setFormData] = useState({
    prenom: '',
    nom: '',
    email: '',
    telephone: '',
    sujet: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [activeTab, setActiveTab] = useState('formulaire');

  useEffect(() => {
    // Reset du statut après 5 secondes en cas de succès
    if (submitStatus === 'success') {
      const timer = setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [submitStatus]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('loading');

    try {
      const formDataToSend = new FormData();
      formDataToSend.append(
        'access_key',
        process.env.REACT_APP_WEB3FORM_API_KEY
      );
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formDataToSend,
      });

      const data = await response.json();
      console.log('Réponse Web3Forms :', data);

      if (data.success) {
        setSubmitStatus('success');
        setFormData({ prenom: '', nom: '', email: '', telephone: '', sujet: '', message: '' });
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCalendly = () => {
    window.open('https://calendly.com/vincentvaiti/30min', '_blank');
  };

  const contactInfos = [
    {
      icon: <MapPin size={24} />,
      title: 'Localisation',
      content: 'Paris & Périphérie, France',
      color: 'from-blue-600 to-black'
    },
    {
      icon: <Smartphone size={24} />,
      title: 'Téléphone',
      content: '07 67 60 34 49',
      color: 'from-blue-800 to-blue-600'
    },
    {
      icon: <Mail size={24} />,
      title: 'E-mail',
      content: 'contact@vgomcreation.fr',
      color: 'from-blue-600 to-black'
    },
    {
      icon: <Clock size={24} />,
      title: 'Horaires',
      content: ['Du lundi au vendredi : 9h - 17h', 'Week-end & jours fériés : Fermé'],
      color: 'from-blue-800 to-blue-600'
    }
  ];

  // Variants pour les animations
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  const formVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.3 }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white mt-28 md:mt-36 px-4 sm:px-6">
      {/* Hero Section */}
      <motion.div 
        className="relative w-full bg-black text-white rounded-2xl overflow-hidden mb-12 shadow-xl"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Particles
          className="absolute inset-0 z-0 hidden md:block"
          quantity={15}
          staticity={30}
          ease={40}
        />
        <div className="relative z-10 py-12 md:py-16 px-6 text-center">
          <motion.h1 
            className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.7 }}
          >
            Nous Contacter
          </motion.h1>
          <motion.p 
            className="text-lg max-w-2xl mx-auto z-50 text-gray-600"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.7 }}
          >
            Une question ? Besoin d'information ? Notre équipe est à votre
            disposition pour répondre à tous vos besoins.
          </motion.p>
        </div>
      </motion.div>

      {/* Container principal */}
      <div className="max-w-6xl mx-auto">
        {/* Bouton retour */}
        <motion.div 
          className="mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <BackHomeButton />
        </motion.div>

        <div className="flex flex-col lg:flex-row gap-10">
          {/* Infos de contact */}
          <motion.div 
            className="lg:w-2/5"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.h2 
              className="text-2xl font-bold mb-6 text-gray-800"
              variants={itemVariants}
            >
              Nos Coordonnées
            </motion.h2>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-6">
              {contactInfos.map((info, index) => (
                <motion.div
                  key={index}
                  className={`relative rounded-xl shadow-lg overflow-hidden bg-gradient-to-r ${info.color} p-6 text-white`}
                  variants={itemVariants}
                >
                  <div className="relative z-10">
                    <div className="flex items-center gap-3 mb-4">
                      <div className="bg-white/20 p-3 rounded-full">
                        {info.icon}
                      </div>
                      <h3 className="text-xl font-bold">{info.title}</h3>
                    </div>
                    {Array.isArray(info.content) ? (
                      info.content.map((line, i) => (
                        <p key={i} className="text-blue-50">{line}</p>
                      ))
                    ) : (
                      <p className="text-blue-50">{info.content}</p>
                    )}
                  </div>
                </motion.div>
              ))}
            </div>

            {/* Call to action */}
            <motion.div
              className="mt-8 bg-gray-50 rounded-xl p-6 shadow-md border border-gray-100"
              variants={itemVariants}
            >
              <h3 className="text-lg font-semibold mb-3 text-gray-800">Besoin d'une réponse rapide ?</h3>
              <p className="text-gray-600 mb-4">
                Planifiez un rendez-vous téléphonique ou visioconférence pour discuter directement avec notre équipe.
              </p>
              <button
                onClick={handleCalendly}
                className="w-full bg-gray-700 text-white py-3 rounded-lg font-medium transition-all hover:bg-gray-800 flex items-center justify-center gap-2"
              >
                <Calendar size={18} />
                <span>Planifier un rendez-vous</span>
              </button>
            </motion.div>
          </motion.div>

          {/* Formulaire et onglets */}
          <motion.div 
            className="lg:w-3/5"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.7, delay: 0.3 }}
          >
            {/* Onglets */}
            <div className="flex mb-6 bg-gray-100 p-1 rounded-lg">
              <button
                className={`flex-1 py-3 px-4 rounded-md transition-all ${
                  activeTab === 'formulaire'
                    ? 'bg-white shadow-md text-blue-600 font-medium'
                    : 'text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setActiveTab('formulaire')}
              >
                Formulaire de contact
              </button>
              <button
                className={`flex-1 py-3 px-4 rounded-md transition-all ${
                  activeTab === 'urgence'
                    ? 'bg-white shadow-md text-blue-600 font-medium'
                    : 'text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setActiveTab('urgence')}
              >
                Contact d'urgence
              </button>
            </div>

            {/* Contenu des onglets */}
            <AnimatePresence mode="wait">
              {activeTab === 'formulaire' ? (
                <motion.div
                  key="form"
                  variants={formVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className="bg-white rounded-xl shadow-lg p-8"
                >
                  <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    Envoyez-nous un message
                  </h2>

                  {submitStatus === 'success' ? (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{ opacity: 1, scale: 1 }}
                      className="text-center py-10"
                    >
                      <div className="inline-flex items-center justify-center bg-green-100 text-green-600 rounded-full w-16 h-16 mb-4">
                        <CheckCircle size={32} />
                      </div>
                      <h3 className="text-xl font-bold text-gray-800 mb-2">Message envoyé avec succès !</h3>
                      <p className="text-gray-600 mb-6">
                        Nous vous répondrons dans les meilleurs délais.
                      </p>
                      <button
                        onClick={() => setSubmitStatus(null)}
                        className="inline-flex items-center px-4 py-2 text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
                      >
                        <ArrowLeft size={16} className="mr-2" />
                        Envoyer un autre message
                      </button>
                    </motion.div>
                  ) : (
                    <form onSubmit={handleSubmit} className="space-y-6">
                      {/* Informations personnelles */}
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div>
                          <label htmlFor="prenom" className="block text-gray-700 font-medium mb-2">
                            Prénom *
                          </label>
                          <input
                            type="text"
                            id="prenom"
                            name="prenom"
                            value={formData.prenom}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                            placeholder="Votre prénom"
                          />
                        </div>
                        <div>
                          <label htmlFor="nom" className="block text-gray-700 font-medium mb-2">
                            Nom *
                          </label>
                          <input
                            type="text"
                            id="nom"
                            name="nom"
                            value={formData.nom}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                            placeholder="Votre nom"
                          />
                        </div>
                      </div>

                      {/* Coordonnées */}
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div>
                          <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
                            Email *
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                            placeholder="votre@email.com"
                          />
                        </div>
                        <div>
                          <label htmlFor="telephone" className="block text-gray-700 font-medium mb-2">
                            Téléphone
                          </label>
                          <input
                            type="tel"
                            id="telephone"
                            name="telephone"
                            value={formData.telephone}
                            onChange={handleChange}
                            className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                            placeholder="Votre numéro (optionnel)"
                          />
                        </div>
                      </div>

                      {/* Sujet */}
                      <div>
                        <label htmlFor="sujet" className="block text-gray-700 font-medium mb-2">
                          Sujet *
                        </label>
                        <select
                          id="sujet"
                          name="sujet"
                          value={formData.sujet}
                          onChange={handleChange}
                          required
                          className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                        >
                          <option value="">Sélectionnez un sujet</option>
                          <option value="devis">Demande de devis</option>
                          <option value="information">Demande d'information</option>
                          <option value="support">Support technique</option>
                          <option value="partenariat">Proposition de partenariat</option>
                          <option value="autre">Autre</option>
                        </select>
                      </div>

                      {/* Message */}
                      <div>
                        <label htmlFor="message" className="block text-gray-700 font-medium mb-2">
                          Message *
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                          rows="5"
                          className="w-full px-4 py-3 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors resize-none"
                          placeholder="Détaillez votre demande ici..."
                        ></textarea>
                      </div>

                      {/* Message d'erreur */}
                      {submitStatus === 'error' && (
                        <div className="bg-red-50 text-red-600 p-4 rounded-lg flex items-center gap-2">
                          <AlertCircle size={18} />
                          <span>Une erreur est survenue. Veuillez réessayer.</span>
                        </div>
                      )}

                      {/* Politique de confidentialité */}
                      <div className="text-sm text-gray-500">
                        <p>
                          Les informations recueillies sont nécessaires pour traiter votre demande. 
                          En soumettant ce formulaire, vous acceptez notre{' '}
                          <a href="/politique-confidentialite" className="text-blue-600 hover:underline">
                            politique de confidentialité
                          </a>.
                        </p>
                      </div>

                      {/* Bouton d'envoi */}
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full bg-blue-800 hover:bg-blue-700 text-white py-3 rounded-lg font-medium transition-all flex items-center justify-center gap-2"
                      >
                        {isSubmitting ? (
                          <>
                            <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full"></div>
                            <span>Envoi en cours...</span>
                          </>
                        ) : (
                          <>
                            <Send size={18} />
                            <span>Envoyer le message</span>
                          </>
                        )}
                      </button>
                    </form>
                  )}
                </motion.div>
              ) : (
                <motion.div
                  key="urgence"
                  variants={formVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className="bg-white rounded-xl shadow-lg p-8"
                >
                  <div className="text-center py-6">
                    <div className="inline-flex items-center justify-center bg-red-100 text-red-600 rounded-full w-16 h-16 mb-4">
                      <Smartphone size={32} />
                    </div>
                    <h3 className="text-xl font-bold text-gray-800 mb-2">Contact d'urgence</h3>
                    <p className="text-gray-600 mb-6">
                      Pour toute urgence concernant votre site ou service en ligne,
                      contactez-nous directement par téléphone :
                    </p>
                    <a 
                      href="tel:0767603449" 
                      className="text-2xl font-bold text-blue-600 hover:underline"
                    >
                      07 67 60 34 49
                    </a>
                    <p className="mt-6 text-gray-500">
                      Disponible en semaine de 9h à 17h.<br />
                      Pour les week-ends et jours fériés, envoyez un email à <strong>contact@vgomdigital.fr</strong>
                    </p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Contact;