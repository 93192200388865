import React from 'react';
import { motion } from 'framer-motion';
import { X, ArrowLeft, ShoppingBag, AlertTriangle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const CancelPage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-red-50 to-white p-4 mt-28 py-12 overflow-x-hidden">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md bg-white rounded-2xl shadow-xl overflow-hidden relative"
      >
        {/* Bordure supérieure rouge */}
        <div className="h-1 w-full bg-gradient-to-r from-red-400 to-red-600"></div>

        {/* Cercle décoratif - maintenant avec overflow-hidden sur le parent */}
        <div className="absolute -top-16 -right-16 w-48 h-48 bg-red-50 rounded-full opacity-70"></div>

        {/* Contenu principal */}
        <div className="relative px-6 pt-10 pb-8 sm:px-10">
          {/* Icône et titre */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="flex flex-col items-center mb-8"
          >
            <div className="relative">
              <div className="w-20 h-20 bg-red-100 rounded-full flex items-center justify-center">
                <X className="text-red-500 w-10 h-10" />
              </div>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  delay: 0.5,
                  type: 'spring',
                  stiffness: 500,
                  damping: 15,
                }}
                className="absolute -top-1 -right-1 bg-red-500 rounded-full w-6 h-6 flex items-center justify-center"
              >
                <AlertTriangle className="text-white w-3 h-3" />
              </motion.div>
            </div>

            <h1 className="mt-6 text-2xl sm:text-3xl font-bold text-gray-800 text-center">
              Paiement annulé
            </h1>

            <motion.div
              initial={{ width: '0%' }}
              animate={{ width: '40%' }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="h-1 bg-red-200 rounded-full mt-4"
            ></motion.div>
          </motion.div>

          {/* Message d'erreur */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="bg-red-50 border-l-4 border-red-400 p-4 mb-8 rounded-r"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-5 w-5 text-red-500" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">
                  Votre transaction n'a pas été complétée. Aucun prélèvement n'a
                  été effectué sur votre compte.
                </p>
              </div>
            </div>
          </motion.div>

          {/* Description */}
          <motion.p
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="text-gray-600 mb-8 text-center"
          >
            Si vous avez annulé ce paiement par erreur, vous pouvez réessayer à
            tout moment. Nous sommes là pour vous aider si vous rencontrez des
            difficultés.
          </motion.p>

          {/* Options */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="flex flex-col space-y-3"
          >
            <button
              onClick={() => navigate('/shop')}
              className="flex-1 px-4 py-2.5 sm:px-6 sm:py-3 text-sm sm:text-base bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-lg transition-all duration-200 font-medium flex items-center justify-center group"
            >
              <ArrowLeft className="mr-2 w-3 h-3 sm:w-4 sm:h-4 group-hover:-translate-x-1 transition-transform duration-200" />
              Retour au magasin
            </button>

            <button
              onClick={() => navigate('/nos-offres')}
              className="flex-1 px-4 py-2.5 sm:px-6 sm:py-3 text-sm sm:text-base bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-all duration-200 font-medium flex items-center justify-center group shadow-lg shadow-blue-500/20"
            >
              <ShoppingBag className="mr-2 w-3 h-3 sm:w-4 sm:h-4 group-hover:scale-110 transition-transform duration-200" />
              Voir nos offres
            </button>
          </motion.div>

          {/* Support */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.7 }}
            className="mt-8 pt-4 border-t border-gray-100 text-center"
          >
            <p className="text-xs text-gray-500">
              Besoin d'aide ?{' '}
              <a
                href="/nous-contacter"
                className="text-blue-600 hover:text-blue-800 hover:underline"
              >
                Contactez notre support
              </a>
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default CancelPage;
