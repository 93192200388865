// src/pages/Profile.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { API_URL } from '../api';
import {
  Mail,
  Phone,
  MapPin,
  Shield,
  AlertCircle,
  Edit,
  Save,
  X,
  CheckCircle,
  Lock,
} from 'lucide-react';
import { motion } from 'framer-motion';

function Profile() {
  const { isAuthenticated } = useContext(UserContext);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    postalCode: '',
    country: '',
    emailNotif: true,
    supportNotif: true,
    projectNotif: true,
  });
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  // Charger les données de l'utilisateur
  useEffect(() => {
    if (!isAuthenticated && !localStorage.getItem('jwt')) {
      navigate('/login');
      return null;
    }

    // Récupérer les données depuis localStorage (à remplacer par un appel API)
    const user = JSON.parse(localStorage.getItem('user'));
    setUserData(user);
    setFormData({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phone || '',
      address: user?.address || '',
      city: user?.city || '',
      postalCode: user?.postalCode || '',
      country: user?.country || '',
      emailNotif: user?.emailNotif !== undefined ? user.emailNotif : true,
      supportNotif: user?.supportNotif !== undefined ? user.supportNotif : true,
      projectNotif: user?.projectNotif !== undefined ? user.projectNotif : true,
    });
  }, [isAuthenticated, navigate]);

  // Gérer les changements de champs du formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Gérer les changements de cases à cocher
  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Soumettre les modifications
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Vérifier explicitement le token
    const jwt = localStorage.getItem('jwt');
    const user = JSON.parse(localStorage.getItem('user'));

    if (!jwt || !user) {
      console.error('Aucun utilisateur connecté');
      navigate('/login'); // Rediriger vers la page de connexion
      return;
    }

    const validateForm = () => {
      const errors = {};

      // Validation du prénom
      if (!formData.firstName.trim()) {
        errors.firstName = 'Le prénom est requis';
      } else if (formData.firstName.trim().length < 2) {
        errors.firstName = 'Le prénom doit contenir au moins 2 caractères';
      }

      // Validation du nom
      if (!formData.lastName.trim()) {
        errors.lastName = 'Le nom est requis';
      } else if (formData.lastName.trim().length < 2) {
        errors.lastName = 'Le nom doit contenir au moins 2 caractères';
      }

      // Validation du téléphone si renseigné
      if (
        formData.phone &&
        !/^(\+\d{1,3}[ -]?)?\d{9,10}$/.test(formData.phone.trim())
      ) {
        errors.phone = 'Numéro de téléphone invalide';
      }

      // Validation du code postal si renseigné
      if (formData.postalCode && !/^\d{5}$/.test(formData.postalCode.trim())) {
        errors.postalCode = 'Code postal invalide (5 chiffres)';
      }

      return errors;
    };

    // Valider le formulaire avant envoi
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsLoading(false);
      return;
    }

    try {
      // Utilisation de l'endpoint Strapi pour mettre à jour le profil
      const response = await fetch(`${API_URL}/api/users/${user.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
        
            address: formData.address,
            city: formData.city,
            postalCode: formData.postalCode,
            country: formData.country,
          
            emailNotif: formData.emailNotif,
            supportNotif: formData.supportNotif,
            projectNotif: formData.projectNotif,
        
        }),
      });

      if (response.ok) {
        const updatedUser = await response.json();

        // Mettre à jour les données locales
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setUserData(updatedUser);

        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 3000);
        setEditMode(false);
      } else {
        // Gestion plus détaillée des erreurs
        const errorData = await response.text();
        console.error("Détails de l'erreur:", {
          status: response.status,
          errorMessage: errorData,
        });
        // Gérer la déconnexion si le token est invalide
        if (response.status === 401) {
          localStorage.removeItem('jwt');
          localStorage.removeItem('user');
          navigate('/login');
        }
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!userData) return null;

  return (
    <div className="min-h-screen pt-32 pb-12 bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8 flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div>
            <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-indigo-500">
              Profil utilisateur
            </h1>
            <p className="mt-2 text-gray-300">
              Gérez vos informations personnelles et préférences de compte
            </p>
          </div>
          {!editMode ? (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="mt-4 sm:mt-0 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg text-white font-medium flex items-center"
              onClick={() => setEditMode(true)}
            >
              <Edit className="w-4 h-4 mr-2" />
              Modifier le profil
            </motion.button>
          ) : (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="mt-4 sm:mt-0 px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg text-white font-medium flex items-center"
              onClick={() => setEditMode(false)}
            >
              <X className="w-4 h-4 mr-2" />
              Annuler
            </motion.button>
          )}
        </div>

        {/* Alerte de succès */}
        {saveSuccess && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            className="mb-6 p-4 bg-green-800 bg-opacity-50 border border-green-700 rounded-lg flex items-center"
          >
            <CheckCircle className="w-5 h-5 text-green-400 mr-3" />
            <p className="text-green-200">
              Vos informations ont été mises à jour avec succès.
            </p>
          </motion.div>
        )}

        <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg border border-gray-700 overflow-hidden mb-8">
          {/* Informations utilisateur */}
          <div className="p-6 md:p-8 border-b border-gray-700">
            <div className="flex flex-col md:flex-row">
              {/* Avatar / Initiales */}
              <div className="flex-shrink-0 mb-6 md:mb-0 md:mr-8 flex flex-col items-center">
                <div className="w-24 h-24 rounded-full bg-gradient-to-r from-blue-600 to-indigo-700 flex items-center justify-center text-3xl font-bold text-white shadow-md mb-3">
                  {userData.firstName?.[0]}
                  {userData.lastName?.[0]}
                </div>
                {userData.confirmed ? (
                  <div className="flex items-center text-green-400 text-sm">
                    <CheckCircle className="w-4 h-4 mr-1" />
                    Email vérifié
                  </div>
                ) : (
                  <div className="flex items-center text-amber-400 text-sm">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    Email non vérifié
                  </div>
                )}
              </div>

              {/* Informations principales */}
              <div className="flex-grow">
                {editMode ? (
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Prénom
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {formErrors.firstName && (
                          <p className="text-xs text-red-400 mt-1">
                            {formErrors.firstName}
                          </p>
                        )}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Nom
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {formErrors.lastName && (
                          <p className="text-xs text-red-400 mt-1">
                            {formErrors.lastName}
                          </p>
                        )}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                          readOnly
                        />
                        <p className="text-xs text-gray-500 mt-1">
                          L'email ne peut pas être modifié directement
                        </p>
                        {formErrors.email && (
                          <p className="text-xs text-red-400 mt-1">
                            {formErrors.email}
                          </p>
                        )}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Téléphone
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {formErrors.phone && (
                          <p className="text-xs text-red-400 mt-1">
                            {formErrors.phone}
                          </p>
                        )}
                      </div>
                      <div className="md:col-span-2">
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Adresse
                        </label>
                        <input
                          type="text"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {formErrors.address && (
                          <p className="text-xs text-red-400 mt-1">
                            {formErrors.address}
                          </p>
                        )}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Ville
                        </label>
                        <input
                          type="text"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {formErrors.city && (
                          <p className="text-xs text-red-400 mt-1">
                            {formErrors.city}
                          </p>
                        )}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Code postal
                        </label>
                        <input
                          type="text"
                          name="postalCode"
                          value={formData.postalCode}
                          onChange={handleChange}
                          className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {formErrors.postalCode && (
                          <p className="text-xs text-red-400 mt-1">
                            {formErrors.postalCode}
                          </p>
                        )}
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Pays
                        </label>
                        <input
                          type="text"
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {formErrors.country && (
                          <p className="text-xs text-red-400 mt-1">
                            {formErrors.country}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        type="submit"
                        disabled={
                          isLoading ||
                          !formData.firstName.trim() ||
                          !formData.lastName.trim()
                        }
                        className={`px-6 py-2 bg-gradient-to-r from-blue-600 to-indigo-700 rounded-lg text-white font-medium flex items-center shadow-md ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                      >
                        {isLoading ? (
                          <>
                            <span className="animate-spin mr-2">⟳</span>
                            Enregistrement...
                          </>
                        ) : (
                          <>
                            <Save className="w-4 h-4 mr-2" />
                            Enregistrer
                          </>
                        )}
                      </motion.button>
                    </div>
                  </form>
                ) : (
                  <div className="space-y-4">
                    <div>
                      <h2 className="text-2xl font-semibold text-white">
                        {userData.firstName} {userData.lastName}
                      </h2>
                      <p className="text-gray-400">
                        Client depuis{' '}
                        {userData.createdAt
                          ? new Date(userData.createdAt).toLocaleDateString(
                              'fr-FR'
                            )
                          : 'récemment'}
                      </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="flex items-start">
                        <Mail className="w-5 h-5 text-gray-400 mt-0.5 mr-3" />
                        <div>
                          <p className="text-sm font-medium text-gray-400">
                            Email
                          </p>
                          <p className="text-white">{userData.email}</p>
                        </div>
                      </div>

                      <div className="flex items-start">
                        <Phone className="w-5 h-5 text-gray-400 mt-0.5 mr-3" />
                        <div>
                          <p className="text-sm font-medium text-gray-400">
                            Téléphone
                          </p>
                          <p className="text-white">
                            {userData.phone || 'Non renseigné'}
                          </p>
                        </div>
                      </div>

                      <div className="flex items-start md:col-span-2">
                        <MapPin className="w-5 h-5 text-gray-400 mt-0.5 mr-3" />
                        <div>
                          <p className="text-sm font-medium text-gray-400">
                            Adresse
                          </p>
                          <p className="text-white">
                            {userData.address ? (
                              <>
                                {userData.address} <br />
                                {userData.postalCode} {userData.city} <br />
                                {userData.country}
                              </>
                            ) : (
                              'Adresse non renseignée'
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Sécurité du compte */}
        <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg p-6 md:p-8 border border-gray-700 mb-8">
          <div className="flex items-center mb-6">
            <Shield className="w-6 h-6 text-blue-400 mr-3" />
            <h2 className="text-xl font-semibold text-white">
              Sécurité du compte
            </h2>
          </div>

          <div className="space-y-4">
            <div className="flex justify-between items-center p-4 bg-gray-700 bg-opacity-50 rounded-lg">
              <div className="flex items-center">
                <Lock className="w-5 h-5 text-gray-400 mr-3" />
                <div>
                  <p className="text-white">Mot de passe</p>
                  <p className="text-xs text-gray-400">
                    Dernière modification :{' '}
                    {userData.passwordUpdatedAt || 'jamais'}
                  </p>
                </div>
              </div>
              <button
                className="px-3 py-1.5 bg-blue-600 hover:bg-blue-700 rounded text-sm text-white transition"
                onClick={() => navigate('/forgot-password')}
              >
                Modifier
              </button>
            </div>

            <div className="flex justify-between items-center p-4 bg-gray-700 bg-opacity-50 rounded-lg">
              <div className="flex items-center">
                <Mail className="w-5 h-5 text-gray-400 mr-3" />
                <div>
                  <p className="text-white">Vérification de l'email</p>
                  <p className="text-xs text-gray-400">
                    {userData.confirmed
                      ? 'Votre adresse email est vérifiée'
                      : "Votre adresse email n'est pas vérifiée"}
                  </p>
                </div>
              </div>
              {!userData.confirmed && (
                <button
                  className="px-3 py-1.5 bg-amber-600 hover:bg-amber-700 rounded text-sm text-white transition"
                  onClick={() => navigate('/resend-verification')}
                >
                  Vérifier
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Section Préférences de notifications */}
        <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg p-6 md:p-8 border border-gray-700">
          <h2 className="text-xl font-semibold text-white mb-6">
            Préférences de notifications
          </h2>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-white">Notifications par email</p>
                <p className="text-sm text-gray-400">
                  Recevoir des mises à jour sur vos projets
                </p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  name="emailNotif"
                  checked={formData.emailNotif}
                  onChange={handleToggle}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-700 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="text-white">Messages du support</p>
                <p className="text-sm text-gray-400">
                  Notifications lorsque le support vous répond
                </p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  name="supportNotif"
                  checked={formData.supportNotif}
                  onChange={handleToggle}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-700 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="text-white">Mises à jour de projets</p>
                <p className="text-sm text-gray-400">
                  Notifications lors des changements d'étape
                </p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  name="projectNotif"
                  checked={formData.projectNotif}
                  onChange={handleToggle}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-700 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>
          <div className="mt-6 pt-6 border-t border-gray-700 text-center">
            <p className="text-gray-400 mb-4">Des soucis avec votre compte ?</p>
            <button
              onClick={() => navigate('/nous-contacter')}
              className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg text-white font-medium transition"
            >
              Contacter le support
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
