import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

const FacebookDeletionConfirmation = () => {
  return (
    <div className="container mx-auto px-4 py-6 mt-24 md:mt-32">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-lg mx-auto bg-white p-8 rounded-xl shadow-md"
      >
        <div className="text-center mb-6">
          <CheckCircle size={64} className="mx-auto text-green-500 mb-4" />
          <h1 className="text-2xl font-bold text-gray-800">Demande de suppression enregistrée</h1>
        </div>
        
        <p className="text-gray-600 mb-4">
          Votre demande de suppression de données liées à Facebook a été enregistrée avec succès.
        </p>
        
        <p className="text-gray-600 mb-4">
          Conformément à notre politique de confidentialité, nous traiterons votre demande dans un délai de 30 jours. 
          Toutes vos données personnelles associées à votre compte Facebook seront supprimées de nos systèmes.
        </p>
        
        <p className="text-gray-600 mb-6">
          Pour toute question concernant votre demande, vous pouvez nous contacter à l'adresse : 
          <a href="mailto:contact@vgomcreation.fr" className="text-blue-600 hover:underline ml-1">
            contact@vgomcreation.fr
          </a>
        </p>
        
        <div className="text-center">
          <a 
            href="/" 
            className="inline-block px-6 py-3 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 transition-colors"
          >
            Retourner à l'accueil
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default FacebookDeletionConfirmation;