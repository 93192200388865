import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FileText, Globe, Copyright, AlertTriangle, Shield, Mail, Scale } from 'lucide-react';

const MentionsLegales = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="bg-gradient-to-br from-blue-600 to-indigo-700 py-24 md:py-32 pb-5 md:pb-10">
      <div className="container mx-auto px-4 py-6 sm:py-8">
        <motion.div 
          initial={{ opacity: 0, y: -20 }} 
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-2xl shadow-xl p-6 sm:p-8 md:p-10"
        >
          {/* En-tête avec titre et description */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center mb-8"
          >
            <motion.div 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="inline-block bg-blue-100 p-3 rounded-full mb-4"
            >
              <FileText size={32} className="text-blue-600" />
            </motion.div>
            
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 text-gray-800">
              Mentions Légales
            </h1>
            
            <p className="text-sm sm:text-base text-gray-600 max-w-lg sm:max-w-3xl mx-auto">
              Conformément à l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, 
              voici les informations relatives à l'éditeur et à l'hébergeur du site VGM Digital.
            </p>
            
            <div className="w-24 h-1 mx-auto mt-6 rounded-full bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
          </motion.div>

          {/* Sections principales avec animations */}
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="space-y-6 sm:space-y-8"
          >
            {/* Section 1 - Éditeur du site */}
            <motion.section variants={itemVariants} className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm">
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <FileText size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">1. Éditeur du site</h2>
              </div>
              
              <div className="ml-12 space-y-2">
                <p className="text-sm sm:text-base">
                  <strong>Nom de l'entreprise :</strong> VGM Digital
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Forme juridique :</strong> Micro-entreprise
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Adresse :</strong> 7 Rue de la Louette, 91150 Étampes, France
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Numéro de téléphone :</strong> 07 67 60 34 49
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Adresse e-mail :</strong>{' '}
                  <a href="mailto:contact@vgomcreation.fr" className="text-blue-600 hover:underline">
                    contact@vgomcreation.fr
                  </a>
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Responsable de publication :</strong> M. Vaïtilingom Vincent
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Numéro SIRET :</strong> 93380665500017
                </p>
              </div>
            </motion.section>

            {/* Section 2 - Hébergeur du site */}
            <motion.section variants={itemVariants} className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm">
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Globe size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">2. Hébergeur du site</h2>
              </div>
              
              <div className="ml-12 space-y-2">
                <p className="text-sm sm:text-base">
                  <strong>Nom de l'hébergeur :</strong> Vercel
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Adresse :</strong> 340 S Lemon Ave #4133, Walnut, CA 91789, United States
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Adresse e-mail :</strong> support@vercel.com
                </p>
                <p className="text-sm sm:text-base">
                  <strong>Site Web :</strong>{' '}
                  <a
                    href="https://vercel.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    https://vercel.com
                  </a>
                </p>
              </div>
            </motion.section>

            {/* Section 3 - Propriété intellectuelle */}
            <motion.section variants={itemVariants} className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm">
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Copyright size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">3. Propriété intellectuelle</h2>
              </div>
              
              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  Le site VGM Digital et tout son contenu (textes, images, graphismes, logos, vidéos, icônes, etc.) sont la propriété exclusive de VGM Digital 
                  ou de ses partenaires, sauf mention contraire. Toute reproduction, représentation, modification, publication, adaptation totale ou partielle 
                  de ces éléments, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable.
                </p>
              </div>
            </motion.section>

            {/* Section 4 - Responsabilité */}
            <motion.section variants={itemVariants} className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm">
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <AlertTriangle size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">4. Responsabilité</h2>
              </div>
              
              <div className="ml-12 space-y-3">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  VGM Digital s'efforce de fournir des informations aussi précises que possible. Toutefois, l'entreprise ne pourra être tenue responsable des omissions, 
                  inexactitudes ou carences dans la mise à jour des informations, qu'elles soient de son fait ou de celui des tiers partenaires qui fournissent ces informations.
                </p>
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  L'utilisateur est seul responsable de l'utilisation qu'il fait des contenus présents sur le site. VGM Digital ne pourra être tenu responsable des dommages directs ou indirects causés à l'utilisateur ou à son matériel.
                </p>
              </div>
            </motion.section>

            {/* Section 5 - Politique de confidentialité */}
            <motion.section variants={itemVariants} className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm">
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Shield size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">5. Politique de confidentialité</h2>
              </div>
              
              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  VGM Digital s'engage à respecter la vie privée de ses utilisateurs. Pour plus de détails sur la collecte et l'utilisation de vos données personnelles, 
                  veuillez consulter notre{' '}
                  <Link to="/politique-confidentialite" className="text-blue-600 hover:underline">
                    Politique de confidentialité
                  </Link>.
                </p>
              </div>
            </motion.section>

            {/* Section 6 - Loi applicable */}
            <motion.section variants={itemVariants} className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm">
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Scale size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">6. Loi applicable</h2>
              </div>
              
              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  Les présentes mentions légales sont régies par la loi française. Tout litige relatif à l'utilisation du site sera soumis à la compétence exclusive des tribunaux français, 
                  dans le ressort du siège social de VGM Digital.
                </p>
              </div>
            </motion.section>

            {/* Section 7 - Contact */}
            <motion.section variants={itemVariants} className="bg-gray-50 rounded-xl p-5 sm:p-6 shadow-sm">
              <div className="flex items-center mb-3">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white mr-3">
                  <Mail size={20} />
                </div>
                <h2 className="text-lg sm:text-xl font-semibold">7. Contact</h2>
              </div>
              
              <div className="ml-12">
                <p className="text-sm sm:text-base text-gray-700 leading-relaxed">
                  Pour toute question relative au site ou aux mentions légales, vous pouvez nous contacter à :{' '}
                  <a href="mailto:contact@vgomcreation.fr" className="text-blue-600 hover:underline">
                    contact@vgomcreation.fr
                  </a>.
                </p>
              </div>
            </motion.section>
          </motion.div>
          
          {/* Élément de décoration en bas de page */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="w-full flex justify-center mt-10"
          >
            <div className="w-24 h-1 rounded-full bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default MentionsLegales;