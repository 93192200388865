import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle, User, Mail, CreditCard, FileText, AlertCircle, Home, Printer } from 'lucide-react';

const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const sessionId = searchParams.get('session_id');
      if (!sessionId) {
        setError('Aucun identifiant de session trouvé.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/projets/retrieve-session/${sessionId}`
        );

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des détails de la commande.');
        }

        const data = await response.json();
        setOrderDetails(data);
      } catch (err) {
        console.error('Erreur détails commande:', err);
        setError('Impossible de récupérer les détails de votre commande.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, [searchParams]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 300, damping: 24 }
    }
  };

  const printReceipt = () => {
    window.print();
  };

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-red-50 to-white flex items-center justify-center px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-md w-full bg-white p-8 rounded-2xl shadow-xl text-center"
        >
          <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-6">
            <AlertCircle className="text-red-500 w-8 h-8" />
          </div>
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Une erreur est survenue</h1>
          <p className="text-gray-600 mb-6">{error}</p>
          <button
            onClick={() => navigate('/')}
            className="px-6 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-900 transition flex items-center justify-center mx-auto"
          >
            <Home className="mr-2 w-4 h-4" />
            <span>Retour à l'accueil</span>
          </button>
        </motion.div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-green-50 to-white flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <div className="w-16 h-16 mx-auto relative">
            <div className="absolute inset-0 rounded-full border-4 border-green-100"></div>
            <div className="absolute inset-0 rounded-full border-4 border-green-500 border-t-transparent animate-spin"></div>
          </div>
          <p className="mt-6 text-lg text-gray-700 font-medium">Chargement des détails de votre commande...</p>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white py-12 px-4">
      <motion.div
        className="max-w-2xl mx-auto"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {/* En-tête */}
        <motion.div 
          className="text-center mb-12" 
          variants={itemVariants}
        >
          <div className="relative w-24 h-24 mx-auto mb-6">
            <div className="absolute inset-0 bg-green-100 rounded-full animate-pulse"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <CheckCircle className="text-green-500 w-14 h-14" />
            </div>
          </div>
          
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-4">Paiement réussi !</h1>
          <div className="w-24 h-1 bg-gradient-to-r from-green-300 to-green-500 mx-auto mb-4 rounded-full"></div>
          <p className="text-xl text-gray-600">
            Merci pour votre commande, <span className="font-semibold">{orderDetails.customer_name}</span> !
          </p>
        </motion.div>

        {/* Carte principale */}
        <motion.div 
          className="bg-white rounded-2xl shadow-lg overflow-hidden mb-8"
          variants={itemVariants}
        >
          <div className="p-1 bg-gradient-to-r from-green-300 to-green-500"></div>
          
          <div className="p-6 sm:p-8">
            <h2 className="text-xl font-semibold mb-6 text-gray-800 flex items-center">
              <FileText className="mr-2 text-green-500" />
              Détails de la transaction
            </h2>
            
            <div className="space-y-4">
              <motion.div 
                className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-300"
                variants={itemVariants}
              >
                <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                  <User className="text-gray-600 w-5 h-5" />
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase tracking-wider">Client</p>
                  <p className="text-gray-800 font-medium">{orderDetails.customer_name}</p>
                </div>
              </motion.div>

              <motion.div 
                className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-300"
                variants={itemVariants}
              >
                <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                  <Mail className="text-gray-600 w-5 h-5" />
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase tracking-wider">Email de confirmation</p>
                  <p className="text-gray-800 font-medium">{orderDetails.customer_email}</p>
                </div>
              </motion.div>

              <motion.div 
                className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-300"
                variants={itemVariants}
              >
                <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                  <CreditCard className="text-gray-600 w-5 h-5" />
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase tracking-wider">Montant payé</p>
                  <p className="text-gray-800 font-medium">
                    {(orderDetails.amount_total / 100).toFixed(2)} {orderDetails.currency.toUpperCase()}
                  </p>
                </div>
              </motion.div>

              <motion.div 
                className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-300"
                variants={itemVariants}
              >
                <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center">
                  <CheckCircle className="text-green-600 w-5 h-5" />
                </div>
                <div>
                  <p className="text-xs text-gray-500 uppercase tracking-wider">Statut</p>
                  <p className="text-green-600 font-medium">Commande validée</p>
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>

        {/* Message de confirmation email */}
        <motion.div 
          className="bg-blue-50 border border-blue-100 rounded-xl p-5 mb-8"
          variants={itemVariants}
        >
          <div className="flex gap-4">
            <div className="bg-blue-100 rounded-full h-10 w-10 flex-shrink-0 flex items-center justify-center">
              <Mail className="text-blue-600 w-5 h-5" />
            </div>
            <div>
              <h3 className="text-blue-800 font-medium mb-1">Confirmation envoyée</h3>
              <p className="text-blue-700 text-sm">
                Un email de confirmation avec votre facture a été envoyé à votre adresse email.
                Veuillez vérifier votre boîte de réception.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Boutons d'action */}
        <motion.div 
          className="flex flex-col sm:flex-row justify-center gap-4"
          variants={itemVariants}
        >
          <button
            onClick={() => navigate('/')}
            className="px-6 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-900 transition flex items-center justify-center"
          >
            <Home className="mr-2 w-4 h-4" />
            <span>Retour à l'accueil</span>
          </button>
          
          <button
            onClick={printReceipt}
            className="px-6 py-3 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition flex items-center justify-center"
          >
            <Printer className="mr-2 w-4 h-4" />
            <span>Imprimer reçu</span>
          </button>
        </motion.div>
        
        {/* Informations complémentaires */}
        <motion.div 
          className="mt-12 text-center text-sm text-gray-500"
          variants={itemVariants}
        >
          <p>Référence de transaction: <span className="font-mono">{orderDetails.payment_intent.substring(0, 16)}...</span></p>
          <p className="mt-1">Un conseiller va bientôt vous contacter pour les prochaines étapes.</p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SuccessPage;