import React, { useState, useEffect } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Eye,
  EyeOff,
  CheckCircle,
  Lock,
  AlertCircle,
  RefreshCw,
  ShieldCheck,
  ArrowRight,
} from 'lucide-react';
import { resetPassword } from '../api';

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const navigate = useNavigate();
  const location = useLocation();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const startCountdown = () => {
    // Nettoyer tout jeton d'authentification existant
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('user');
    setIsRedirecting(true);

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate('/login');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Récupérer le code depuis les paramètres d'URL
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  useEffect(() => {
    // Vérifier que le code est présent
    if (!code) {
      setError('Code de réinitialisation invalide ou expiré');
    }
  }, [code]);

  // Critères de sécurité du mot de passe
  const passwordCriteria = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /\d/.test(password),
    specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  };

  const allCriteriaMet = Object.values(passwordCriteria).every(Boolean);
  const passwordsMatch = password === confirmPassword && confirmPassword !== '';

  // Gestion du compte à rebours après succès
  useEffect(() => {
    if (success && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else if (success && countdown === 0) {
      navigate('/login');
    }
  }, [success, countdown, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      // Vérifier que le code est présent
      if (!code) {
        throw new Error('Code de réinitialisation invalide ou expiré');
      }

      // Vérifier que les mots de passe correspondent
      if (password !== confirmPassword) {
        throw new Error('Les mots de passe ne correspondent pas');
      }

      // Vérifier que le mot de passe respecte les critères
      if (!allCriteriaMet) {
        throw new Error(
          'Le mot de passe ne respecte pas tous les critères de sécurité'
        );
      }

      console.log('Tentative de réinitialisation avec code:', code);

      // Appel de l'API de réinitialisation
      const data = await resetPassword({
        code,
        password,
        passwordConfirmation: confirmPassword,
      });

      console.log('Réinitialisation réussie:', data);

      setSuccess(true);

      // Démarrer le compte à rebours pour la redirection
      startCountdown();
    } catch (err) {
      console.error('Erreur complète:', err);

      // Gestion des différents types d'erreurs
      if (err.response?.data?.error?.message) {
        setError(err.response.data.error.message);
      } else if (err.message) {
        setError(err.message);
      } else {
        setError(
          'Une erreur est survenue lors de la réinitialisation du mot de passe'
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 to-indigo-700 flex items-center justify-center p-4 mt-20 py-16">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative w-full max-w-md bg-white rounded-2xl shadow-xl overflow-hidden"
      >
        {/* Effet de design en arrière-plan */}
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
        <div className="absolute top-0 right-0 w-48 h-48 rounded-full bg-gradient-to-r from-blue-100 to-indigo-100 opacity-10 -mr-16 -mt-16"></div>

        <div className="relative px-6 pt-10 pb-8 sm:px-10">
          {/* En-tête avec animation */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.2 }}
            className="flex flex-col items-center mb-8"
          >
            <div className="bg-blue-600 text-white p-3 rounded-full shadow-lg">
              {success ? <CheckCircle size={28} /> : <Lock size={28} />}
            </div>
            <h2 className="mt-4 text-2xl sm:text-3xl font-bold text-gray-800 text-center">
              {success ? 'Mot de passe réinitialisé' : 'Nouveau mot de passe'}
            </h2>
            <p className="mt-2 text-sm sm:text-base text-gray-500 text-center max-w-sm">
              {success
                ? `Redirection dans ${countdown} secondes`
                : 'Créez un nouveau mot de passe sécurisé pour votre compte'}
            </p>
          </motion.div>

          {/* Message d'erreur avec animation */}
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-center p-4 mb-6 bg-red-50 border-l-4 border-red-500 rounded"
            >
              <AlertCircle
                size={20}
                className="text-red-500 mr-2 flex-shrink-0"
              />
              <p className="text-red-600 text-sm">{error}</p>
            </motion.div>
          )}

          {/* Message de succès */}
          {success ? (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-green-50 border-l-4 border-green-500 rounded p-5 text-center flex flex-col items-center"
            >
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
                <CheckCircle className="text-green-500 w-8 h-8" />
              </div>
              <h3 className="text-green-800 font-semibold text-lg mb-2">
                Mot de passe modifié avec succès !
              </h3>
              <p className="text-green-700">
                Vous allez être redirigé vers la page de connexion.
              </p>
              <div className="mt-4 w-full max-w-xs bg-gray-100 h-2 rounded-full overflow-hidden">
                <motion.div
                  initial={{ width: '100%' }}
                  animate={{ width: '0%' }}
                  transition={{ duration: 3, ease: 'linear' }}
                  className="h-full bg-green-500"
                />
              </div>
            </motion.div>
          ) : (
            <motion.form
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              onSubmit={handleSubmit}
              className="space-y-6"
            >
              {/* Nouveau mot de passe */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="space-y-2"
              >
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nouveau mot de passe
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock size={16} className="text-gray-400" />
                  </div>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="pl-10 block w-full border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 py-2.5 text-gray-900 bg-gray-50 pr-10"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
                  >
                    {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                  </button>
                </div>
              </motion.div>

              {/* Confirmer le mot de passe */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="space-y-2"
              >
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirmer le mot de passe
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <ShieldCheck size={16} className="text-gray-400" />
                  </div>
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className={`pl-10 block w-full border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 py-2.5 text-gray-900 bg-gray-50 pr-10 ${
                      confirmPassword && !passwordsMatch
                        ? 'border-red-300 ring-1 ring-red-300'
                        : ''
                    }`}
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
                  >
                    {showConfirmPassword ? (
                      <EyeOff size={18} />
                    ) : (
                      <Eye size={18} />
                    )}
                  </button>
                </div>
                {confirmPassword && !passwordsMatch && (
                  <p className="text-red-500 text-xs mt-1">
                    Les mots de passe ne correspondent pas
                  </p>
                )}
                {confirmPassword && passwordsMatch && (
                  <p className="text-green-500 text-xs mt-1 flex items-center">
                    <CheckCircle size={12} className="mr-1" /> Les mots de passe
                    correspondent
                  </p>
                )}
              </motion.div>

              {/* Critères du mot de passe */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
                className="mt-2 bg-gray-50 rounded-lg p-4 border border-gray-100"
              >
                <p className="text-xs font-medium text-gray-700 mb-2">
                  Votre mot de passe doit contenir :
                </p>
                <div className="grid grid-cols-1 gap-y-2">
                  <div
                    className={`flex items-center text-sm ${passwordCriteria.length ? 'text-green-600' : 'text-gray-500'}`}
                  >
                    <CheckCircle size={14} className="mr-2 flex-shrink-0" />
                    <span>Au moins 8 caractères</span>
                  </div>
                  <div
                    className={`flex items-center text-sm ${passwordCriteria.uppercase ? 'text-green-600' : 'text-gray-500'}`}
                  >
                    <CheckCircle size={14} className="mr-2 flex-shrink-0" />
                    <span>Une lettre majuscule</span>
                  </div>
                  <div
                    className={`flex items-center text-sm ${passwordCriteria.lowercase ? 'text-green-600' : 'text-gray-500'}`}
                  >
                    <CheckCircle size={14} className="mr-2 flex-shrink-0" />
                    <span>Une lettre minuscule</span>
                  </div>
                  <div
                    className={`flex items-center text-sm ${passwordCriteria.number ? 'text-green-600' : 'text-gray-500'}`}
                  >
                    <CheckCircle size={14} className="mr-2 flex-shrink-0" />
                    <span>Un chiffre</span>
                  </div>
                  <div
                    className={`flex items-center text-sm ${passwordCriteria.specialChar ? 'text-green-600' : 'text-gray-500'}`}
                  >
                    <CheckCircle size={14} className="mr-2 flex-shrink-0" />
                    <span>Un caractère spécial (!@#$%^&*)</span>
                  </div>
                </div>
              </motion.div>

              {/* Bouton de soumission */}
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.7 }}
                type="submit"
                disabled={!allCriteriaMet || !passwordsMatch || isLoading}
                className={`relative w-full py-3 px-4 flex items-center justify-center ${
                  allCriteriaMet && passwordsMatch
                    ? 'bg-blue-600 hover:bg-blue-700'
                    : 'bg-gray-400'
                } focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-white font-medium rounded-lg transition-all duration-300 overflow-hidden group`}
              >
                <span className="inline-flex items-center relative z-10">
                  {isLoading ? (
                    <>
                      <RefreshCw size={18} className="animate-spin mr-2" />
                      <span>Réinitialisation en cours...</span>
                    </>
                  ) : (
                    <>
                      <Lock size={18} className="mr-2" />
                      <span>Réinitialiser le mot de passe</span>
                    </>
                  )}
                </span>
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </motion.button>
            </motion.form>
          )}

          {/* Lien vers la connexion */}
          {!success && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.8 }}
              className="mt-8 text-center"
            >
              <button
                onClick={() => navigate('/login')}
                className="inline-flex items-center font-medium text-blue-600 hover:text-blue-800 hover:underline transition-colors"
              >
                Retour à la connexion
                <ArrowRight
                  size={14}
                  className="ml-1 group-hover:translate-x-1 transition-transform"
                />
              </button>
            </motion.div>
          )}
        </div>
      </motion.div>
    </div>
  );
}

export default ResetPassword;
