import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  Eye,
  EyeOff,
  CheckCircle,
  User,
  Mail,
  RefreshCw,
  AlertCircle,
  Lock,
  ArrowRight,
} from 'lucide-react';
import GoogleLogin from '../components/GoogleLogin';
import FacebookLogin from '../components/FacebookLogin';
import { useNavigate } from 'react-router-dom';
import { register } from '../api';

function Inscription() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const navigate = useNavigate();

  const passwordCriteria = {
    length: formData.password.length >= 8,
    uppercase: /[A-Z]/.test(formData.password),
    lowercase: /[a-z]/.test(formData.password),
    number: /\d/.test(formData.password),
    specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(formData.password),
  };

  const allCriteriaMet = Object.values(passwordCriteria).every(Boolean);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    if (!allCriteriaMet) {
      setError('Le mot de passe ne respecte pas tous les critères.');
      setIsLoading(false);
      return;
    }

    if (!termsAccepted) {
      setError(
        "Vous devez accepter les conditions d'utilisation et la politique de confidentialité."
      );
      setIsLoading(false);
      return;
    }

    try {
      const response = await register(formData);
      if (response) {
        setSuccess(true);
        setEmailSent(true);
        setIsLoading(false);
        setTimeout(() => {
          navigate('/email-verification-prompt');
        }, 2000);
      }
    } catch (err) {
      setError(
        err.response?.data?.error?.message || "Erreur lors de l'inscription"
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 to-indigo-700 flex items-center justify-center p-4 py-16 md:py-20 mt-20">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="relative w-full max-w-xl bg-white rounded-2xl shadow-xl overflow-hidden"
      >
        {/* Effet de design en arrière-plan */}
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
        <div className="absolute -top-20 -right-20 w-64 h-64 rounded-full bg-gradient-to-r from-blue-100 to-indigo-100 opacity-10"></div>
        <div className="absolute -bottom-16 -left-16 w-48 h-48 rounded-full bg-gradient-to-r from-indigo-100 to-purple-100 opacity-10"></div>

        <div className="relative px-6 pt-10 pb-8 sm:px-10">
          {/* En-tête avec animation */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0.2 }}
            className="flex flex-col items-center mb-8"
          >
            <div className="bg-blue-600 text-white p-3 rounded-full shadow-lg">
              {success ? <CheckCircle size={28} /> : <User size={28} />}
            </div>
            <h2 className="mt-4 text-2xl sm:text-3xl font-bold text-gray-800 text-center">
              {success ? 'Inscription réussie' : 'Créer un compte'}
            </h2>
            <p className="mt-2 text-sm sm:text-base text-gray-500 text-center max-w-sm">
              {success
                ? 'Un email de confirmation vous a été envoyé'
                : "Rejoignez-nous en créant votre compte. C'est rapide et facile !"}
            </p>
          </motion.div>

          {/* Message d'erreur avec animation */}
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-center p-4 mb-6 bg-red-50 border-l-4 border-red-500 rounded"
            >
              <AlertCircle
                size={20}
                className="text-red-500 mr-2 flex-shrink-0"
              />
              <p className="text-red-600 text-sm">{error}</p>
            </motion.div>
          )}

          {/* Message de succès avec animation */}
          {success && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-center justify-center p-4 mb-6 bg-green-50 border-l-4 border-green-500 rounded"
            >
              <CheckCircle size={20} className="text-green-500 mr-2" />
              <span className="text-green-600 text-sm">
                Inscription réussie !
              </span>
            </motion.div>
          )}

          {/* Indication d'email envoyé */}
          {emailSent && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="flex flex-col items-center justify-center p-4 mb-6 bg-blue-50 border-l-4 border-blue-500 rounded text-center"
            >
              <Mail size={24} className="text-blue-500 mb-2" />
              <p className="text-blue-700 text-sm">
                Un email de confirmation a été envoyé à votre adresse.
              </p>
              <p className="text-blue-600 text-sm mt-1">
                Veuillez vérifier votre boîte de réception pour activer votre
                compte.
              </p>
            </motion.div>
          )}

          {/* Formulaire avec animation séquentielle des champs */}
          {!success && !emailSent && (
            <motion.form
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              onSubmit={handleRegister}
              className="space-y-5"
            >
              {/* Nom et prénom sur la même ligne */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="grid grid-cols-1 sm:grid-cols-2 gap-4"
              >
                <div className="space-y-2">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Prénom
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User size={16} className="text-gray-400" />
                    </div>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                      className="pl-10 block w-full border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 py-2.5 text-gray-900 bg-gray-50"
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nom
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User size={16} className="text-gray-400" />
                    </div>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                      className="pl-10 block w-full border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 py-2.5 text-gray-900 bg-gray-50"
                    />
                  </div>
                </div>
              </motion.div>

              {/* Nom d'utilisateur */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="space-y-2"
              >
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom d'utilisateur
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User size={16} className="text-gray-400" />
                  </div>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                    className="pl-10 block w-full border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 py-2.5 text-gray-900 bg-gray-50"
                  />
                </div>
              </motion.div>

              {/* Email */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
                className="space-y-2"
              >
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  E-mail
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail size={16} className="text-gray-400" />
                  </div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="pl-10 block w-full border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 py-2.5 text-gray-900 bg-gray-50"
                  />
                </div>
              </motion.div>

              {/* Mot de passe */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.7 }}
                className="space-y-2"
              >
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mot de passe
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock size={16} className="text-gray-400" />
                  </div>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    className="pl-10 block w-full border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 py-2.5 text-gray-900 bg-gray-50 pr-10"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
                  >
                    {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                  </button>
                </div>

                {/* Critères du mot de passe */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.9 }}
                  className="mt-2 bg-gray-50 rounded-lg p-4 border border-gray-100"
                >
                  <p className="text-xs font-medium text-gray-700 mb-2">
                    Le mot de passe doit contenir :
                  </p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-4">
                    <div
                      className={`flex items-center text-sm ${passwordCriteria.length ? 'text-green-600' : 'text-gray-500'}`}
                    >
                      <CheckCircle size={14} className="mr-2 flex-shrink-0" />
                      <span>Au moins 8 caractères</span>
                    </div>
                    <div
                      className={`flex items-center text-sm ${passwordCriteria.uppercase ? 'text-green-600' : 'text-gray-500'}`}
                    >
                      <CheckCircle size={14} className="mr-2 flex-shrink-0" />
                      <span>Une lettre majuscule</span>
                    </div>
                    <div
                      className={`flex items-center text-sm ${passwordCriteria.lowercase ? 'text-green-600' : 'text-gray-500'}`}
                    >
                      <CheckCircle size={14} className="mr-2 flex-shrink-0" />
                      <span>Une lettre minuscule</span>
                    </div>
                    <div
                      className={`flex items-center text-sm ${passwordCriteria.number ? 'text-green-600' : 'text-gray-500'}`}
                    >
                      <CheckCircle size={14} className="mr-2 flex-shrink-0" />
                      <span>Un chiffre</span>
                    </div>
                    <div
                      className={`flex items-center text-sm ${passwordCriteria.specialChar ? 'text-green-600' : 'text-gray-500'}`}
                    >
                      <CheckCircle size={14} className="mr-2 flex-shrink-0" />
                      <span>Un caractère spécial</span>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
              {/* Case à cocher des conditions d'utilisation */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.75 }}
                className="mt-5 bg-gray-50 rounded-lg p-4 border border-gray-100"
              >
                <div className="flex items-start">
                  <div className="flex items-center h-5 pt-0.5">
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      checked={termsAccepted}
                      onChange={() => setTermsAccepted(!termsAccepted)}
                      className="focus:ring-2 focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="terms" className="text-gray-700">
                      En créant un compte, j'accepte les{' '}
                      <a
                        href="/cgu"
                        className="text-blue-600 hover:text-blue-800 hover:underline font-medium"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        conditions d'utilisation
                      </a>{' '}
                      et la{' '}
                      <a
                        href="/politique-confidentialite"
                        className="text-blue-600 hover:text-blue-800 hover:underline font-medium"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        politique de confidentialité
                      </a>{' '}
                      de VGom Creation.
                    </label>
                    {!termsAccepted && formData.password && allCriteriaMet && (
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="mt-1 text-xs text-amber-600"
                      >
                        Vous devez accepter les conditions pour continuer
                      </motion.p>
                    )}
                  </div>
                </div>
              </motion.div>

              {/* Bouton S'inscrire */}
              <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.8 }}
                type="submit"
                disabled={!allCriteriaMet || isLoading}
                className={`relative w-full py-3 px-4 flex items-center justify-center ${
                  allCriteriaMet
                    ? 'bg-blue-600 hover:bg-blue-700'
                    : 'bg-gray-400'
                } focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-white font-medium rounded-lg transition-all duration-300 overflow-hidden group`}
              >
                <span className="inline-flex items-center relative z-10">
                  {isLoading ? (
                    <>
                      <RefreshCw size={18} className="animate-spin mr-2" />
                      <span>Inscription en cours...</span>
                    </>
                  ) : (
                    <>
                      <User size={18} className="mr-2" />
                      <span>S'inscrire</span>
                    </>
                  )}
                </span>
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-indigo-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </motion.button>
            </motion.form>
          )}

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.65 }}
            className="relative flex py-5 items-center my-4"
          >
            <div className="flex-grow border-t border-gray-200"></div>
            <span className="flex-shrink mx-4 text-gray-400 text-sm font-medium">
              Ou continuer avec
            </span>
            <div className="flex-grow border-t border-gray-200"></div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.7 }}
            className="flex gap-3 justify-center flex-wrap md:flex-nowrap"
          >
            {/* Bouton Google simplifié */}
            <div className="w-full md:w-1/2">
              <GoogleLogin isRegistration={false} />
            </div>

            {/* Bouton Facebook simplifié */}
            <div className="w-full md:w-1/2">
              <FacebookLogin isRegistration={false} />
            </div>
          </motion.div>

          {/* Lien de connexion */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.0 }}
            className="mt-8 text-center"
          >
            <p className="text-sm text-gray-600">
              Déjà un compte ?{' '}
              <button
                onClick={() => navigate('/login')}
                className="inline-flex items-center font-medium text-blue-600 hover:text-blue-800 hover:underline transition-colors"
              >
                Se connecter
                <ArrowRight
                  size={14}
                  className="ml-1 group-hover:translate-x-1 transition-transform"
                />
              </button>
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}

export default Inscription;
