import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../context/UserContext';
import { CheckCircle, AlertCircle, RefreshCw } from 'react-feather';

const API_URL = process.env.REACT_APP_API_URL || 'https://vgomcreation-api.onrender.com';

function GoogleCallback() {
  const navigate = useNavigate();
  const location = useLocation();
  const { loginUser } = useContext(UserContext);
  const [status, setStatus] = useState('loading'); // loading, success, error
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const accessToken = urlParams.get('access_token');
    const error = urlParams.get('error');

    // Si une erreur est présente dans l'URL
    if (error) {
      console.error('Erreur de connexion Google:', error);
      setStatus('error');
      setErrorMessage('La connexion avec Google a échoué. Veuillez réessayer.');
      
      // Redirection après délai
      setTimeout(() => {
        navigate('/login');
      }, 3000);
      return;
    }

    // Si pas de token, rediriger vers la page login
    if (!accessToken) {
      console.error('Token Google manquant');
      setStatus('error');
      setErrorMessage('Informations d\'authentification manquantes.');
      
      // Redirection après délai
      setTimeout(() => {
        navigate('/login');
      }, 3000);
      return;
    }

    // Authentification avec le token Google
    const completeGoogleAuth = async () => {
      try {
        console.log('Authentification avec token Google:', accessToken);
        
        // Appel au backend Strapi pour valider le token
        const response = await axios.get(`${API_URL}/api/auth/google/callback`, {
          params: { access_token: accessToken },
        });

        console.log('Réponse du serveur:', response.data);

        if (response.data?.jwt && response.data?.user) {
          // Stocker les informations utilisateur
          loginUser(response.data.user, response.data.jwt, true);
          setStatus('success');
          
          // Redirection après un délai
          setTimeout(() => {
            navigate('/');
          }, 1500);
        } else {
          throw new Error('Données utilisateur incomplètes');
        }
      } catch (error) {
        console.error('Erreur lors de l\'authentification Google:', error);
        setStatus('error');
        setErrorMessage(
          error.response?.data?.error?.message || 
          'Une erreur est survenue lors de l\'authentification Google. Veuillez réessayer.'
        );
        
        // Redirection après délai
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    };

    completeGoogleAuth();
  }, [navigate, loginUser, location.search]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 to-indigo-700 flex items-center justify-center p-4 py-16 mt-12 md:py-20 md:mt-20">
      <div className="relative w-full max-w-md bg-white rounded-2xl shadow-xl overflow-hidden p-8">
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600"></div>
        
        <div className="flex flex-col items-center justify-center">
          {status === 'loading' && (
            <>
              <div className="w-16 h-16 flex items-center justify-center bg-blue-100 rounded-full mb-4">
                <RefreshCw size={32} className="text-blue-600 animate-spin" />
              </div>
              <h2 className="text-2xl font-bold text-gray-800 mb-2">Connexion en cours</h2>
              <p className="text-gray-600 text-center">
                Nous finalisons votre connexion avec Google...
              </p>
            </>
          )}
          
          {status === 'success' && (
            <>
              <div className="w-16 h-16 flex items-center justify-center bg-green-100 rounded-full mb-4">
                <CheckCircle size={32} className="text-green-600" />
              </div>
              <h2 className="text-2xl font-bold text-gray-800 mb-2">Connexion réussie !</h2>
              <p className="text-gray-600 text-center">
                Vous êtes maintenant connecté. Redirection en cours...
              </p>
            </>
          )}
          
          {status === 'error' && (
            <>
              <div className="w-16 h-16 flex items-center justify-center bg-red-100 rounded-full mb-4">
                <AlertCircle size={32} className="text-red-600" />
              </div>
              <h2 className="text-2xl font-bold text-gray-800 mb-2">Erreur de connexion</h2>
              <p className="text-red-600 text-center">
                {errorMessage}
              </p>
              <button 
                className="mt-6 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                onClick={() => navigate('/login')}
              >
                Retour à la connexion
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default GoogleCallback;