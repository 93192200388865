import React, { useContext, createContext, useState, useEffect } from 'react';
import { UserContext } from './UserContext';
import {
  toggleArticleLike,
  addComment,
  getComments,
  incrementView,
  API_URL,
} from '../api';

export const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { jwt, user } = useContext(UserContext);

  // Fonction pour vérifier si une vue a expiré
  const isViewExpired = (viewTimestamp) => {
    const VIEW_EXPIRY_HOURS = 24;
    const now = new Date().getTime();
    return now - viewTimestamp > VIEW_EXPIRY_HOURS * 60 * 60 * 1000;
  };

  // Charger les articles au montage du composant
  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/api/blogs?populate=*`);
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des articles');
        }
        const data = await response.json();

        // Récupérer les likes stockés avec l'ID de l'utilisateur
        const storedLikes =
          jwt && user
            ? JSON.parse(localStorage.getItem(`likedArticles_${user.id}`)) || {}
            : {};

        // Ajouter l'état hasLiked à chaque article
        const articlesWithLikes = data.data.map((article) => ({
          ...article,
          hasLiked: jwt && user ? storedLikes[article.id] || false : false,
        }));

        setArticles(articlesWithLikes);
        setError(null);
      } catch (err) {
        console.error('Erreur lors du chargement des articles:', err);
        setError('Impossible de charger les articles.');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [jwt, user]);

  // Afficher les articles mis à jour
  useEffect(() => {
    console.log('Articles mis à jour dans le contexte :', articles);
  }, [articles]);

  // Fonction pour mettre à jour le compteur de vues d'un article et stocker la vue dans le localStorage
  const handleUpdateViewCount = async (articleId) => {
    try {
      // Générer une clé unique basée sur l'ID utilisateur ou une session anonyme
      const visitorId = user?.id || 'anonymous_' + (localStorage.getItem('anonymousId') || Math.random().toString(36).substr(2, 9));
      
      // Stocker l'ID anonyme si nécessaire
      if (!user) {
        localStorage.setItem('anonymousId', visitorId.split('_')[1]);
      }
  
      const storageKey = `viewedArticles_${visitorId}`;
      const viewedArticles = JSON.parse(localStorage.getItem(storageKey)) || {};
      const lastView = viewedArticles[articleId];
  
      // Vérifier si c'est une première visite ou si 24h se sont écoulées
      if (!lastView || isViewExpired(lastView)) {
        const newViewCount = await incrementView(articleId);
        
        if (newViewCount !== null) {
          // Mettre à jour le compteur dans l'état
          setArticles(prevArticles =>
            prevArticles.map(article =>
              article.id === articleId 
                ? { ...article, vue_count: newViewCount }
                : article
            )
          );
  
          // Enregistrer la nouvelle vue
          viewedArticles[articleId] = new Date().getTime();
          localStorage.setItem(storageKey, JSON.stringify(viewedArticles));
          
          console.log(`Nouvelle vue pour l'article ${articleId}:`, newViewCount);
        }
      } else {
        console.log(`Vue ignorée pour l'article ${articleId} - moins de 24h`);
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour des vues:', error);
    }
  };

  // Fonction pour gérer le toggle du like
  const handleLikeToggle = async (articleId) => {
    // Vérifier si l'utilisateur est connecté
    if (!jwt || !user) {
      alert('Veuillez vous connecter pour aimer cet article.');
      return;
    }

    try {
      const response = await toggleArticleLike(articleId);

      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article.id === articleId
            ? {
                ...article,
                likes_count: response.data.likes_count,
                hasLiked: response.data.hasLiked,
              }
            : article
        )
      );
      setLikeInLocalStorage(articleId, response.data.hasLiked);
    } catch (error) {
      console.error('Erreur lors du toggle du like:', error);
    }
  };

  // Fonction pour sauvegarder le like dans le localStorage
  const setLikeInLocalStorage = (articleId, hasLiked) => {
    if (!user) return;

    const storedLikes =
      JSON.parse(localStorage.getItem(`likedArticles_${user.id}`)) || {};
    storedLikes[articleId] = hasLiked;
    localStorage.setItem(
      `likedArticles_${user.id}`,
      JSON.stringify(storedLikes)
    );
  };

  // Fonction pour ajouter un commentaire
  const setCommentCountInLocalStorage = (articleId, commentCount) => {
    const storedComments =
      JSON.parse(localStorage.getItem('commentCounts')) || {};
    storedComments[articleId] = commentCount;
    localStorage.setItem('commentCounts', JSON.stringify(storedComments));
  };

  // Fonction pour ajouter un commentaire
  const handleAddComment = async (articleId, commentData) => {
    if (!jwt) {
      console.error('Vous devez être connecté pour ajouter un commentaire.');
      alert('Veuillez vous connecter pour ajouter un commentaire.');
      return;
    }

    try {
      const newComment = await addComment(articleId, commentData, jwt);
      console.log('Nouveau commentaire créé :', newComment);

      const updatedComments = await getComments(articleId);
      console.log('Commentaires mis à jour :', updatedComments);

      const updatedCommentCount = updatedComments.length;

      setArticles((prevArticles) =>
        prevArticles.map((a) =>
          a.id === articleId
            ? {
                ...a,
                commentaire_count: updatedCommentCount,
                comments: updatedComments,
              }
            : a
        )
      );

      setCommentCountInLocalStorage(articleId, updatedCommentCount);
    } catch (error) {
      console.error(
        'Erreur lors de l’ajout du commentaire:',
        error.response?.data || error.message
      );
    }
  };

  // Fonction pour récupérer les commentaires
  const updateCommentCount = (articleId, newCount) => {
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === articleId
          ? {
              ...article,
              commentaire_count: newCount,
            }
          : article
      )
    );
  };

  return (
    <BlogContext.Provider
      value={{
        articles,
        loading,
        error,
        handleUpdateViewCount,
        handleLikeToggle,
        handleAddComment,
        getComments,
        updateCommentCount,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};
