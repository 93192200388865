import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Home, ArrowLeft, RefreshCw } from "lucide-react";

const Error404 = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-16 md:py-20 mt-20 bg-gradient-to-b from-blue-900 to-gray-900 text-gray-100 px-4">
      <div className="max-w-3xl w-full mx-auto">
        {/* Élément décoratif */}
        <motion.div 
          className="absolute top-10 right-10 opacity-20"
          animate={{ 
            rotate: 360,
          }} 
          transition={{ 
            duration: 60, 
            repeat: Infinity, 
            ease: "linear" 
          }}
        >
          <div className="w-64 h-64 rounded-full bg-gradient-to-r from-blue-400 to-purple-600 blur-3xl" />
        </motion.div>

        <div className="relative z-10">
          {/* En-tête animé */}
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            className="text-center"
          >
            <div className="relative inline-block">
              <motion.div 
                className="text-8xl sm:text-9xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-violet-500 to-purple-600 mb-4"
                animate={{ 
                  textShadow: ["0 0 5px rgba(167, 139, 250, 0.3)", "0 0 15px rgba(167, 139, 250, 0.5)", "0 0 5px rgba(167, 139, 250, 0.3)"]
                }}
                transition={{ 
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
              >
                404
              </motion.div>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ 
                  delay: 0.5,
                  type: "spring",
                  stiffness: 300,
                  damping: 15
                }}
                className="absolute -top-6 -right-6 bg-red-500 text-white text-sm font-bold rounded-full w-12 h-12 flex items-center justify-center transform rotate-12"
              >
                Oups!
              </motion.div>
            </div>
            
            <motion.h2 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.7 }}
              className="text-xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 text-white"
            >
              Cette page semble avoir disparu
            </motion.h2>
            
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.7 }}
              className="text-sm sm:text-base md:text-lg text-gray-300 mb-6 sm:mb-8 max-w-lg mx-auto"
            >
              La page que vous cherchez n'existe plus ou a été déplacée. 
              Pas d'inquiétude, vous pouvez toujours retourner à l'accueil.
            </motion.p>
          </motion.div>

          {/* Illustration animée */}
          <motion.div 
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2, duration: 0.8 }}
            className="my-8 flex justify-center"
          >
            <div className="relative w-64 h-64 sm:w-72 sm:h-72">
              {/* Cercle décoratif */}
              <motion.div 
                className="absolute inset-0 rounded-full border-4 border-blue-400 opacity-20"
                animate={{ scale: [1, 1.05, 1] }}
                transition={{ duration: 3, repeat: Infinity }}
              />
              
              {/* Planète */}
              <div className="absolute inset-4 rounded-full bg-gradient-to-br from-blue-600 to-blue-900 shadow-xl">
                <div className="absolute top-6 left-8 w-10 h-10 rounded-full bg-blue-300 opacity-30"></div>
                <div className="absolute bottom-8 right-12 w-6 h-6 rounded-full bg-blue-300 opacity-20"></div>
              </div>
              
              {/* Satellite */}
              <motion.div
                className="absolute w-10 h-10 rounded-full bg-violet-500"
                style={{ top: '30%', left: '10%' }}
                animate={{ 
                  x: [0, 160, 0], 
                  y: [0, 80, 0],
                  scale: [1, 0.8, 1],
                }}
                transition={{ 
                  duration: 15, 
                  repeat: Infinity, 
                  ease: "easeInOut" 
                }}
              />
              
              {/* Étoiles */}
              <div className="absolute inset-0 overflow-hidden">
                {[...Array(20)].map((_, i) => (
                  <motion.div
                    key={i}
                    className="absolute w-1 h-1 bg-white rounded-full"
                    style={{
                      top: `${Math.random() * 100}%`,
                      left: `${Math.random() * 100}%`,
                    }}
                    animate={{ 
                      opacity: [0.2, 0.8, 0.2],
                      scale: [1, 1.5, 1]
                    }}
                    transition={{ 
                      duration: 2 + Math.random() * 3,
                      repeat: Infinity,
                      delay: Math.random() * 2
                    }}
                  />
                ))}
              </div>
              
              {/* Météorite (404) */}
              <motion.div
                className="absolute flex items-center justify-center w-16 h-16 rounded-md bg-gradient-to-br from-red-500 to-purple-600 shadow-lg text-white font-bold"
                style={{ bottom: '20%', right: '15%', rotate: 15 }}
                animate={{ 
                  rotate: [15, -5, 15], 
                  y: [0, -10, 0],
                }}
                transition={{ 
                  duration: 4, 
                  repeat: Infinity, 
                  ease: "easeInOut" 
                }}
              >
                404
              </motion.div>
            </div>
          </motion.div>

          {/* Boutons d'action */}
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7, duration: 0.5 }}
            className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4 mt-8"
          >
            <Link
              to="/"
              className="group px-6 py-3 bg-gradient-to-r from-blue-500 to-violet-600 hover:from-blue-600 hover:to-violet-700 text-gray-100 rounded-lg shadow-lg transition duration-300 flex items-center justify-center w-full sm:w-auto"
            >
              <Home className="w-5 h-5 mr-2 group-hover:-translate-y-1 transition-transform" />
              <span>Retour à l'accueil</span>
            </Link>
            
            <button
              onClick={() => window.history.back()}
              className="px-6 py-3 bg-gray-700 hover:bg-gray-600 text-gray-100 rounded-lg shadow-lg transition duration-300 flex items-center justify-center w-full sm:w-auto"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              <span>Page précédente</span>
            </button>
          </motion.div>

          {/* Suggestions supplémentaires */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.7 }}
            className="mt-12 text-center"
          >
            <p className="text-gray-400 text-sm mb-3">Vous pouvez également essayer :</p>
            <div className="flex flex-wrap justify-center gap-2 text-xs">
              <Link to="/contact" className="px-3 py-1 bg-gray-800 hover:bg-gray-700 rounded-full transition text-gray-300 hover:text-white">
                Nous contacter
              </Link>
              <Link to="/blog" className="px-3 py-1 bg-gray-800 hover:bg-gray-700 rounded-full transition text-gray-300 hover:text-white">
                Visiter le blog
              </Link>
              <Link to="/nos-offres" className="px-3 py-1 bg-gray-800 hover:bg-gray-700 rounded-full transition text-gray-300 hover:text-white">
                Voir nos offres
              </Link>
              <button 
                onClick={() => window.location.reload()} 
                className="px-3 py-1 bg-gray-800 hover:bg-gray-700 rounded-full transition text-gray-300 hover:text-white flex items-center gap-1"
              >
                <RefreshCw className="w-3 h-3" /> 
                Actualiser la page
              </button>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Error404;