import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Code, Monitor, Users, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const images = [
    {
      src: '/images/1.webp',
      alt: 'Bureau de Vincent',
    },
    {
      src: '/images/2.webp',
      alt: 'Poste de travail',
    },
    {
      src: '/images/3.webp',
      alt: 'Projet Géo Métrics 3D',
    },
  ];

  // Changement automatique des slides avec gestion de la transition
  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setIsVisible(false);
      setTimeout(() => {
        setCurrentSlide((prev) => (prev + 1) % images.length);
        setIsVisible(true);
        setTimeout(() => {
          setIsTransitioning(false);
        }, 500);
      }, 500);
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="relative w-full h-[700px] sm:h-[650px] overflow-hidden bg-black">
      {/* Background image with animated fade transitions */}
      <div className="absolute inset-0 w-full h-full">
        {images.map((image, index) => (
          <div
          key={index}
          className={`absolute inset-0 transition-all duration-1000 ${
            index === currentSlide 
            ? 'opacity-100 brightness-100' 
            : 'opacity-0 brightness-125'
          }`}
          style={{
            filter: index === currentSlide ? 'brightness(100%)' : 'brightness(125%)',
            transition: 'opacity 1000ms, filter 1000ms'
          }}
        >
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-full object-cover object-left"
            />
            {/* Dégradé plus foncé et uniforme sur mobile pour meilleure lisibilité */}
            <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/60 to-black/40"></div>
          </div>
        ))}
      </div>

      {/* Content overlay - repositionné pour mobile */}
      <div className="relative z-10 h-full container mx-auto px-4 flex flex-col justify-center sm:mt-0 md:mt-10">
        <div className="max-w-3xl">
          {/* Badge - taille réduite sur mobile */}
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.2 }}
            className="inline-block bg-blue-600 text-white px-2 py-1 sm:px-4 sm:py-1.5 rounded-full text-[10px] sm:text-xs font-bold tracking-wide mb-6"
          >
            DÉVELOPPEMENT WEB PROFESSIONNEL
          </motion.div>

          {/* Main heading - plus compact sur mobile */}
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.4 }}
            className="text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-3 sm:mb-6 leading-tight"
          >
            Création de Sites Web
            <span className="text-blue-400 block mt-1">Sur Mesure</span>
          </motion.h1>

          {/* Description - plus courte sur mobile */}
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.6 }}
            className="text-sm sm:text-lg md:text-xl text-gray-100 mb-4 sm:mb-8 max-w-xl"
          >
            Solutions web pour entreprises et associations
          </motion.p>

          {/* Key features - disposés verticalement sur mobile pour gagner de la place */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.8 }}
            className="flex flex-col sm:flex-row flex-wrap gap-2 sm:gap-4 mb-8 items-start sm:items-center"
          >
            <div className="inline-flex items-center bg-white/10 backdrop-blur-sm px-3 py-1 sm:px-4 sm:py-2 rounded-full w-auto">
              <Monitor
                size={14}
                className="text-blue-400 mr-1 sm:mr-2 flex-shrink-0"
              />
              <span className="text-white text-xs sm:text-sm whitespace-nowrap">
                Design Moderne
              </span>
            </div>

            <div className="inline-flex items-center bg-white/10 backdrop-blur-sm px-3 py-1 sm:px-4 sm:py-2 rounded-full w-auto">
              <Code
                size={14}
                className="text-blue-400 mr-1 sm:mr-2 flex-shrink-0"
              />
              <span className="text-white text-xs sm:text-sm whitespace-nowrap">
                Développement sur mesure
              </span>
            </div>

            <div className="inline-flex items-center bg-white/10 backdrop-blur-sm px-3 py-1 sm:px-4 sm:py-2 rounded-full w-auto">
              <Users
                size={14}
                className="text-blue-400 mr-1 sm:mr-2 flex-shrink-0"
              />
              <span className="text-white text-xs sm:text-sm whitespace-nowrap">
                Support dédié
              </span>
            </div>
          </motion.div>

          {/* CTA buttons - boutons plus petits sur mobile */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 1.0 }}
            className="flex flex-col sm:flex-row flex-wrap gap-2 sm:gap-4"
          >
            <a
              href="https://forms.gle/Wqq9dcyKvvYXVtKM6"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 sm:px-6 sm:py-3 rounded-lg font-medium transition-colors group w-full sm:w-auto text-sm sm:text-base"
            >
              <span>Demander un devis gratuit</span>
              <ArrowRight
                size={14}
                className="ml-2 group-hover:translate-x-1 transition-transform"
              />
            </a>

            <Link
              to="/galerie"
              className="inline-flex items-center justify-center bg-white/10 hover:bg-white/20 text-white px-4 py-2 sm:px-6 sm:py-3 rounded-lg backdrop-blur-sm font-medium transition-colors group w-full sm:w-auto text-sm sm:text-base"
            >
              <span>Voir nos réalisations</span>
              <ChevronRight
                size={14}
                className="ml-1 group-hover:translate-x-1 transition-transform"
              />
            </Link>
          </motion.div>

          {/* Trust indicators - plus compact sur mobile */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1.2 }}
            className="mt-4 sm:mt-8 py-1 px-2 sm:py-2 sm:px-4 bg-white/10 backdrop-blur-sm rounded-lg inline-block"
          >
            <p className="text-white text-[10px] sm:text-sm flex items-center flex-wrap">
              <span className="font-bold text-blue-300 mr-1 sm:mr-2">
                Devis gratuit
              </span>
              sous 24h • Accompagnement personnalisé
            </p>
          </motion.div>
        </div>
      </div>

      {/* Slide indicators - plus petits et moins intrusifs sur mobile */}
      <div className="absolute bottom-2 sm:bottom-6 right-2 sm:right-8 flex space-x-1 sm:space-x-2 z-10">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              if (isTransitioning) return;
              setIsTransitioning(true);
              setIsVisible(false);
              setTimeout(() => {
                setCurrentSlide(index);
                setIsVisible(true);
                setTimeout(() => {
                  setIsTransitioning(false);
                }, 500);
              }, 500);
            }}
            className={`w-1.5 h-1.5 sm:w-2.5 sm:h-2.5 rounded-full transition-all duration-300 ${
              index === currentSlide
                ? 'bg-blue-500 w-4 sm:w-8'
                : 'bg-white/50 hover:bg-white/80'
            }`}
            aria-label={`Voir l'image ${index + 1}`}
            disabled={isTransitioning}
          />
        ))}
      </div>
    </div>
  );
};

export default Banner;
