import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import { RefreshCw } from 'lucide-react';
import { motion } from 'framer-motion';
import facebookLogo from '../assets/facebook-logo.png';  // Vous devrez ajouter ce logo

const API_URL = process.env.REACT_APP_API_URL || 'https://vgomcreation-api.onrender.com';

const FacebookLogin = ({ isRegistration = false }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [showTerms, setShowTerms] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { loginUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Fonction pour traiter la redirection de Facebook
  useEffect(() => {
    const processFacebookCallback = async () => {
      // Vérifier si on est sur la page de callback avec le token
      const urlParams = new URLSearchParams(location.search);
      const accessToken = urlParams.get('access_token');
      
      if (accessToken) {
        setIsProcessing(true);
        
        try {
          // Récupérer les informations utilisateur avec le token
          const response = await axios.get(`${API_URL}/api/auth/facebook/callback?access_token=${accessToken}`);
          
          console.log("Données utilisateur Facebook reçues:", response.data);
          
          if (response.data && response.data.user && response.data.jwt) {
            // Vérifier si l'utilisateur est nouveau (inscription)
            const isNewUser = response.data.user.createdAt === response.data.user.updatedAt;
            
            // Si c'est un nouvel utilisateur et que les termes n'ont pas été acceptés
            if (isNewUser && isRegistration && !localStorage.getItem('termsAccepted')) {
              // Stocker temporairement les données utilisateur
              sessionStorage.setItem('tempFacebookUserData', JSON.stringify(response.data));
              setShowTerms(true);
              setIsProcessing(false);
              return;
            }
            
            // Assurer que l'utilisateur a des données de nom complètes
            const userData = {
              ...response.data.user,
              // Utiliser les informations fournies par Facebook
              firstname: response.data.user.firstname || (response.data.user.name?.split(' ')[0]) || '',
              lastname: response.data.user.lastname || (response.data.user.name?.split(' ').slice(1).join(' ')) || '',
            };
            
            // Connecter l'utilisateur
            loginUser(userData, response.data.jwt, true);
            
            // Rediriger vers la page d'accueil
            setTimeout(() => {
              navigate('/');
            }, 1000);
          } else {
            throw new Error("Données utilisateur incomplètes");
          }
        } catch (err) {
          console.error("Erreur lors de la connexion Facebook:", err);
          
          // Extraction détaillée de l'erreur
          let errorMessage = 'La connexion avec Facebook a échoué';
          
          if (err.response) {
            console.log("Détails de l'erreur:", {
              status: err.response.status,
              data: err.response.data,
              headers: err.response.headers
            });
            
            // Message spécifique selon le code d'erreur
            if (err.response.status === 500) {
              errorMessage = "Erreur interne du serveur. Ce compte Facebook pourrait nécessiter des permissions supplémentaires.";
            } else if (err.response.status === 401 || err.response.status === 403) {
              errorMessage = "Autorisation refusée. Essayez de vous déconnecter de Facebook puis reconnectez-vous.";
            } else if (err.response.data?.error?.message) {
              errorMessage = `Erreur: ${err.response.data.error.message}`;
            }
          }
          
          setError(errorMessage);
        } finally {
          setIsProcessing(false);
        }
      }
    };
    
    processFacebookCallback();
  }, [location, loginUser, navigate, isRegistration]);

const handleFacebookLogin = () => {
  console.log('Tentative de connexion avec Facebook');
  
  // Stocker l'URL actuelle pour redirection après connexion si nécessaire
  if (window.location.pathname !== '/connexion' && window.location.pathname !== '/inscription') {
    sessionStorage.setItem('redirectAfterLogin', window.location.pathname);
  }
  
  // Rediriger vers le point d'entrée de l'API pour l'authentification Facebook
  window.location.href = `${process.env.REACT_APP_API_URL}/api/connect/facebook`;
};
  const handleAcceptTerms = async () => {
    try {
      setIsProcessing(true);
      // Récupérer les données temporaires
      const tempData = JSON.parse(sessionStorage.getItem('tempFacebookUserData'));
      
      if (!tempData) {
        throw new Error("Données utilisateur perdues");
      }
      
      // Mettre à jour l'utilisateur pour indiquer qu'il a accepté les termes
      await axios.put(
        `${API_URL}/api/users/${tempData.user.id}`,
        { termsAccepted: true },
        { headers: { Authorization: `Bearer ${tempData.jwt}` } }
      );
      
      // Stocker l'acceptation pour éviter de redemander
      localStorage.setItem('termsAccepted', 'true');
      
      // Connecter l'utilisateur
      loginUser(tempData.user, tempData.jwt, true);
      
      // Nettoyer les données temporaires
      sessionStorage.removeItem('tempFacebookUserData');
      
      // Rediriger vers la page d'accueil
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } catch (error) {
      console.error("Erreur lors de l'acceptation des termes:", error);
      setError("Erreur lors de l'acceptation des termes. Veuillez réessayer.");
    } finally {
      setIsProcessing(false);
    }
  };

  // Si on montre l'écran d'acceptation des termes
  if (showTerms) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white p-5 rounded-lg border border-gray-200 shadow-md"
      >
        <h3 className="text-lg font-semibold text-gray-800 mb-3">Accepter les conditions</h3>
        <p className="text-sm text-gray-600 mb-4">
          Pour continuer avec Facebook, vous devez accepter nos conditions d'utilisation et notre politique de confidentialité.
        </p>
        
        <div className="flex items-start mb-4">
          <div className="flex items-center h-5 pt-0.5">
            <input
              id="facebook-terms"
              name="facebook-terms"
              type="checkbox"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              className="focus:ring-2 focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="facebook-terms" className="text-gray-700">
              J'accepte les{' '}
              <a
                href="/cgu"
                className="text-blue-600 hover:text-blue-800 hover:underline font-medium"
                target="_blank"
                rel="noopener noreferrer"
              >
                conditions d'utilisation
              </a>{' '}
              et la{' '}
              <a
                href="/politique-confidentialite"
                className="text-blue-600 hover:text-blue-800 hover:underline font-medium"
                target="_blank"
                rel="noopener noreferrer"
              >
                politique de confidentialité
              </a>{' '}
              de VGom Creation.
            </label>
          </div>
        </div>
        
        <div className="flex space-x-3">
          <button
            onClick={handleAcceptTerms}
            disabled={!termsAccepted || isProcessing}
            className={`flex items-center justify-center px-4 py-2 rounded-md text-white ${
              termsAccepted ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400'
            } transition-colors duration-200`}
          >
            {isProcessing ? (
              <>
                <RefreshCw size={16} className="animate-spin mr-2" />
                Traitement...
              </>
            ) : (
              'Continuer'
            )}
          </button>
          <button
            onClick={() => {
              setShowTerms(false);
              sessionStorage.removeItem('tempFacebookUserData');
            }}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            Annuler
          </button>
        </div>
      </motion.div>
    );
  }

  // Si on est en train de traiter le callback
  if (isProcessing) {
    return (
      <div className="flex items-center justify-center w-full py-2.5 px-4 bg-gray-100 border border-gray-300 rounded-md">
        <svg className="animate-spin h-5 w-5 text-blue-600 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span>Connexion en cours...</span>
      </div>
    );
  }

  // Si une erreur s'est produite
  if (error) {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="text-red-600 bg-red-50 p-3 rounded-md mb-3 w-full text-center">
          {error}
        </div>
        <button 
          onClick={handleFacebookLogin} 
          className="flex items-center justify-center gap-2 w-full py-2.5 px-4 bg-white hover:bg-gray-50 border border-gray-300 rounded-md shadow-sm transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          style={{ 
            fontFamily: 'Helvetica, Arial, sans-serif', 
            color: '#1877F2', 
            fontWeight: '600',
            fontSize: '14px',
            letterSpacing: '0.25px',
          }}
        >
          <img 
            src={facebookLogo} 
            alt="Facebook Logo" 
            style={{ width: '18px', height: '18px', marginRight: '8px' }} 
          />
          <span>Réessayer avec Facebook</span>
        </button>
      </div>
    );
  }

  // Affichage normal du bouton
  return (
    <button 
      onClick={handleFacebookLogin} 
      className="flex items-center justify-center gap-2 w-full py-2.5 px-4 bg-[#004aad] border border-[#004aad] rounded-md shadow-sm transition-shadow duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 hover:shadow-md"
      style={{ 
        fontFamily: 'Helvetica, Arial, sans-serif', 
        color: '#ffffff', 
        fontWeight: '600',
        fontSize: '14px',
        letterSpacing: '0.25px',
      }}
    >
      <img 
        src={facebookLogo} 
        alt="Facebook Logo" 
        style={{ width: '18px', height: '18px', marginRight: '8px' }} 
      />
      <span>Facebook</span>
    </button>
  );
};

export default FacebookLogin;