import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import { RefreshCw, CheckCircle, AlertCircle } from 'lucide-react';

const API_URL =
  process.env.REACT_APP_API_URL || 'https://vgomcreation-api.onrender.com';

const FacebookAuthRedirect = () => {
  const [status, setStatus] = useState('processing');
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { loginUser } = useContext(UserContext);

  useEffect(() => {
    const processAuth = async () => {
      try {
        // Récupérer le token Facebook de l'URL
        const urlParams = new URLSearchParams(location.search);
        const fbAccessToken = urlParams.get('access_token');

        if (!fbAccessToken) {
          throw new Error("Token d'accès manquant dans l'URL");
        }

        // Déboguer le token
        console.log(
          'Token Facebook reçu:',
          fbAccessToken.substring(0, 10) + '...'
        );

        // Étape 1: Obtenir les données utilisateur de Facebook
        console.log('Récupération des données utilisateur depuis Facebook...');
        const fbResponse = await fetch(
          `https://graph.facebook.com/v18.0/me?fields=id,name,email,picture&access_token=${fbAccessToken}`
        );

        if (!fbResponse.ok) {
          throw new Error(
            'Erreur lors de la récupération des données Facebook'
          );
        }

        const fbData = await fbResponse.json();
        console.log('Données Facebook récupérées:', fbData);

        if (!fbData.id || !fbData.email) {
          throw new Error('Données Facebook incomplètes');
        }

        // Étape 2: Utiliser notre endpoint personnalisé au lieu du callback standard
        console.log('Authentification avec le serveur...');
        try {
            const authResponse = await axios.get(
                `${API_URL}/api/auth/facebook/callback`,
                {
                  params: { access_token: fbAccessToken }
                }
              );

          console.log('Réponse authentification:', authResponse.data);

          if (
            authResponse.data &&
            authResponse.data.jwt &&
            authResponse.data.user
          ) {
            // Connexion réussie avec JWT Strapi
            const strapiJWT = authResponse.data.jwt;
            const userData = authResponse.data.user;

            // Connecter l'utilisateur avec les données et le JWT Strapi
            loginUser(userData, strapiJWT, true);

            setStatus('success');

            // Redirection différée
            setTimeout(() => {
              const redirectPath = sessionStorage.getItem('redirectAfterLogin');
              if (redirectPath) {
                sessionStorage.removeItem('redirectAfterLogin');
                navigate(redirectPath);
              } else {
                navigate('/');
              }
            }, 1500);
          } else {
            throw new Error('Réponse invalide du serveur');
          }
        } catch (error) {
          console.error('Erreur authentification serveur:', error);

          if (error.response?.status === 404) {
            throw new Error(
              "L'endpoint d'authentification Facebook n'existe pas sur votre API"
            );
          } else if (error.response?.status === 400) {
            throw new Error(error.response.data.error || 'Données invalides');
          } else {
            throw new Error(
              "Erreur lors de l'authentification avec le serveur"
            );
          }
        }
      } catch (error) {
        console.error("Erreur lors de l'authentification:", error);
        setStatus('error');
        setErrorMessage(
          error.message ||
            "Une erreur s'est produite lors de l'authentification. Veuillez réessayer."
        );
      }
    };

    processAuth();
  }, [location, loginUser, navigate]);

  // Le reste du composant reste inchangé...
  if (status === 'processing') {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full"
        >
          <div className="flex flex-col items-center">
            <RefreshCw size={48} className="text-blue-500 animate-spin mb-4" />
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Authentification en cours
            </h2>
            <p className="text-gray-500 text-center">
              Nous finalisons votre connexion avec Facebook, veuillez
              patienter...
            </p>
          </div>
        </motion.div>
      </div>
    );
  }

  if (status === 'success') {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full"
        >
          <div className="flex flex-col items-center">
            <div className="bg-green-100 p-3 rounded-full mb-4">
              <CheckCircle size={42} className="text-green-500" />
            </div>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Connexion réussie!
            </h2>
            <p className="text-gray-500 text-center">
              Vous allez être redirigé automatiquement...
            </p>
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full"
      >
        <div className="flex flex-col items-center">
          <div className="bg-red-100 p-3 rounded-full mb-4">
            <AlertCircle size={42} className="text-red-500" />
          </div>
          <h2 className="text-xl font-semibold text-gray-800 mb-2">
            Erreur d'authentification
          </h2>
          <p className="text-red-500 text-center mb-4">{errorMessage}</p>
          <button
            onClick={() => navigate('/connexion')}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            Retour à la page de connexion
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default FacebookAuthRedirect;