import React, { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { getPurchasedProjects } from '../api';
import axios from 'axios';
import { API_URL } from '../api';
import {
  LayoutDashboard,
  FileText,
  CreditCard,
  MessageSquare,
  ChevronRight,
  FileCheck,
  Clock,
  Download,
  Calendar,
  Mail,
  Loader,
} from 'lucide-react';

function Dashboard() {
  const { isAuthenticated } = useContext(UserContext);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const jwt = localStorage.getItem('jwt');
  const [activeTab, setActiveTab] = useState('overview');
  const dataFetched = useRef(false);

  // États pour stocker les données récupérées de l'API
  const [projects, setProjects] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState({
    projects: false,
    invoices: false,
    messages: false,
  });
  const [error, setError] = useState({
    projects: null,
    invoices: null,
    messages: null,
  });

  // Ajoutez cette fonction pour vérifier l'état d'authentification
  const checkAuthStatus = () => {
    // Récupérer tous les éléments d'authentification
    const jwt = localStorage.getItem('jwt');
    const user = localStorage.getItem('user');
    const parsedUser = user ? JSON.parse(user) : null;

    console.log('=== VÉRIFICATION AUTHENTIFICATION ===');
    console.log('JWT présent:', !!jwt);
    if (jwt) {
      // Analyser le JWT sans bibliothèque
      try {
        const parts = jwt.split('.');
        if (parts.length === 3) {
          // Décoder le payload (partie centrale)
          const payload = JSON.parse(atob(parts[1]));
          console.log('JWT valide avec payload:', payload);
          console.log(
            "Date d'expiration:",
            new Date(payload.exp * 1000).toLocaleString()
          );
          console.log('JWT expiré:', Date.now() > payload.exp * 1000);
        } else {
          console.log('Format JWT incorrect, nombre de parties:', parts.length);
        }
      } catch (e) {
        console.log('Erreur lors du décodage du JWT:', e.message);
      }
    }

    console.log('User présent:', !!parsedUser);
    if (parsedUser) {
      console.log('ID utilisateur:', parsedUser.id);
      console.log('Rôle utilisateur:', parsedUser.role?.name || 'Non spécifié');
    }

    // Vérifier si UserContext a les mêmes informations
    console.log('isAuthenticated dans le contexte:', isAuthenticated);
    console.log('================================');

    return { jwt, parsedUser, isAuthenticated };
  };

  // Appelez cette fonction au chargement du composant
  useEffect(() => {
    const authStatus = checkAuthStatus();
    if (
      authStatus.isAuthenticated &&
      authStatus.jwt &&
      authStatus.parsedUser &&
      !dataFetched.current
    ) {
      dataFetched.current = true;
      fetchProjects();
      fetchInvoices();
      fetchMessages();
    }
  }, [isAuthenticated]);

  // Fonction pour récupérer les projets de l'utilisateur
  const fetchProjects = async () => {
    setLoading((prev) => ({ ...prev, projects: true }));
    try {
      console.log('Début de la récupération des projets...');

      const response = await getPurchasedProjects();
      console.log('Réponse des projets:', response);

      // Vérifier si response.data existe et est un tableau
      if (!response || !response.data) {
        console.error('Réponse invalide de getPurchasedProjects()', response);
        setProjects([]); // Définir un tableau vide en cas de problème
        setError((prev) => ({
          ...prev,
          projects: 'Format de données incorrect',
        }));
        return;
      }

      // Adapter le format pour l'affichage - ajustez selon la structure réelle
      const formattedProjects = response.data.map((item) => ({
        id: item.id,
        name: item.attributes.titre,
        progress: item.attributes.progression || 0,
        status: item.attributes.statut || 'En attente',
        deadline: item.attributes.date_fin_prevue
          ? new Date(item.attributes.date_fin_prevue).toLocaleDateString(
              'fr-FR'
            )
          : 'Non définie',
        datePurchased: item.attributes.dateSold
          ? new Date(item.attributes.dateSold).toLocaleDateString('fr-FR')
          : '-',
      }));

      console.log('Projets formatés:', formattedProjects);
      setProjects(formattedProjects);
      setError((prev) => ({ ...prev, projects: null })); // Réinitialiser l'erreur en cas de succès
    } catch (err) {
      console.error(
        'Erreur détaillée lors de la récupération des projets:',
        err
      );
      setProjects([]); // Définir un tableau vide en cas d'erreur
      setError((prev) => ({
        ...prev,
        projects: `Impossible de charger vos projets: ${err.message || 'Erreur inconnue'}`,
      }));
    } finally {
      console.log('Fin de la récupération des projets');
      setLoading((prev) => ({ ...prev, projects: false })); // Assurez-vous que cette ligne s'exécute
    }
  };
  // Fonction pour récupérer les factures de l'utilisateur
  const fetchInvoices = async () => {
    if (!jwt || !user) return;

    setLoading((prev) => ({ ...prev, invoices: true }));
    try {
      const response = await axios.get(`${API_URL}/api/factures`, {
        headers: { Authorization: `Bearer ${jwt}` },
        params: {
          'filters[users_permissions_user][id][$eq]': user.id, // Adapter au nom de la relation dans votre modèle
          populate: ['projet'],
        },
      });

      console.log('Réponse API factures:', response.data);

      // Adapter pour Strapi 5 (sans attributes)
      const formattedInvoices = response.data.data.map((item) => ({
        id: item.numero_facture || item.documentId || item.id,
        date: new Date(item.date_emission || item.createdAt).toLocaleDateString(
          'fr-FR'
        ),
        amount: `${item.montant_total || item.montant || 0}€`,
        status: item.statut_paiement || item.statut || 'En attente',
        project: item.projet?.titre || 'Non spécifié', // Accès direct aux données du projet
      }));

      setInvoices(formattedInvoices);
    } catch (err) {
      console.error(
        'Erreur détaillée lors de la récupération des factures:',
        err.response?.data || err
      );
      setError((prev) => ({
        ...prev,
        invoices: 'Impossible de charger vos factures',
      }));
    } finally {
      setLoading((prev) => ({ ...prev, invoices: false }));
    }
  };

  // Fonction pour récupérer les messages de l'utilisateur
  const fetchMessages = async () => {
    if (!jwt || !user) return;

    setLoading((prev) => ({ ...prev, messages: true }));
    try {
      const response = await axios.get(`${API_URL}/api/messages`, {
        headers: { Authorization: `Bearer ${jwt}` },
        params: {
          'filters[destinataire][id][$eq]': user.id,
          populate: ['expediteur'],
          sort: 'createdAt:desc',
        },
      });

      console.log('Réponse API messages:', response.data);

      // Adapter pour Strapi 5 (sans attributes)
      const formattedMessages = response.data.data.map((item) => ({
        id: item.documentId || item.id,
        from: item.expediteur?.username || 'Support', // Accès direct aux données
        subject: item.sujet || item.objet || 'Message',
        date: new Date(item.createdAt).toLocaleDateString('fr-FR'),
        read: item.lu || false,
      }));

      setMessages(formattedMessages);
    } catch (err) {
      console.error(
        'Erreur détaillée lors de la récupération des messages:',
        err.response?.data || err
      );
      setError((prev) => ({
        ...prev,
        messages: 'Impossible de charger vos messages',
      }));
    } finally {
      setLoading((prev) => ({ ...prev, messages: false }));
    }
  };

  // Chargement des données au montage du composant - version corrigée
  useEffect(() => {
    // On supprime le useEffect redondant et on ne garde que celui-ci
    if (isAuthenticated && user && !dataFetched.current) {
      console.log('Chargement initial des données...');
      dataFetched.current = true;
      fetchProjects();
      fetchInvoices();
      fetchMessages();
    }
  }, [isAuthenticated, user]); // eslint-disable-line react-hooks/exhaustive-deps

  // Redirige vers la page de connexion si non authentifié
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  // Marquer un message comme lu
  const markMessageAsRead = async (messageId) => {
    if (!jwt) return;

    try {
      // Strapi 5 n'utilise plus { data: { ... } } mais directement l'objet
      await axios.put(
        `${API_URL}/api/messages/${messageId}`,
        { lu: true }, // Sans wrapper data
        { headers: { Authorization: `Bearer ${jwt}` } }
      );

      // Mettre à jour l'état local
      setMessages((prev) =>
        prev.map((msg) => (msg.id === messageId ? { ...msg, read: true } : msg))
      );
    } catch (err) {
      console.error('Erreur lors du marquage du message:', err);
    }
  };

  // Si non authentifié, ne rien afficher pendant la redirection
  if (!isAuthenticated) return null;

  return (
    <div className="min-h-screen pt-32 pb-12 bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-10">
          <div>
            <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-indigo-500">
              Tableau de bord
            </h1>
            <p className="mt-2 text-gray-300">
              Bienvenue, {user?.firstName || 'Utilisateur'} ! Retrouvez toutes
              vos informations.
            </p>
          </div>
          <div className="mt-4 md:mt-0">
            <p className="text-sm text-gray-400">
              Dernière connexion: {new Date().toLocaleDateString('fr-FR')}
            </p>
          </div>
        </div>

        {/* Navigation par onglets */}
        <div className="flex flex-wrap gap-2 mb-8 border-b border-gray-700 pb-2">
          <button
            onClick={() => setActiveTab('overview')}
            className={`px-4 py-2 rounded-t-lg transition ${
              activeTab === 'overview'
                ? 'bg-blue-600 text-white'
                : 'text-gray-400 hover:text-white hover:bg-gray-800'
            }`}
          >
            Vue d'ensemble
          </button>
          <button
            onClick={() => setActiveTab('projects')}
            className={`px-4 py-2 rounded-t-lg transition ${
              activeTab === 'projects'
                ? 'bg-blue-600 text-white'
                : 'text-gray-400 hover:text-white hover:bg-gray-800'
            }`}
          >
            Projets
          </button>
          <button
            onClick={() => setActiveTab('invoices')}
            className={`px-4 py-2 rounded-t-lg transition ${
              activeTab === 'invoices'
                ? 'bg-blue-600 text-white'
                : 'text-gray-400 hover:text-white hover:bg-gray-800'
            }`}
          >
            Factures
          </button>
          <button
            onClick={() => setActiveTab('messages')}
            className={`px-4 py-2 rounded-t-lg transition ${
              activeTab === 'messages'
                ? 'bg-blue-600 text-white'
                : 'text-gray-400 hover:text-white hover:bg-gray-800'
            }`}
          >
            Messages
          </button>
        </div>

        <div className="bg-gradient-to-r from-blue-900 to-indigo-900 rounded-xl shadow-lg p-8 mb-10 border border-blue-700 relative overflow-hidden">
          <div className="absolute top-0 right-0 -mt-4 -mr-4 bg-blue-500 bg-opacity-20 p-2 rounded-full">
            <LayoutDashboard className="h-10 w-10 text-blue-300" />
          </div>

          <div className="flex items-start">
            <div className="bg-blue-500 bg-opacity-20 p-3 rounded-full mr-6">
              <Clock className="h-8 w-8 text-blue-300" />
            </div>
            <div className="text-left">
              <h2 className="text-2xl font-bold text-white mb-3">
                Tableau de bord en cours de développement
              </h2>
              <p className="text-blue-100 mb-4 max-w-3xl">
                Nous sommes en train de construire une expérience complète pour
                suivre vos projets, gérer vos paiements et communiquer
                facilement avec notre équipe. Cette interface évoluera
                régulièrement avec de nouvelles fonctionnalités.
              </p>
              <div className="flex flex-wrap gap-3">
                <div className="bg-blue-800 bg-opacity-50 px-4 py-2 rounded-lg border border-blue-600">
                  <p className="text-sm text-blue-200">
                    <span className="text-blue-100 font-medium">Phase 1 :</span>{' '}
                    Interface de base (Disponible)
                  </p>
                </div>
                <div className="bg-blue-800 bg-opacity-50 px-4 py-2 rounded-lg border border-blue-600">
                  <p className="text-sm text-blue-200">
                    <span className="text-blue-100 font-medium">Phase 2 :</span>{' '}
                    Suivi des projets (Bientôt)
                  </p>
                </div>
                <div className="bg-blue-800 bg-opacity-50 px-4 py-2 rounded-lg border border-blue-600">
                  <p className="text-sm text-blue-200">
                    <span className="text-blue-100 font-medium">Phase 3 :</span>{' '}
                    Paiements et documents (À venir)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Vue d'ensemble - montré par défaut */}
        {activeTab === 'overview' && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-10">
            {/* Carte des projets */}
            <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg p-6 border border-gray-700">
              <div className="flex items-center mb-4">
                <div className="bg-blue-500 bg-opacity-20 p-3 rounded-lg">
                  <FileText className="h-6 w-6 text-blue-400" />
                </div>
                <h2 className="ml-3 text-xl font-semibold text-white">
                  Mes projets
                </h2>
              </div>

              {projects.length > 0 ? (
                <div className="space-y-4">
                  {projects.slice(0, 2).map((project) => (
                    <div
                      key={project.id}
                      className="border-b border-gray-700 pb-3 last:border-0 last:pb-0"
                    >
                      <div className="flex justify-between mb-1">
                        <span className="text-white font-medium">
                          {project.name}
                        </span>
                        <span
                          className={`text-xs px-2 py-1 rounded-full ${
                            project.status === 'En développement'
                              ? 'bg-yellow-500 bg-opacity-20 text-yellow-300'
                              : 'bg-blue-500 bg-opacity-20 text-blue-300'
                          }`}
                        >
                          {project.status}
                        </span>
                      </div>
                      <div className="w-full bg-gray-700 rounded-full h-2.5 mb-1">
                        <div
                          className="bg-gradient-to-r from-blue-500 to-indigo-600 h-2.5 rounded-full"
                          style={{ width: `${project.progress}%` }}
                        ></div>
                      </div>
                      <div className="flex justify-between text-xs text-gray-400">
                        <span>Progression: {project.progress}%</span>
                        <span>Échéance: {project.deadline}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-400">
                  Aucun projet en cours pour le moment.
                </p>
              )}

              <button
                className="mt-4 text-blue-400 hover:text-blue-300 flex items-center text-sm font-medium"
                onClick={() => setActiveTab('projects')}
              >
                Voir tous mes projets
                <ChevronRight className="w-4 h-4 ml-1" />
              </button>
            </div>

            {/* Carte des factures */}
            <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg p-6 border border-gray-700">
              <div className="flex items-center mb-4">
                <div className="bg-green-500 bg-opacity-20 p-3 rounded-lg">
                  <CreditCard className="h-6 w-6 text-green-400" />
                </div>
                <h2 className="ml-3 text-xl font-semibold text-white">
                  Factures & Paiements
                </h2>
              </div>

              {invoices.length > 0 ? (
                <div className="space-y-3">
                  {invoices.map((invoice) => (
                    <div
                      key={invoice.id}
                      className="flex justify-between items-center border-b border-gray-700 pb-3 last:border-0 last:pb-0"
                    >
                      <div>
                        <p className="text-white">{invoice.id}</p>
                        <p className="text-xs text-gray-400">
                          {invoice.date} - {invoice.project}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-white font-medium">
                          {invoice.amount}
                        </p>
                        <p
                          className={`text-xs ${
                            invoice.status === 'Payée'
                              ? 'text-green-400'
                              : 'text-yellow-400'
                          }`}
                        >
                          {invoice.status}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-400">Aucune facture disponible.</p>
              )}

              <button
                className="mt-4 text-green-400 hover:text-green-300 flex items-center text-sm font-medium"
                onClick={() => setActiveTab('invoices')}
              >
                Voir toutes mes factures
                <ChevronRight className="w-4 h-4 ml-1" />
              </button>
            </div>

            {/* Carte des messages */}
            <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg p-6 border border-gray-700">
              <div className="flex items-center mb-4">
                <div className="bg-purple-500 bg-opacity-20 p-3 rounded-lg">
                  <MessageSquare className="h-6 w-6 text-purple-400" />
                </div>
                <h2 className="ml-3 text-xl font-semibold text-white">
                  Messages récents
                </h2>
              </div>

              {messages.length > 0 ? (
                <div className="space-y-3">
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className="border-b border-gray-700 pb-3 last:border-0 last:pb-0"
                    >
                      <div className="flex items-start">
                        {!message.read && (
                          <div className="h-2 w-2 bg-blue-500 rounded-full mt-1.5 mr-2"></div>
                        )}
                        <div className={message.read ? 'ml-4' : ''}>
                          <p className="text-white font-medium">
                            {message.from}: {message.subject}
                          </p>
                          <p className="text-xs text-gray-400">
                            {message.date}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-400">Aucun message récent.</p>
              )}

              <button
                className="mt-4 text-purple-400 hover:text-purple-300 flex items-center text-sm font-medium"
                onClick={() => setActiveTab('messages')}
              >
                Voir tous mes messages
                <ChevronRight className="w-4 h-4 ml-1" />
              </button>
            </div>
          </div>
        )}

        {/* Vue des projets */}
        {activeTab === 'projects' && (
          <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg p-6 border border-gray-700 mb-10">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold text-white">Mes projets</h2>
              <button
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg text-white text-sm font-medium transition"
                onClick={() => navigate('/nous-contacter')}
              >
                Demander un nouveau projet
              </button>
            </div>

            {loading.projects ? (
              <div className="flex justify-center py-8">
                <Loader className="w-8 h-8 text-blue-400 animate-spin" />
              </div>
            ) : error.projects ? (
              <div className="text-center py-6">
                <p className="text-red-400">{error.projects}</p>
                <button
                  className="mt-2 text-blue-400 hover:text-blue-300"
                  onClick={fetchProjects}
                >
                  Réessayer
                </button>
              </div>
            ) : projects && projects.length > 0 ? (
              <div className="space-y-6">
                {/* Code existant pour afficher les projets */}
                {projects.map((project) => (
                  <div
                    key={project.id}
                    className="bg-gray-900 bg-opacity-50 rounded-lg p-5 border border-gray-700"
                  >
                    <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-4">
                      <h3 className="text-xl font-medium text-white mb-2 md:mb-0">
                        {project.name}
                      </h3>
                      <span
                        className={`text-xs px-3 py-1 rounded-full ${
                          project.status === 'En développement'
                            ? 'bg-yellow-500 bg-opacity-20 text-yellow-300'
                            : 'bg-blue-500 bg-opacity-20 text-blue-300'
                        }`}
                      >
                        {project.status}
                      </span>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                      <div className="bg-gray-800 p-3 rounded-lg">
                        <p className="text-xs text-gray-400 mb-1">
                          Progression
                        </p>
                        <div className="w-full bg-gray-700 rounded-full h-2.5 mb-2">
                          <div
                            className="bg-gradient-to-r from-blue-500 to-indigo-600 h-2.5 rounded-full"
                            style={{ width: `${project.progress}%` }}
                          ></div>
                        </div>
                        <p className="text-sm text-right text-gray-300">
                          {project.progress}%
                        </p>
                      </div>
                      <div className="bg-gray-800 p-3 rounded-lg">
                        <p className="text-xs text-gray-400 mb-1">
                          Prochaine étape
                        </p>
                        <p className="text-sm text-gray-300">
                          Validation des maquettes
                        </p>
                      </div>
                      <div className="bg-gray-800 p-3 rounded-lg">
                        <p className="text-xs text-gray-400 mb-1">Échéance</p>
                        <div className="flex items-center">
                          <Calendar className="w-4 h-4 text-gray-400 mr-2" />
                          <p className="text-sm text-gray-300">
                            {project.deadline}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-800 p-3 rounded-lg">
                      <p className="text-xs text-gray-400 mb-1">Date d'achat</p>
                      <div className="flex items-center">
                        <Calendar className="w-4 h-4 text-gray-400 mr-2" />
                        <p className="text-sm text-gray-300">
                          {project.datePurchased}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-2">
                      <button className="px-3 py-1.5 bg-gray-700 hover:bg-gray-600 rounded text-sm text-white transition flex items-center">
                        <FileCheck className="w-4 h-4 mr-1" />
                        Détails du projet
                      </button>
                      <button className="px-3 py-1.5 bg-gray-700 hover:bg-gray-600 rounded text-sm text-white transition flex items-center">
                        <MessageSquare className="w-4 h-4 mr-1" />
                        Discuter
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-10">
                <p className="text-gray-400 mb-4">
                  Vous n'avez aucun projet en cours pour le moment.
                </p>
                <button
                  className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg text-white text-sm font-medium transition"
                  onClick={() => navigate('/shop')}
                >
                  Découvrir nos projets
                </button>
              </div>
            )}
          </div>
        )}

        {/* Vue des factures */}
        {activeTab === 'invoices' && (
          <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg p-6 border border-gray-700 mb-10">
            <h2 className="text-2xl font-semibold text-white mb-6">
              Mes factures
            </h2>

            {invoices.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        N° Facture
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Projet
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Montant
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Statut
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {invoices.map((invoice) => (
                      <tr key={invoice.id} className="hover:bg-gray-700">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {invoice.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {invoice.date}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {invoice.project}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {invoice.amount}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`px-2 py-1 text-xs rounded-full ${
                              invoice.status === 'Payée'
                                ? 'bg-green-500 bg-opacity-20 text-green-400'
                                : 'bg-yellow-500 bg-opacity-20 text-yellow-400'
                            }`}
                          >
                            {invoice.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-blue-400 hover:text-blue-300 mx-2">
                            <Download className="w-5 h-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-10">
                <p className="text-gray-400">
                  Aucune facture disponible pour le moment.
                </p>
              </div>
            )}
          </div>
        )}

        {/* Vue des messages */}
        {activeTab === 'messages' && (
          <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg p-6 border border-gray-700 mb-10">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold text-white">
                Mes messages
              </h2>
              <button
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg text-white text-sm font-medium transition flex items-center"
                onClick={() => navigate('/nous-contacter')}
              >
                <Mail className="w-4 h-4 mr-2" />
                Nouveau message
              </button>
            </div>

            {messages.length > 0 ? (
              <div className="space-y-4">
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={`p-4 rounded-lg border ${
                      message.read
                        ? 'bg-gray-900 bg-opacity-50 border-gray-700'
                        : 'bg-blue-900 bg-opacity-10 border-blue-800'
                    }`}
                    onClick={() =>
                      !message.read && markMessageAsRead(message.id)
                    }
                    role="button"
                    tabIndex={0}
                  >
                    <div className="flex justify-between items-start mb-2">
                      <div className="flex items-center">
                        {!message.read && (
                          <div className="h-2 w-2 bg-blue-500 rounded-full mt-1.5 mr-2"></div>
                        )}
                        <h3 className="text-lg font-medium text-white">
                          {message.subject}
                        </h3>
                      </div>
                      <span className="text-xs text-gray-400">
                        {message.date}
                      </span>
                    </div>
                    <p className="text-sm text-gray-300 mb-3">
                      De: <span className="text-white">{message.from}</span>
                    </p>
                    <p className="text-gray-300 mb-4">
                      Contenu du message tronqué pour la prévisualisation.
                      Cliquez pour voir le message complet...
                    </p>
                    <div className="flex justify-end">
                      <button className="px-3 py-1.5 bg-gray-700 hover:bg-gray-600 rounded text-sm text-white transition">
                        Répondre
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg p-6 text-center mb-10">
                <h2 className="text-xl font-semibold text-white mb-2">
                  Vous avez des suggestions ?
                </h2>
                <p className="text-gray-300 mb-4 max-w-2xl mx-auto">
                  Aidez-nous à améliorer votre espace client en partageant vos
                  idées et besoins.
                </p>
                <button
                  onClick={() => navigate('/nous-contacter')}
                  className="px-5 py-2 bg-gradient-to-r from-blue-600 to-indigo-700 text-white font-medium rounded-lg hover:shadow-lg transition-shadow"
                >
                  Contactez-nous
                </button>
              </div>
            )}
          </div>
        )}

        {/* Section "En construction" qui reste toujours visible */}
        <div className="bg-gray-800 bg-opacity-50 rounded-xl shadow-lg p-8 text-center mb-10">
          <LayoutDashboard className="h-10 w-10 text-blue-400 mx-auto mb-4" />
          <h2 className="text-2xl font-semibold text-white mb-2">
            Votre espace client évolue constamment
          </h2>
          <p className="text-gray-300 mb-6 max-w-2xl mx-auto">
            Nous travaillons activement à l'amélioration de votre espace
            personnel. De nouvelles fonctionnalités seront régulièrement
            ajoutées pour faciliter le suivi de vos projets.
          </p>
          <button
            onClick={() => navigate('/nous-contacter')}
            className="px-6 py-3 bg-gradient-to-r from-blue-600 to-indigo-700 text-white font-medium rounded-lg hover:shadow-lg transition-shadow"
          >
            Une suggestion ? Contactez-nous
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
