import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import BackHomeButton from '../components/BackHomeButton';
import Particles from '../animations/Particles';
import {
  FaCcVisa,
  FaCcMastercard,
  FaLock,
  FaCheckCircle,
  FaRegLightbulb,
  FaRegClock,
  FaShieldAlt,
} from 'react-icons/fa';

const Tarifs = () => {
  const pricingPlans = [
    {
      title: 'Vitrine',
      price: 799.99,
      fixedPrice: false,
      description:
        'Site vitrine (1 à 3 pages), responsive, formulaire de contact, hébergement 1 an, nom de domaine inclus.',
      delay: '10-15 jours ouvrés',
      features: [
        'Design responsive & moderne',
        'Formulaire de contact sécurisé',
        'Optimisation mobile',
        'Hébergement 1ère année inclus',
      ],
      recommended: false,
      color: 'bg-gradient-to-br from-blue-50 to-indigo-100',
    },
    {
      title: 'Business',
      price: 1799.99,
      fixedPrice: false,
      description:
        'Site professionnel (4 à 7 pages), blog ou galerie, SEO de base, tableau de bord admin, hébergement 1 an.',
      delay: '25-35 jours ouvrés',
      features: [
        "Jusqu'à 7 pages personnalisées",
        'Blog ou galerie intégrés',
        'SEO de base',
        'Dashboard administration',
        'Support technique 3 mois',
      ],
      recommended: true,
      color: 'bg-gradient-to-br from-blue-100 to-indigo-200',
    },
    {
      title: 'E-Commerce',
      price: 2799.99,
      fixedPrice: false,
      description:
        'Boutique en ligne complète, gestion des produits, paiements sécurisés, SEO avancé, formation incluse.',
      delay: '35-45 jours ouvrés',
      features: [
        'Boutique en ligne complète',
        'Gestion de produits',
        'Paiements sécurisés',
        'SEO avancé',
        "Formation d'utilisation incluse",
        'Support technique 6 mois',
      ],
      recommended: false,
      color: 'bg-gradient-to-br from-blue-50 to-indigo-100',
    },
    {
      title: 'Maintenance',
      price: 29.99,
      fixedPrice: true,
      description:
        'Mises à jour régulières, correctifs de bugs, surveillance de sécurité et assistance technique.',
      delay: 'Abonnement mensuel, engagement de 12 mois',
      features: [
        'Mises à jour de sécurité',
        'Correction des bugs',
        'Surveillance 24/7',
        'Assistance technique',
        'Sauvegardes régulières',
      ],
      recommended: false,
      color: 'bg-gradient-to-br from-purple-50 to-blue-100',
    },
  ];

  const additionalOptions = [
    [
      'Page supplémentaire : 100 €',
      'Blog avec gestion des posts : 800 €',
      'Intégration de Stripe ou PayPal : 600 €',
      'Authentification Google : 500 €',
    ],
    [
      'Optimisation performance + audit SEO : 900 €',
      'SEO pour 5 pages : 800 €',
      'Debugging (simple) : 50 - 100 €/heure',
      'SEO on-page (par page) : 100 - 200 €',
    ],
    [
      'Progressive Web App (PWA) : 800 €',
      "Intégration d'IA simple : 500 €",
      'Système de réservation : 700 €',
      'Chat en direct : 400 €',
    ],
  ];

  const paymentMethods = [
    {
      icon: <FaCcVisa className="text-blue-600 w-8 h-8" />,
      text: 'Carte Visa',
    },
    {
      icon: <FaCcMastercard className="text-orange-500 w-8 h-8" />,
      text: 'MasterCard',
    },
    {
      icon: <FaLock className="text-gray-700 w-7 h-7" />,
      text: 'Virement bancaire sécurisé',
    },
    {
      icon: <FaRegClock className="text-gray-700 w-7 h-7" />,
      text: 'Paiement en plusieurs fois',
    },
  ];

  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-11 sm:py-12">
      {/* Section Tarifs */}
      <div className="container mt-16 md:mt-24 mx-auto px-4">
        <motion.div
          className="text-center mb-12 sm:mb-16"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
            Nos{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-700">
              Tarifs
            </span>
          </h1>
          <p className="text-gray-600 max-w-xl mx-auto mb-0 md:mb-8 text-lg">
            Des solutions web adaptées à votre budget et à vos ambitions
          </p>
        </motion.div>

        {/* Bouton retour */}
        <BackHomeButton />

        {/* Cards des forfaits */}
        <div className="mt-6 md:mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-7xl mx-auto">
          {pricingPlans.map((plan, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 ${plan.color} relative`}
            >
              {plan.recommended && (
                <div className="absolute top-0 right-0 translate-y-0 translate-x-0">
                  <div className="bg-gradient-to-r from-amber-500 to-yellow-500 text-white px-4 py-1 rounded-full text-xs font-semibold shadow-md">
                    Recommandé
                  </div>
                </div>
              )}
              <div className="p-6">
                <h3 className="text-xl font-bold mb-3 text-gray-800">
                  {plan.title}
                </h3>
                <div className="flex items-baseline mb-4">
                  <span className="text-2xl sm:text-3xl font-bold text-gray-900">
                    {plan.price.toFixed(2)} €
                  </span>
                  {plan.fixedPrice && (
                    <span className="text-gray-600 ml-1 text-sm">/mois</span>
                  )}
                </div>
                <p className="text-gray-600 text-sm mb-6">
                  {!plan.fixedPrice && 'À partir de'}
                </p>
                <div className="h-px bg-gray-200 mb-6"></div>
                <p className="text-gray-700 text-sm mb-6">{plan.description}</p>
                <div className="space-y-3 mb-8">
                  {plan.features.map((feature, idx) => (
                    <div key={idx} className="flex items-start space-x-2">
                      <FaCheckCircle className="text-green-500 mt-0.5 flex-shrink-0" />
                      <span className="text-sm text-gray-700">{feature}</span>
                    </div>
                  ))}
                </div>
                <div className="flex items-center justify-between text-sm text-gray-600 mt-auto">
                  <div className="flex items-center">
                    <FaRegClock className="mr-1 text-gray-500" />
                    <span>{plan.delay}</span>
                  </div>
                </div>
              </div>
              <div className="p-4 bg-white/60">
                <Link
                  to="/contact"
                  className={`block text-center py-2.5 px-4 rounded-lg font-medium transition-all ${
                    plan.recommended
                      ? 'bg-black text-white hover:bg-gray-800'
                      : 'bg-indigo-100 text-indigo-800 hover:bg-indigo-200'
                  }`}
                >
                  Demander un devis
                </Link>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Modalités de paiement */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="bg-white mt-12 py-6 px-8 rounded-xl shadow-md border border-gray-200 max-w-4xl mx-auto"
        >
          <div className="flex items-start space-x-4">
            <div className="bg-blue-100 p-2 rounded-full">
              <FaShieldAlt className="text-blue-600 w-6 h-6" />
            </div>
            <div>
              <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">
                Modalités de paiement
              </h3>
              <p className="text-sm sm:text-base text-gray-700 mb-3">
                Pour tous les forfaits, le paiement s'effectue en trois étapes
                pour garantir un suivi optimal et une répartition équilibrée :
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 text-center">
                  <div className="text-xl font-bold text-blue-600 mb-1">
                    30%
                  </div>
                  <div className="text-sm text-gray-600">à la commande</div>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 text-center">
                  <div className="text-xl font-bold text-blue-600 mb-1">
                    50%
                  </div>
                  <div className="text-sm text-gray-600">
                    en cours de projet
                  </div>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 text-center">
                  <div className="text-xl font-bold text-blue-600 mb-1">
                    20%
                  </div>
                  <div className="text-sm text-gray-600">
                    avant la livraison
                  </div>
                </div>
              </div>
              <p className="text-sm sm:text-base text-gray-700">
                Ces modalités assurent un contrôle régulier du projet tout en
                répartissant les paiements selon les étapes clés de la
                prestation.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Options supplémentaires */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="mt-16 sm:mt-20 text-center"
        >
          <h2 className="text-2xl sm:text-3xl font-bold mb-4">
            Options supplémentaires
          </h2>
          <p className="text-gray-600 max-w-lg sm:max-w-3xl mx-auto text-sm sm:text-base mb-6 sm:mb-8">
            Personnalisez davantage votre site avec nos options supplémentaires.
          </p>
          <p className="text-gray-500 italic max-w-lg sm:max-w-3xl mx-auto text-sm sm:text-base mb-8">
            * Les tarifs indiqués sont à titre indicatif et peuvent varier en
            fonction de la complexité de vos besoins spécifiques.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl mx-auto">
            {additionalOptions.map((list, idx) => (
              <motion.div
                key={idx}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4, delay: idx * 0.1 }}
                className="bg-gradient-to-br from-white to-gray-50 p-6 rounded-xl shadow-lg border border-gray-100"
              >
                <div className="flex justify-center mb-4">
                  <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center">
                    <FaRegLightbulb className="text-indigo-600 w-5 h-5" />
                  </div>
                </div>
                <ul className="space-y-4 text-left">
                  {list.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-start gap-2 text-sm sm:text-base text-gray-700"
                    >
                      <FaCheckCircle className="text-green-500 w-5 h-5 mt-0.5 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Section Modes de paiement */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, ease: 'easeOut' }}
          className="mt-16 sm:mt-20 text-center"
        >
          <h2 className="text-2xl sm:text-3xl font-bold mb-4">
            Modes de paiement disponibles
          </h2>
          <p className="text-gray-600 max-w-lg sm:max-w-3xl mx-auto text-sm sm:text-base mb-8">
            Nous acceptons plusieurs moyens de paiement par carte bancaire pour
            vous faciliter la gestion de vos transactions. Tous nos paiements
            sont sécurisés grâce à <span className="font-bold">Stripe</span>.
          </p>
          <div className="flex flex-wrap justify-center gap-8 max-w-4xl mx-auto">
            {paymentMethods.map((item, index) => (
              <motion.div
                key={index}
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4, delay: index * 0.1 }}
                className="flex flex-col items-center gap-2 bg-white p-4 rounded-xl shadow-md border border-gray-100"
              >
                <div className="bg-gray-50 p-3 rounded-full">{item.icon}</div>
                <span className="text-sm font-medium text-gray-700">
                  {item.text}
                </span>
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="flex flex-col items-center mt-8 sm:mt-10"
          >
            <img
              src="https://res.cloudinary.com/dwcdxkpfk/image/upload/v1736382739/stripe_twabz5.webp"
              alt="Powered by Stripe"
              className="h-8 sm:h-10"
            />
            <p className="text-gray-500 text-xs sm:text-sm mt-2">
              <Link
                to="/paiement-securise"
                className="text-blue-600 hover:text-blue-800 font-medium hover:underline"
              >
                En savoir plus sur notre système de paiement sécurisé
              </Link>
            </p>
          </motion.div>
        </motion.div>
        {/* Appel à l'action */}
        <div className="bg-black py-12 sm:py-20 mt-16 sm:mt-20 relative overflow-hidden">
          <Particles
            className="absolute inset-0 z-0 hidden md:block"
            quantity={15}
            staticity={30}
            ease={40}
          />
          <div className="container mx-auto px-4 text-center relative z-10">
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.7 }}
            >
              <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-white">
                Prêt à donner vie à votre projet web ?
              </h2>
              <p className="text-gray-300 max-w-lg sm:max-w-3xl mx-auto text-sm sm:text-base mb-8 sm:mb-10">
                Contactez-nous pour un devis personnalisé ou pour toute question
                sur nos services et tarifs. Notre équipe est à votre écoute pour
                transformer votre vision en réalité digitale.
              </p>

              {/* Responsive Divider */}
              <div className="w-12 md:w-20 h-[2px] bg-gray-300 my-4 mx-auto"></div>

              {/* Button Description */}
              <p className="text-white text-sm md:text-base text-center mb-4">
                <strong>Devis gratuit</strong> sous 24h !
              </p>

              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <Link
                  to="/contact"
                  className="inline-block bg-white text-blue-900 px-8 py-3.5 rounded-lg text-base sm:text-lg font-semibold hover:bg-gray-100 transition transform shadow-lg"
                >
                  Demander un devis gratuit
                </Link>

                <a
                  href="https://forms.gle/Wqq9dcyKvvYXVtKM6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group flex items-center gap-3 px-8 py-3.5 
                  bg-bootstrap hover:bg-google
                  text-white text-base sm:text-lg font-semibold 
                  rounded-lg shadow-lg 
                  transform transition duration-300
                  focus:ring focus:ring-blue-300"
                >
                  Formulaire de devis
                </a>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tarifs;
