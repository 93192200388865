import React, { useContext, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Eye, Heart, MessageSquare, Search, Clock, Calendar, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { BlogContext } from '../context/BlogContext';
import BackHomeButton from '../components/BackHomeButton';

const BlogPage = () => {
  // Utiliser les articles et les fonctions du contexte
  const { articles } = useContext(BlogContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  
  // Filtrer les articles selon le terme de recherche
  const filteredArticles = articles.filter(article => 
    article.titre.toLowerCase().includes(searchTerm.toLowerCase()) || 
    article.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Animation variants pour Framer Motion
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  // Afficher un message si aucun article n'est disponible
  if (!articles.length) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="bg-white p-8 rounded-xl shadow-xl text-center max-w-md"
        >
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Le blog arrive bientôt</h2>
          <p className="text-gray-600 mb-6">
            Nous préparons du contenu de qualité pour vous. Revenez nous voir prochainement !
          </p>
          <BackHomeButton />
        </motion.div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-b from-white to-gray-50 min-h-screen mt-28 md:mt-36 pb-6 md:pb-20">
      {/* Hero Section */}
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          className="text-center mb-4 md:mb-12"
        >
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 text-gray-900">
            Notre <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-700">Blog</span>
          </h1>
          <p className="text-gray-600 max-w-xl mx-auto mb-8 text-lg">
            Explorez nos articles pour découvrir des conseils, des idées et des
            actualités sur des sujets qui nous passionnent. Que vous soyez ici
            pour apprendre, vous inspirer ou rester informé, notre blog a quelque
            chose pour vous !
          </p>
        </motion.div>
      </div>

      {/* Section de recherche et navigation */}
      <div className="container mx-auto px-4 mb-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4"
        >
          <BackHomeButton />

          {/* Barre de recherche */}
          <div className="relative w-full md:w-auto md:min-w-[260px]">
            <input
              type="text"
              placeholder="Rechercher un article..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Search size={18} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </motion.div>
      </div>

      {/* Liste d'articles */}
      <div className="container mx-auto px-4">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="space-y-10"
        >
          <AnimatePresence>
            {filteredArticles.length === 0 ? (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-center py-12"
              >
                <p className="text-gray-600">Aucun article ne correspond à votre recherche.</p>
              </motion.div>
            ) : (
              filteredArticles.map((article) => (
                <motion.div
                  key={article.id}
                  variants={itemVariants}
                  className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 cursor-pointer group"
                  onClick={() => navigate(`/article/${article.id}`)}
                >
                  <div className="flex flex-col md:flex-row">
                    {/* Image de l'article */}
                    {article.image?.url ? (
                      <div className="w-full md:w-2/5 h-64 md:h-auto relative overflow-hidden">
                        <img
                          src={`${article.image.url}`}
                          alt={article.titre}
                          className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end justify-start p-4">
                          <span className="text-white text-sm font-medium">Lire l'article complet</span>
                        </div>
                      </div>
                    ) : (
                      <div className="w-full md:w-2/5 h-64 bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center">
                        <span className="text-gray-400 text-sm">Image non disponible</span>
                      </div>
                    )}
                    
                    {/* Contenu de l'article */}
                    <div className="w-full md:w-3/5 p-6 md:p-8 flex flex-col justify-between">
                      <div>
                        {/* Catégorie et Date */}
                        <div className="flex flex-wrap items-center gap-4 mb-3">
                          <div className="flex items-center text-xs font-medium text-gray-500">
                            <Calendar size={14} className="mr-1.5" />
                            <span>
                              {new Date(article.createdAt).toLocaleDateString('fr-FR', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                              })}
                            </span>
                          </div>
                          <div className="flex items-center text-xs font-medium text-gray-500">
                            <Clock size={14} className="mr-1.5" />
                            <span>{article.temps_lecture} min de lecture</span>
                          </div>
                        </div>
                        
                        {/* Titre et Description */}
                        <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-900 group-hover:text-blue-600 transition-colors">
                          {article.titre}
                        </h2>
                        <p className="text-gray-600 mb-6 line-clamp-3 md:text-base">
                          {article.description}
                        </p>
                      </div>
                      
                      {/* Auteur et stats */}
                      <div className="flex items-center justify-between border-t border-gray-100 pt-4">
                        <div className="flex items-center">
                          <div className="w-10 h-10 bg-gradient-to-br from-blue-500 to-indigo-600 rounded-full flex items-center justify-center text-white font-bold text-sm">
                            {article.auteur?.charAt(0) || 'A'}
                          </div>
                          <div className="ml-3">
                            <p className="text-sm font-medium text-gray-900">
                              {article.auteur || 'Auteur inconnu'}
                            </p>
                          </div>
                        </div>
                        
                        {/* Statistiques */}
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center text-gray-500">
                            <Eye size={16} className="mr-1" />
                            <span className="text-xs">{article.vue_count || 0}</span>
                          </div>
                          <div className="flex items-center text-gray-500">
                            <Heart
                              size={16}
                              className={`mr-1 ${
                                article.hasLiked
                                  ? 'text-red-500 fill-current'
                                  : ''
                              }`}
                            />
                            <span className="text-xs">{article.likes_count || 0}</span>
                          </div>
                          <div className="flex items-center text-gray-500">
                            <MessageSquare size={16} className="mr-1" />
                            <span className="text-xs">{article.commentaire_count || 0}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))
            )}
          </AnimatePresence>
        </motion.div>
      </div>

      {/* Call to Action */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.7 }}
        className="container mx-auto px-4 mt-12 md:mt-24"
      >
        <div className="bg-gradient-to-r from-blue-600 to-indigo-700 rounded-2xl p-8 md:p-12 text-white text-center shadow-xl overflow-hidden relative">
          <div className="absolute inset-0 bg-black opacity-10"></div>
          <div className="relative z-10">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Vous avez une question ou un projet ?</h2>
            <p className="text-blue-100 mb-8 max-w-2xl mx-auto">
              Notre équipe est à votre disposition pour répondre à vos questions et vous accompagner dans vos projets web.
            </p>
            <button 
              onClick={() => navigate('/contact')}
              className="inline-flex items-center bg-white text-blue-700 px-6 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors group"
            >
              <span>Nous contacter</span>
              <ArrowRight size={18} className="ml-2 transition-transform group-hover:translate-x-1" />
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default BlogPage;